/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthRouteImport } from './routes/auth/route'
import { Route as AppRouteImport } from './routes/app/route'
import { Route as IndexImport } from './routes/index'
import { Route as AuthIndexImport } from './routes/auth/index'
import { Route as AppIndexImport } from './routes/app/index'
import { Route as AuthLoginRouteImport } from './routes/auth/login/route'
import { Route as AppToolsRouteImport } from './routes/app/tools/route'
import { Route as AppProductsRouteImport } from './routes/app/products/route'
import { Route as AppFaqRouteImport } from './routes/app/faq/route'
import { Route as AppExternalLinksRouteImport } from './routes/app/external-links/route'
import { Route as AppEnterprisesRouteImport } from './routes/app/enterprises/route'
import { Route as AppDashboardRouteImport } from './routes/app/dashboard/route'
import { Route as AppBusinessesRmaRouteImport } from './routes/app/businesses-rma/route'
import { Route as AppToolsIndexImport } from './routes/app/tools/index'
import { Route as AppToolsVvaRouteImport } from './routes/app/tools/vva/route'
import { Route as AppToolsSchedulerRouteImport } from './routes/app/tools/scheduler/route'
import { Route as AppToolsRepresentativesTurnoverRouteImport } from './routes/app/tools/representatives-turnover/route'
import { Route as AppToolsRepresentativesMapRouteImport } from './routes/app/tools/representatives-map/route'
import { Route as AppToolsProductShelvesRouteImport } from './routes/app/tools/product-shelves/route'
import { Route as AppToolsProductInventoryRouteImport } from './routes/app/tools/product-inventory/route'
import { Route as AppToolsProductFiltersRouteImport } from './routes/app/tools/product-filters/route'
import { Route as AppToolsPredefinedTextsRouteImport } from './routes/app/tools/predefined-texts/route'
import { Route as AppToolsPredefinedMessagesRouteImport } from './routes/app/tools/predefined-messages/route'
import { Route as AppToolsNewsRouteImport } from './routes/app/tools/news/route'
import { Route as AppToolsMenuRouteImport } from './routes/app/tools/menu/route'
import { Route as AppToolsMailsRouteImport } from './routes/app/tools/mails/route'
import { Route as AppToolsGlobalTurnoverRouteImport } from './routes/app/tools/global-turnover/route'
import { Route as AppToolsFormationsRouteImport } from './routes/app/tools/formations/route'
import { Route as AppToolsExternalLinksRouteImport } from './routes/app/tools/external-links/route'
import { Route as AppToolsEmailsRouteImport } from './routes/app/tools/emails/route'
import { Route as AppToolsDepartmentsRouteImport } from './routes/app/tools/departments/route'
import { Route as AppToolsDdnsRouteImport } from './routes/app/tools/ddns/route'
import { Route as AppToolsCreditRouteImport } from './routes/app/tools/credit/route'
import { Route as AppProductsProductIdRouteImport } from './routes/app/products_.$productId/route'
import { Route as AppProductsSerialNumbersRouteImport } from './routes/app/products/serial-numbers/route'
import { Route as AppProductsCreateRouteImport } from './routes/app/products/create/route'
import { Route as AppFaqCreateRouteImport } from './routes/app/faq/create/route'
import { Route as AppExternalLinksExternalLinkIdRouteImport } from './routes/app/external-links_.$externalLinkId/route'
import { Route as AppEnterprisesEnterpriseIdRouteImport } from './routes/app/enterprises_.$enterpriseId/route'
import { Route as AppEnterprisesCreateRouteImport } from './routes/app/enterprises/create/route'
import { Route as AppDashboardDeleteCollectiveTasksRouteImport } from './routes/app/dashboard/delete-collective-tasks/route'
import { Route as AppDashboardCreateProgressiveInfoRouteImport } from './routes/app/dashboard/create-progressive-info/route'
import { Route as AppDashboardCreatePersonalTaskRouteImport } from './routes/app/dashboard/create-personal-task/route'
import { Route as AppDashboardCreateCollectiveTaskRouteImport } from './routes/app/dashboard/create-collective-task/route'
import { Route as AppBusinessesRmaSearchByProductsRouteImport } from './routes/app/businesses-rma/search-by-products/route'
import { Route as AppBusinessesRmaRepresentativeTurnoverRouteImport } from './routes/app/businesses-rma/representative-turnover/route'
import { Route as AppProductsProductIdIndexImport } from './routes/app/products_.$productId/index'
import { Route as AppToolsVvaCreateRouteImport } from './routes/app/tools/vva/create/route'
import { Route as AppToolsSchedulerCreateRouteImport } from './routes/app/tools/scheduler/create/route'
import { Route as AppToolsProductShelvesCreateRouteImport } from './routes/app/tools/product-shelves/create/route'
import { Route as AppToolsProductInventoryValidateQuantitiesRouteImport } from './routes/app/tools/product-inventory/validate-quantities/route'
import { Route as AppToolsProductFiltersCreateRouteImport } from './routes/app/tools/product-filters/create/route'
import { Route as AppToolsPredefinedTextsCreateRouteImport } from './routes/app/tools/predefined-texts/create/route'
import { Route as AppToolsPredefinedMessagesCreateRouteImport } from './routes/app/tools/predefined-messages/create/route'
import { Route as AppToolsNewsCreateRouteImport } from './routes/app/tools/news/create/route'
import { Route as AppToolsMenuCreateProductRouteImport } from './routes/app/tools/menu/create-product/route'
import { Route as AppToolsMenuCreateEnterpriseRouteImport } from './routes/app/tools/menu/create-enterprise/route'
import { Route as AppToolsMailsCreateRouteImport } from './routes/app/tools/mails/create/route'
import { Route as AppToolsFormationsCreateRouteImport } from './routes/app/tools/formations/create/route'
import { Route as AppToolsExternalLinksCreateRouteImport } from './routes/app/tools/external-links/create/route'
import { Route as AppToolsEmailsEmailIdRouteImport } from './routes/app/tools/emails/$emailId/route'
import { Route as AppToolsDepartmentsCreateRouteImport } from './routes/app/tools/departments/create/route'
import { Route as AppToolsDdnsCreateRouteImport } from './routes/app/tools/ddns/create/route'
import { Route as AppToolsCreditShowRouteImport } from './routes/app/tools/credit/show/route'
import { Route as AppToolsCreditDetailsRouteImport } from './routes/app/tools/credit/details/route'
import { Route as AppProductsProductIdManageRouteImport } from './routes/app/products_.$productId/manage/route'
import { Route as AppProductsProductIdInformationsRouteImport } from './routes/app/products_.$productId/informations/route'
import { Route as AppProductsSerialNumbersCreateRouteImport } from './routes/app/products/serial-numbers/create/route'
import { Route as AppFaqUpdateFaqIdRouteImport } from './routes/app/faq/update.$faqId/route'
import { Route as AppFaqSendByEmailFaqIdRouteImport } from './routes/app/faq/send-by-email.$faqId/route'
import { Route as AppFaqGedFaqIdRouteImport } from './routes/app/faq/ged.$faqId/route'
import { Route as AppFaqDeleteFaqIdRouteImport } from './routes/app/faq/delete.$faqId/route'
import { Route as AppFaqArchiveFaqIdRouteImport } from './routes/app/faq/archive.$faqId/route'
import { Route as AppEnterprisesEnterpriseIdUpdateRepresentativeRouteImport } from './routes/app/enterprises_.$enterpriseId/update-representative/route'
import { Route as AppEnterprisesEnterpriseIdUpdateCategoryRouteImport } from './routes/app/enterprises_.$enterpriseId/update-category/route'
import { Route as AppEnterprisesEnterpriseIdUpdateAccountabilityRouteImport } from './routes/app/enterprises_.$enterpriseId/update-accountability/route'
import { Route as AppEnterprisesEnterpriseIdUpdateRouteImport } from './routes/app/enterprises_.$enterpriseId/update/route'
import { Route as AppEnterprisesEnterpriseIdRelateBusinessRmaRouteImport } from './routes/app/enterprises_.$enterpriseId/relate-business-rma/route'
import { Route as AppEnterprisesEnterpriseIdImportGedFilesRouteImport } from './routes/app/enterprises_.$enterpriseId/import-ged-files/route'
import { Route as AppEnterprisesEnterpriseIdImportContactsRouteImport } from './routes/app/enterprises_.$enterpriseId/import-contacts/route'
import { Route as AppEnterprisesEnterpriseIdEmailHistoryRouteImport } from './routes/app/enterprises_.$enterpriseId/email-history/route'
import { Route as AppEnterprisesEnterpriseIdDeleteRouteImport } from './routes/app/enterprises_.$enterpriseId/delete/route'
import { Route as AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteImport } from './routes/app/enterprises_.$enterpriseId/create-lifesheet-comment/route'
import { Route as AppEnterprisesEnterpriseIdCreateGedDirectoryRouteImport } from './routes/app/enterprises_.$enterpriseId/create-ged-directory/route'
import { Route as AppEnterprisesEnterpriseIdCreateContactRouteImport } from './routes/app/enterprises_.$enterpriseId/create-contact/route'
import { Route as AppEnterprisesEnterpriseIdAddressBookRouteImport } from './routes/app/enterprises_.$enterpriseId/address-book/route'
import { Route as AppEnterprisesUpdateContactContactIdRouteImport } from './routes/app/enterprises/update-contact.$contactId/route'
import { Route as AppEnterprisesUpdateContactPasswordContactIdRouteImport } from './routes/app/enterprises/update-contact-password.$contactId/route'
import { Route as AppEnterprisesSendEmailToContactContactIdRouteImport } from './routes/app/enterprises/send-email-to-contact.$contactId/route'
import { Route as AppEnterprisesDeleteContactContactIdRouteImport } from './routes/app/enterprises/delete-contact.$contactId/route'
import { Route as AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteImport } from './routes/app/enterprises/create-enterprise-rma.$enterpriseId/route'
import { Route as AppEnterprisesCreateContactEnterpriseIdRouteImport } from './routes/app/enterprises/create-contact.$enterpriseId/route'
import { Route as AppEnterprisesCreateContactTravelVoucherContactIdRouteImport } from './routes/app/enterprises/create-contact-travel-voucher.$contactId/route'
import { Route as AppEnterprisesCreateContactBusinessContactIdRouteImport } from './routes/app/enterprises/create-contact-business.$contactId/route'
import { Route as AppDashboardValidatePersonalTaskTaskIdRouteImport } from './routes/app/dashboard/validate-personal-task.$taskId/route'
import { Route as AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteImport } from './routes/app/dashboard/update-progressive-info.$progressiveInfoId/route'
import { Route as AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteImport } from './routes/app/dashboard/update-personal-task-deadline.$taskId/route'
import { Route as AppDashboardUnlinkPersonalTaskTaskIdRouteImport } from './routes/app/dashboard/unlink-personal-task.$taskId/route'
import { Route as AppDashboardTransferTaskTaskIdRouteImport } from './routes/app/dashboard/transfer-task.$taskId/route'
import { Route as AppDashboardTaskEmailTaskIdRouteImport } from './routes/app/dashboard/task-email.$taskId/route'
import { Route as AppDashboardTaskCommentsTaskIdRouteImport } from './routes/app/dashboard/task-comments.$taskId/route'
import { Route as AppDashboardTakeCollectiveTaskTaskIdRouteImport } from './routes/app/dashboard/take-collective-task.$taskId/route'
import { Route as AppDashboardSchedulerEventDetailsEventIdRouteImport } from './routes/app/dashboard/scheduler-event-details.$eventId/route'
import { Route as AppDashboardPersonalTaskDetailsTaskIdRouteImport } from './routes/app/dashboard/personal-task-details.$taskId/route'
import { Route as AppDashboardOtherPersonalTasksProfileIdRouteImport } from './routes/app/dashboard/other-personal-tasks.$profileId/route'
import { Route as AppDashboardLinkPersonalTaskTaskIdRouteImport } from './routes/app/dashboard/link-personal-task.$taskId/route'
import { Route as AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteImport } from './routes/app/dashboard/delete-progressive-info.$progressiveInfoId/route'
import { Route as AppDashboardDeleteCollectiveTaskTaskIdRouteImport } from './routes/app/dashboard/delete-collective-task.$taskId/route'
import { Route as AppDashboardArchivePersonalTaskTaskIdRouteImport } from './routes/app/dashboard/archive-personal-task.$taskId/route'
import { Route as AppBusinessesRmaRmaRmaIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/route'
import { Route as AppBusinessesRmaRmaRmaIdIndexImport } from './routes/app/businesses-rma_/rma.$rmaId/index'
import { Route as AppBusinessesRmaBusinessBusinessIdIndexImport } from './routes/app/businesses-rma_/business.$businessId/index'
import { Route as AppToolsVvaDeleteVvaIdRouteImport } from './routes/app/tools/vva/delete.$vvaId/route'
import { Route as AppToolsSchedulerDetailsRdvIdRouteImport } from './routes/app/tools/scheduler/details.$rdvId/route'
import { Route as AppToolsProductShelvesDeleteProductShelfIdRouteImport } from './routes/app/tools/product-shelves/delete.$productShelfId/route'
import { Route as AppToolsProductInventoryUpdateStockIdRouteImport } from './routes/app/tools/product-inventory/update.$stockId/route'
import { Route as AppToolsProductFiltersUpdateProductFilterIdRouteImport } from './routes/app/tools/product-filters/update.$productFilterId/route'
import { Route as AppToolsProductFiltersDeleteProductFilterIdRouteImport } from './routes/app/tools/product-filters/delete.$productFilterId/route'
import { Route as AppToolsPredefinedTextsUpdatePredefinedTextIdRouteImport } from './routes/app/tools/predefined-texts/update.$predefinedTextId/route'
import { Route as AppToolsPredefinedTextsDeletePredefinedTextIdRouteImport } from './routes/app/tools/predefined-texts/delete.$predefinedTextId/route'
import { Route as AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteImport } from './routes/app/tools/predefined-messages/update.$predefinedMessageId/route'
import { Route as AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteImport } from './routes/app/tools/predefined-messages/delete.$predefinedMessageId/route'
import { Route as AppToolsNewsUpdateNewsIdRouteImport } from './routes/app/tools/news/update.$newsId/route'
import { Route as AppToolsNewsDeleteNewsIdRouteImport } from './routes/app/tools/news/delete.$newsId/route'
import { Route as AppToolsMailsUpdateMailIdRouteImport } from './routes/app/tools/mails/update.$mailId/route'
import { Route as AppToolsMailsShowMailIdRouteImport } from './routes/app/tools/mails/show.$mailId/route'
import { Route as AppToolsMailsDeleteMailIdRouteImport } from './routes/app/tools/mails/delete.$mailId/route'
import { Route as AppToolsFormationsUpdateFormationIdRouteImport } from './routes/app/tools/formations/update.$formationId/route'
import { Route as AppToolsFormationsSubscribersFormationDetailIdRouteImport } from './routes/app/tools/formations/subscribers.$formationDetailId/route'
import { Route as AppToolsFormationsDeleteFormationIdRouteImport } from './routes/app/tools/formations/delete.$formationId/route'
import { Route as AppToolsFormationsCreateDetailsRouteImport } from './routes/app/tools/formations/create/details/route'
import { Route as AppToolsFormationsCreateAddDetailRouteImport } from './routes/app/tools/formations/create/add-detail/route'
import { Route as AppToolsExternalLinksUpdateExternalLinkIdRouteImport } from './routes/app/tools/external-links/update.$externalLinkId/route'
import { Route as AppToolsExternalLinksDeleteExternalLinkIdRouteImport } from './routes/app/tools/external-links/delete.$externalLinkId/route'
import { Route as AppToolsExternalLinksArchiveExternalLinkIdRouteImport } from './routes/app/tools/external-links/archive.$externalLinkId/route'
import { Route as AppToolsEmailsEmailIdReplyRouteImport } from './routes/app/tools/emails/$emailId/reply/route'
import { Route as AppToolsDepartmentsUpdateDepartmentIdRouteImport } from './routes/app/tools/departments/update.$departmentId/route'
import { Route as AppToolsDepartmentsDeleteDepartmentIdRouteImport } from './routes/app/tools/departments/delete.$departmentId/route'
import { Route as AppToolsDdnsDeleteDdnsIdRouteImport } from './routes/app/tools/ddns/delete.$ddnsId/route'
import { Route as AppProductsProductIdManageCreateVersionRouteImport } from './routes/app/products_.$productId/manage/create-version/route'
import { Route as AppProductsProductIdManageCreateStockRouteImport } from './routes/app/products_.$productId/manage/create-stock/route'
import { Route as AppProductsProductIdManageAddSpecificationRouteImport } from './routes/app/products_.$productId/manage/add-specification/route'
import { Route as AppProductsProductIdManageAddNomenclatureDetailRouteImport } from './routes/app/products_.$productId/manage/add-nomenclature-detail/route'
import { Route as AppProductsProductIdManageAddAssociatedProductRouteImport } from './routes/app/products_.$productId/manage/add-associated-product/route'
import { Route as AppProductsProductIdInformationsRenameGedObjectRouteImport } from './routes/app/products_.$productId/informations/rename-ged-object/route'
import { Route as AppProductsProductIdInformationsImportGedFilesRouteImport } from './routes/app/products_.$productId/informations/import-ged-files/route'
import { Route as AppProductsProductIdInformationsDeleteGedObjectRouteImport } from './routes/app/products_.$productId/informations/delete-ged-object/route'
import { Route as AppProductsProductIdInformationsCreateLifesheetCommentRouteImport } from './routes/app/products_.$productId/informations/create-lifesheet-comment/route'
import { Route as AppProductsProductIdInformationsCreateGedDirectoryRouteImport } from './routes/app/products_.$productId/informations/create-ged-directory/route'
import { Route as AppProductsSerialNumbersUpdateSerialNumberIdRouteImport } from './routes/app/products/serial-numbers/update.$serialNumberId/route'
import { Route as AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteImport } from './routes/app/products/serial-numbers/remove-from-business.$serialNumberId/route'
import { Route as AppProductsSerialNumbersDeleteSerialNumberIdRouteImport } from './routes/app/products/serial-numbers/delete.$serialNumberId/route'
import { Route as AppProductsSerialNumbersCreateRmaSerialNumberIdRouteImport } from './routes/app/products/serial-numbers/create-rma.$serialNumberId/route'
import { Route as AppFaqGedFaqIdImportFilesRouteImport } from './routes/app/faq/ged.$faqId/import-files/route'
import { Route as AppFaqGedFaqIdCreateDirectoryRouteImport } from './routes/app/faq/ged.$faqId/create-directory/route'
import { Route as AppEnterprisesEnterpriseIdUpdateContactContactIdRouteImport } from './routes/app/enterprises_.$enterpriseId/update-contact.$contactId/route'
import { Route as AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteImport } from './routes/app/enterprises_.$enterpriseId/update-contact-password.$contactId/route'
import { Route as AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteImport } from './routes/app/enterprises_.$enterpriseId/unrelate-business-rma.$businessRmaId/route'
import { Route as AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteImport } from './routes/app/enterprises_.$enterpriseId/unlink-task.$taskId/route'
import { Route as AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteImport } from './routes/app/enterprises_.$enterpriseId/task-email.$taskId/route'
import { Route as AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteImport } from './routes/app/enterprises_.$enterpriseId/send-email-to-contact.$contactId/route'
import { Route as AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteImport } from './routes/app/enterprises_.$enterpriseId/rename-ged-object.$objectRelativePath/route'
import { Route as AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteImport } from './routes/app/enterprises_.$enterpriseId/lifesheet-email.$lifesheetId/route'
import { Route as AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteImport } from './routes/app/enterprises_.$enterpriseId/delete-ged-object.$objectRelativePath/route'
import { Route as AppEnterprisesEnterpriseIdDeleteContactContactIdRouteImport } from './routes/app/enterprises_.$enterpriseId/delete-contact.$contactId/route'
import { Route as AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteImport } from './routes/app/enterprises_.$enterpriseId/create-contact-business.$contactId/route'
import { Route as AppEnterprisesEnterpriseIdAddressBookImportRouteImport } from './routes/app/enterprises_.$enterpriseId/address-book/import/route'
import { Route as AppEnterprisesEnterpriseIdAddressBookCreateRouteImport } from './routes/app/enterprises_.$enterpriseId/address-book/create/route'
import { Route as AppDashboardTaskEmailTaskIdReplyRouteImport } from './routes/app/dashboard/task-email.$taskId/reply/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/route'
import { Route as AppBusinessesRmaRmaRmaIdReceptionRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/reception/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/route'
import { Route as AppBusinessesRmaBusinessBusinessIdStudyRouteImport } from './routes/app/businesses-rma_/business.$businessId_/study/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBlRouteImport } from './routes/app/businesses-rma_/business.$businessId/bl/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBillRouteImport } from './routes/app/businesses-rma_/business.$businessId/bill/route'
import { Route as AppBusinessesRmaBusinessBusinessIdArcRouteImport } from './routes/app/businesses-rma_/business.$businessId/arc/route'
import { Route as AppBusinessesRmaBusinessBusinessIdStudyIndexImport } from './routes/app/businesses-rma_/business.$businessId_/study/index'
import { Route as AppToolsSchedulerDetailsRdvIdUpdateRouteImport } from './routes/app/tools/scheduler/details.$rdvId/update/route'
import { Route as AppToolsSchedulerDetailsRdvIdDeleteRouteImport } from './routes/app/tools/scheduler/details.$rdvId/delete/route'
import { Route as AppToolsFormationsUpdateFormationIdDetailsRouteImport } from './routes/app/tools/formations/update.$formationId/details/route'
import { Route as AppToolsFormationsUpdateFormationIdAddDetailRouteImport } from './routes/app/tools/formations/update.$formationId/add-detail/route'
import { Route as AppToolsFormationsSubscribersFormationDetailIdCreateRouteImport } from './routes/app/tools/formations/subscribers.$formationDetailId/create/route'
import { Route as AppProductsProductIdManageUpdateVersionVersionIdRouteImport } from './routes/app/products_.$productId/manage/update-version.$versionId/route'
import { Route as AppProductsProductIdManageUpdateStockStockIdRouteImport } from './routes/app/products_.$productId/manage/update-stock.$stockId/route'
import { Route as AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteImport } from './routes/app/products_.$productId/manage/update-specification.$specificationId/route'
import { Route as AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteImport } from './routes/app/products_.$productId/manage/update-nomenclature-detail.$nomenclatureDetailId/route'
import { Route as AppProductsProductIdManageStockHistoryStockIdRouteImport } from './routes/app/products_.$productId/manage/stock-history.$stockId/route'
import { Route as AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteImport } from './routes/app/products_.$productId/manage/remove-associated-product.$associatedProductId/route'
import { Route as AppProductsProductIdManageDeleteVersionVersionIdRouteImport } from './routes/app/products_.$productId/manage/delete-version.$versionId/route'
import { Route as AppProductsProductIdManageDeleteStockStockIdRouteImport } from './routes/app/products_.$productId/manage/delete-stock.$stockId/route'
import { Route as AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteImport } from './routes/app/products_.$productId/manage/delete-specification.$specificationId/route'
import { Route as AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteImport } from './routes/app/products_.$productId/manage/delete-nomenclature-detail.$nomenclatureDetailId/route'
import { Route as AppProductsProductIdManageAddSpecificationFilterIdRouteImport } from './routes/app/products_.$productId/manage/add-specification/$filterId/route'
import { Route as AppProductsProductIdInformationsUnlinkTaskTaskIdRouteImport } from './routes/app/products_.$productId/informations/unlink-task.$taskId/route'
import { Route as AppProductsProductIdInformationsTaskEmailTaskIdRouteImport } from './routes/app/products_.$productId/informations/task-email.$taskId/route'
import { Route as AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteImport } from './routes/app/products_.$productId/informations/lifesheet-email.$lifesheetId/route'
import { Route as AppFaqGedFaqIdRenameItemRelativePathRouteImport } from './routes/app/faq/ged.$faqId/rename.$itemRelativePath/route'
import { Route as AppFaqGedFaqIdDeleteItemRelativePathRouteImport } from './routes/app/faq/ged.$faqId/delete.$itemRelativePath/route'
import { Route as AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteImport } from './routes/app/enterprises_.$enterpriseId/task-email.$taskId/reply/route'
import { Route as AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteImport } from './routes/app/enterprises_.$enterpriseId/email-history/email.$emailId/route'
import { Route as AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteImport } from './routes/app/enterprises_.$enterpriseId/address-book/update.$addressId/route'
import { Route as AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteImport } from './routes/app/enterprises_.$enterpriseId/address-book/delete.$addressId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportPdfRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/pdf/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/import-ged-files/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/create-link/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/create-lifesheet/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/create-ged-directory/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/create-detail/route'
import { Route as AppBusinessesRmaRmaRmaIdReceptionPdfRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/reception/pdf/route'
import { Route as AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/reception/create-detail/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/travel-voucher/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryPdfRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/pdf/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/create-detail/route'
import { Route as AppBusinessesRmaBusinessBusinessIdStudyExpertRouteImport } from './routes/app/businesses-rma_/business.$businessId_/study/expert/route'
import { Route as AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteImport } from './routes/app/businesses-rma_/business.$businessId_/study/automatic/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/update-shipping-price/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/pdf/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/commercial-notice/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/update-responsible/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/update-representative/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/update-billing-address/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/send-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/import-ged-files/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/email-history/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/delete/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/create-link/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/create-lifesheet/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/create-ged-directory/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/travel-voucher/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/bl/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/bill/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBillCreditsRouteImport } from './routes/app/businesses-rma_/business.$businessId/bill/credits/route'
import { Route as AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteImport } from './routes/app/businesses-rma_/business.$businessId/arc/update-shipping-price/route'
import { Route as AppBusinessesRmaBusinessBusinessIdArcPdfRouteImport } from './routes/app/businesses-rma_/business.$businessId/arc/pdf/route'
import { Route as AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteImport } from './routes/app/tools/formations/subscribers.$formationDetailId/send-email.$subscriptionId/route'
import { Route as AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteImport } from './routes/app/tools/formations/subscribers.$formationDetailId/delete.$subscriptionId/route'
import { Route as AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteImport } from './routes/app/products_.$productId/informations/task-email.$taskId/reply/route'
import { Route as AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteImport } from './routes/app/enterprises_.$enterpriseId/email-history/email.$emailId/reply/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/update-detail.$detailId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/unlink-task.$taskId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/task-email.$taskId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/rename-ged-object.$objectRelativePath/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/pdf/send-by-email/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/lifesheet-email.$lifesheetId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/delete-link.$associatedId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/delete-ged-object.$relativePath/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/delete-detail.$detailId/route'
import { Route as AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/reception/update-detail.$detailId/route'
import { Route as AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/reception/pdf/send-by-email/route'
import { Route as AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/reception/delete-detail.$detailId/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/update-detail.$detailId/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/pdf/send-by-email/route'
import { Route as AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/delivery/delete-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/pdf/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/import-ged-files/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-subtitle/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-no-billed-time/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-cumulated-time/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-link/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-lifesheet/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-ged-directory/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-faq/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/update-subquotation.$subquotationId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/update-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/pdf/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/delete-subquotation.$subquotationId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/delete-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/create-detail.$subquotationId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/create-associated-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/commercial-notice/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/unlink-task.$taskId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/task-email.$taskId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/rename-ged-object.$objectRelativePath/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/lifesheet-email.$lifesheetId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/email-history/$emailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/delete-link.$associatedId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/delete-ged-object.$objectRelativePath/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/confirm-quotation-import.$otherBusinessId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/create/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/update-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/delete-serial.$serialId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/delete-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/create-serial-rma.$serialId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/create-detail-rma.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/bp/add-serial.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/bill/credits/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/arc/update-detail.$detailId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId/arc/pdf/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/arc/delete-detail.$detailId/route'
import { Route as AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteImport } from './routes/app/businesses-rma_/rma.$rmaId/support/task-email.$taskId/reply/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/rename-ged-object.$objectRelativePath/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/pdf/send-by-email/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/lifesheet-email.$lifesheetId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete-link.$associatedId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteImport } from './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete-ged-object.$objectRelativePath/route'
import { Route as AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteImport } from './routes/app/businesses-rma_/business.$businessId/quotation/commercial-notice/send-by-email/predefined-messages/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/email-history/$emailId/reply/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/update.$addressId/route'
import { Route as AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteImport } from './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/delete.$addressId/route'

// Create Virtual Routes

const AuthForgotPasswordRouteLazyImport = createFileRoute(
  '/auth/forgot-password',
)()
const AuthResetPasswordTokenRouteLazyImport = createFileRoute(
  '/auth/reset-password/$token',
)()
const AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyImport =
  createFileRoute('/app/businesses-rma_/business/$businessId/bl/update')()

// Create/Update Routes

const AuthRouteRoute = AuthRouteImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/route.lazy').then((d) => d.Route))

const AppRouteRoute = AppRouteImport.update({
  id: '/app',
  path: '/app',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/app/route.lazy').then((d) => d.Route))

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AppIndexRoute = AppIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppRouteRoute,
} as any)

const AuthForgotPasswordRouteLazyRoute =
  AuthForgotPasswordRouteLazyImport.update({
    id: '/forgot-password',
    path: '/forgot-password',
    getParentRoute: () => AuthRouteRoute,
  } as any).lazy(() =>
    import('./routes/auth/forgot-password/route.lazy').then((d) => d.Route),
  )

const AuthLoginRouteRoute = AuthLoginRouteImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import('./routes/auth/login/route.lazy').then((d) => d.Route),
)

const AppToolsRouteRoute = AppToolsRouteImport.update({
  id: '/tools',
  path: '/tools',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppProductsRouteRoute = AppProductsRouteImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/app/products/route.lazy').then((d) => d.Route),
)

const AppFaqRouteRoute = AppFaqRouteImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() => import('./routes/app/faq/route.lazy').then((d) => d.Route))

const AppExternalLinksRouteRoute = AppExternalLinksRouteImport.update({
  id: '/external-links',
  path: '/external-links',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/app/external-links/route.lazy').then((d) => d.Route),
)

const AppEnterprisesRouteRoute = AppEnterprisesRouteImport.update({
  id: '/enterprises',
  path: '/enterprises',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/app/enterprises/route.lazy').then((d) => d.Route),
)

const AppDashboardRouteRoute = AppDashboardRouteImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/app/dashboard/route.lazy').then((d) => d.Route),
)

const AppBusinessesRmaRouteRoute = AppBusinessesRmaRouteImport.update({
  id: '/businesses-rma',
  path: '/businesses-rma',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/app/businesses-rma/route.lazy').then((d) => d.Route),
)

const AppToolsIndexRoute = AppToolsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppToolsRouteRoute,
} as any)

const AuthResetPasswordTokenRouteLazyRoute =
  AuthResetPasswordTokenRouteLazyImport.update({
    id: '/reset-password/$token',
    path: '/reset-password/$token',
    getParentRoute: () => AuthRouteRoute,
  } as any).lazy(() =>
    import('./routes/auth/reset-password.$token/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsVvaRouteRoute = AppToolsVvaRouteImport.update({
  id: '/vva',
  path: '/vva',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/vva/route.lazy').then((d) => d.Route),
)

const AppToolsSchedulerRouteRoute = AppToolsSchedulerRouteImport.update({
  id: '/scheduler',
  path: '/scheduler',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/scheduler/route.lazy').then((d) => d.Route),
)

const AppToolsRepresentativesTurnoverRouteRoute =
  AppToolsRepresentativesTurnoverRouteImport.update({
    id: '/representatives-turnover',
    path: '/representatives-turnover',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/representatives-turnover/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsRepresentativesMapRouteRoute =
  AppToolsRepresentativesMapRouteImport.update({
    id: '/representatives-map',
    path: '/representatives-map',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/representatives-map/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsProductShelvesRouteRoute =
  AppToolsProductShelvesRouteImport.update({
    id: '/product-shelves',
    path: '/product-shelves',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/product-shelves/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsProductInventoryRouteRoute =
  AppToolsProductInventoryRouteImport.update({
    id: '/product-inventory',
    path: '/product-inventory',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/product-inventory/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsProductFiltersRouteRoute =
  AppToolsProductFiltersRouteImport.update({
    id: '/product-filters',
    path: '/product-filters',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/product-filters/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsPredefinedTextsRouteRoute =
  AppToolsPredefinedTextsRouteImport.update({
    id: '/predefined-texts',
    path: '/predefined-texts',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/predefined-texts/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsPredefinedMessagesRouteRoute =
  AppToolsPredefinedMessagesRouteImport.update({
    id: '/predefined-messages',
    path: '/predefined-messages',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/predefined-messages/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsNewsRouteRoute = AppToolsNewsRouteImport.update({
  id: '/news',
  path: '/news',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/news/route.lazy').then((d) => d.Route),
)

const AppToolsMenuRouteRoute = AppToolsMenuRouteImport.update({
  id: '/menu',
  path: '/menu',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/menu/route.lazy').then((d) => d.Route),
)

const AppToolsMailsRouteRoute = AppToolsMailsRouteImport.update({
  id: '/mails',
  path: '/mails',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/mails/route.lazy').then((d) => d.Route),
)

const AppToolsGlobalTurnoverRouteRoute =
  AppToolsGlobalTurnoverRouteImport.update({
    id: '/global-turnover',
    path: '/global-turnover',
    getParentRoute: () => AppToolsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/global-turnover/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsFormationsRouteRoute = AppToolsFormationsRouteImport.update({
  id: '/formations',
  path: '/formations',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/formations/route.lazy').then((d) => d.Route),
)

const AppToolsExternalLinksRouteRoute = AppToolsExternalLinksRouteImport.update(
  {
    id: '/external-links',
    path: '/external-links',
    getParentRoute: () => AppToolsRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/app/tools/external-links/route.lazy').then((d) => d.Route),
)

const AppToolsEmailsRouteRoute = AppToolsEmailsRouteImport.update({
  id: '/emails',
  path: '/emails',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/emails/route.lazy').then((d) => d.Route),
)

const AppToolsDepartmentsRouteRoute = AppToolsDepartmentsRouteImport.update({
  id: '/departments',
  path: '/departments',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/departments/route.lazy').then((d) => d.Route),
)

const AppToolsDdnsRouteRoute = AppToolsDdnsRouteImport.update({
  id: '/ddns',
  path: '/ddns',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/ddns/route.lazy').then((d) => d.Route),
)

const AppToolsCreditRouteRoute = AppToolsCreditRouteImport.update({
  id: '/credit',
  path: '/credit',
  getParentRoute: () => AppToolsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/credit/route.lazy').then((d) => d.Route),
)

const AppProductsProductIdRouteRoute = AppProductsProductIdRouteImport.update({
  id: '/products_/$productId',
  path: '/products/$productId',
  getParentRoute: () => AppRouteRoute,
} as any).lazy(() =>
  import('./routes/app/products_.$productId/route.lazy').then((d) => d.Route),
)

const AppProductsSerialNumbersRouteRoute =
  AppProductsSerialNumbersRouteImport.update({
    id: '/serial-numbers',
    path: '/serial-numbers',
    getParentRoute: () => AppProductsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/products/serial-numbers/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppProductsCreateRouteRoute = AppProductsCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppProductsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/products/create/route.lazy').then((d) => d.Route),
)

const AppFaqCreateRouteRoute = AppFaqCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppFaqRouteRoute,
} as any).lazy(() =>
  import('./routes/app/faq/create/route.lazy').then((d) => d.Route),
)

const AppExternalLinksExternalLinkIdRouteRoute =
  AppExternalLinksExternalLinkIdRouteImport.update({
    id: '/external-links_/$externalLinkId',
    path: '/external-links/$externalLinkId',
    getParentRoute: () => AppRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/external-links_.$externalLinkId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppEnterprisesEnterpriseIdRouteRoute =
  AppEnterprisesEnterpriseIdRouteImport.update({
    id: '/enterprises_/$enterpriseId',
    path: '/enterprises/$enterpriseId',
    getParentRoute: () => AppRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/enterprises_.$enterpriseId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppEnterprisesCreateRouteRoute = AppEnterprisesCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppEnterprisesRouteRoute,
} as any).lazy(() =>
  import('./routes/app/enterprises/create/route.lazy').then((d) => d.Route),
)

const AppDashboardDeleteCollectiveTasksRouteRoute =
  AppDashboardDeleteCollectiveTasksRouteImport.update({
    id: '/delete-collective-tasks',
    path: '/delete-collective-tasks',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/delete-collective-tasks/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardCreateProgressiveInfoRouteRoute =
  AppDashboardCreateProgressiveInfoRouteImport.update({
    id: '/create-progressive-info',
    path: '/create-progressive-info',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/create-progressive-info/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardCreatePersonalTaskRouteRoute =
  AppDashboardCreatePersonalTaskRouteImport.update({
    id: '/create-personal-task',
    path: '/create-personal-task',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/create-personal-task/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardCreateCollectiveTaskRouteRoute =
  AppDashboardCreateCollectiveTaskRouteImport.update({
    id: '/create-collective-task',
    path: '/create-collective-task',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/create-collective-task/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaSearchByProductsRouteRoute =
  AppBusinessesRmaSearchByProductsRouteImport.update({
    id: '/search-by-products',
    path: '/search-by-products',
    getParentRoute: () => AppBusinessesRmaRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/businesses-rma/search-by-products/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaRepresentativeTurnoverRouteRoute =
  AppBusinessesRmaRepresentativeTurnoverRouteImport.update({
    id: '/representative-turnover',
    path: '/representative-turnover',
    getParentRoute: () => AppBusinessesRmaRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma/representative-turnover/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdIndexRoute = AppProductsProductIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppProductsProductIdRouteRoute,
} as any)

const AppToolsVvaCreateRouteRoute = AppToolsVvaCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppToolsVvaRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/vva/create/route.lazy').then((d) => d.Route),
)

const AppToolsSchedulerCreateRouteRoute =
  AppToolsSchedulerCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsSchedulerRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/scheduler/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsProductShelvesCreateRouteRoute =
  AppToolsProductShelvesCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsProductShelvesRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/product-shelves/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsProductInventoryValidateQuantitiesRouteRoute =
  AppToolsProductInventoryValidateQuantitiesRouteImport.update({
    id: '/validate-quantities',
    path: '/validate-quantities',
    getParentRoute: () => AppToolsProductInventoryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/product-inventory/validate-quantities/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsProductFiltersCreateRouteRoute =
  AppToolsProductFiltersCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsProductFiltersRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/product-filters/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsPredefinedTextsCreateRouteRoute =
  AppToolsPredefinedTextsCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsPredefinedTextsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/predefined-texts/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsPredefinedMessagesCreateRouteRoute =
  AppToolsPredefinedMessagesCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsPredefinedMessagesRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/predefined-messages/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsNewsCreateRouteRoute = AppToolsNewsCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppToolsNewsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/news/create/route.lazy').then((d) => d.Route),
)

const AppToolsMenuCreateProductRouteRoute =
  AppToolsMenuCreateProductRouteImport.update({
    id: '/create-product',
    path: '/create-product',
    getParentRoute: () => AppToolsMenuRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/menu/create-product/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsMenuCreateEnterpriseRouteRoute =
  AppToolsMenuCreateEnterpriseRouteImport.update({
    id: '/create-enterprise',
    path: '/create-enterprise',
    getParentRoute: () => AppToolsMenuRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/menu/create-enterprise/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsMailsCreateRouteRoute = AppToolsMailsCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppToolsMailsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/mails/create/route.lazy').then((d) => d.Route),
)

const AppToolsFormationsCreateRouteRoute =
  AppToolsFormationsCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsFormationsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/formations/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsExternalLinksCreateRouteRoute =
  AppToolsExternalLinksCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsExternalLinksRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/external-links/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsEmailsEmailIdRouteRoute = AppToolsEmailsEmailIdRouteImport.update(
  {
    id: '/$emailId',
    path: '/$emailId',
    getParentRoute: () => AppToolsEmailsRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/app/tools/emails/$emailId/route.lazy').then((d) => d.Route),
)

const AppToolsDepartmentsCreateRouteRoute =
  AppToolsDepartmentsCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppToolsDepartmentsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/departments/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsDdnsCreateRouteRoute = AppToolsDdnsCreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AppToolsDdnsRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/ddns/create/route.lazy').then((d) => d.Route),
)

const AppToolsCreditShowRouteRoute = AppToolsCreditShowRouteImport.update({
  id: '/show',
  path: '/show',
  getParentRoute: () => AppToolsCreditRouteRoute,
} as any).lazy(() =>
  import('./routes/app/tools/credit/show/route.lazy').then((d) => d.Route),
)

const AppToolsCreditDetailsRouteRoute = AppToolsCreditDetailsRouteImport.update(
  {
    id: '/details',
    path: '/details',
    getParentRoute: () => AppToolsCreditRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/app/tools/credit/details/route.lazy').then((d) => d.Route),
)

const AppProductsProductIdManageRouteRoute =
  AppProductsProductIdManageRouteImport.update({
    id: '/manage',
    path: '/manage',
    getParentRoute: () => AppProductsProductIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/products_.$productId/manage/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppProductsProductIdInformationsRouteRoute =
  AppProductsProductIdInformationsRouteImport.update({
    id: '/informations',
    path: '/informations',
    getParentRoute: () => AppProductsProductIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/products_.$productId/informations/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppProductsSerialNumbersCreateRouteRoute =
  AppProductsSerialNumbersCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppProductsSerialNumbersRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/products/serial-numbers/create/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppFaqUpdateFaqIdRouteRoute = AppFaqUpdateFaqIdRouteImport.update({
  id: '/update/$faqId',
  path: '/update/$faqId',
  getParentRoute: () => AppFaqRouteRoute,
} as any).lazy(() =>
  import('./routes/app/faq/update.$faqId/route.lazy').then((d) => d.Route),
)

const AppFaqSendByEmailFaqIdRouteRoute =
  AppFaqSendByEmailFaqIdRouteImport.update({
    id: '/send-by-email/$faqId',
    path: '/send-by-email/$faqId',
    getParentRoute: () => AppFaqRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/faq/send-by-email.$faqId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppFaqGedFaqIdRouteRoute = AppFaqGedFaqIdRouteImport.update({
  id: '/ged/$faqId',
  path: '/ged/$faqId',
  getParentRoute: () => AppFaqRouteRoute,
} as any).lazy(() =>
  import('./routes/app/faq/ged.$faqId/route.lazy').then((d) => d.Route),
)

const AppFaqDeleteFaqIdRouteRoute = AppFaqDeleteFaqIdRouteImport.update({
  id: '/delete/$faqId',
  path: '/delete/$faqId',
  getParentRoute: () => AppFaqRouteRoute,
} as any).lazy(() =>
  import('./routes/app/faq/delete.$faqId/route.lazy').then((d) => d.Route),
)

const AppFaqArchiveFaqIdRouteRoute = AppFaqArchiveFaqIdRouteImport.update({
  id: '/archive/$faqId',
  path: '/archive/$faqId',
  getParentRoute: () => AppFaqRouteRoute,
} as any).lazy(() =>
  import('./routes/app/faq/archive.$faqId/route.lazy').then((d) => d.Route),
)

const AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute =
  AppEnterprisesEnterpriseIdUpdateRepresentativeRouteImport.update({
    id: '/update-representative',
    path: '/update-representative',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/update-representative/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute =
  AppEnterprisesEnterpriseIdUpdateCategoryRouteImport.update({
    id: '/update-category',
    path: '/update-category',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/update-category/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute =
  AppEnterprisesEnterpriseIdUpdateAccountabilityRouteImport.update({
    id: '/update-accountability',
    path: '/update-accountability',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/update-accountability/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdUpdateRouteRoute =
  AppEnterprisesEnterpriseIdUpdateRouteImport.update({
    id: '/update',
    path: '/update',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/enterprises_.$enterpriseId/update/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute =
  AppEnterprisesEnterpriseIdRelateBusinessRmaRouteImport.update({
    id: '/relate-business-rma',
    path: '/relate-business-rma',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/relate-business-rma/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdImportGedFilesRouteRoute =
  AppEnterprisesEnterpriseIdImportGedFilesRouteImport.update({
    id: '/import-ged-files',
    path: '/import-ged-files',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/import-ged-files/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdImportContactsRouteRoute =
  AppEnterprisesEnterpriseIdImportContactsRouteImport.update({
    id: '/import-contacts',
    path: '/import-contacts',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/import-contacts/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdEmailHistoryRouteRoute =
  AppEnterprisesEnterpriseIdEmailHistoryRouteImport.update({
    id: '/email-history',
    path: '/email-history',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/email-history/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdDeleteRouteRoute =
  AppEnterprisesEnterpriseIdDeleteRouteImport.update({
    id: '/delete',
    path: '/delete',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/enterprises_.$enterpriseId/delete/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute =
  AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteImport.update({
    id: '/create-lifesheet-comment',
    path: '/create-lifesheet-comment',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/create-lifesheet-comment/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute =
  AppEnterprisesEnterpriseIdCreateGedDirectoryRouteImport.update({
    id: '/create-ged-directory',
    path: '/create-ged-directory',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/create-ged-directory/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdCreateContactRouteRoute =
  AppEnterprisesEnterpriseIdCreateContactRouteImport.update({
    id: '/create-contact',
    path: '/create-contact',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/create-contact/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdAddressBookRouteRoute =
  AppEnterprisesEnterpriseIdAddressBookRouteImport.update({
    id: '/address-book',
    path: '/address-book',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/address-book/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesUpdateContactContactIdRouteRoute =
  AppEnterprisesUpdateContactContactIdRouteImport.update({
    id: '/update-contact/$contactId',
    path: '/update-contact/$contactId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/update-contact.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesUpdateContactPasswordContactIdRouteRoute =
  AppEnterprisesUpdateContactPasswordContactIdRouteImport.update({
    id: '/update-contact-password/$contactId',
    path: '/update-contact-password/$contactId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/update-contact-password.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesSendEmailToContactContactIdRouteRoute =
  AppEnterprisesSendEmailToContactContactIdRouteImport.update({
    id: '/send-email-to-contact/$contactId',
    path: '/send-email-to-contact/$contactId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/send-email-to-contact.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesDeleteContactContactIdRouteRoute =
  AppEnterprisesDeleteContactContactIdRouteImport.update({
    id: '/delete-contact/$contactId',
    path: '/delete-contact/$contactId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/delete-contact.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute =
  AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteImport.update({
    id: '/create-enterprise-rma/$enterpriseId',
    path: '/create-enterprise-rma/$enterpriseId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/create-enterprise-rma.$enterpriseId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesCreateContactEnterpriseIdRouteRoute =
  AppEnterprisesCreateContactEnterpriseIdRouteImport.update({
    id: '/create-contact/$enterpriseId',
    path: '/create-contact/$enterpriseId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/create-contact.$enterpriseId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute =
  AppEnterprisesCreateContactTravelVoucherContactIdRouteImport.update({
    id: '/create-contact-travel-voucher/$contactId',
    path: '/create-contact-travel-voucher/$contactId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/create-contact-travel-voucher.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesCreateContactBusinessContactIdRouteRoute =
  AppEnterprisesCreateContactBusinessContactIdRouteImport.update({
    id: '/create-contact-business/$contactId',
    path: '/create-contact-business/$contactId',
    getParentRoute: () => AppEnterprisesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises/create-contact-business.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardValidatePersonalTaskTaskIdRouteRoute =
  AppDashboardValidatePersonalTaskTaskIdRouteImport.update({
    id: '/validate-personal-task/$taskId',
    path: '/validate-personal-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/validate-personal-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute =
  AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteImport.update({
    id: '/update-progressive-info/$progressiveInfoId',
    path: '/update-progressive-info/$progressiveInfoId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/update-progressive-info.$progressiveInfoId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute =
  AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteImport.update({
    id: '/update-personal-task-deadline/$taskId',
    path: '/update-personal-task-deadline/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/update-personal-task-deadline.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardUnlinkPersonalTaskTaskIdRouteRoute =
  AppDashboardUnlinkPersonalTaskTaskIdRouteImport.update({
    id: '/unlink-personal-task/$taskId',
    path: '/unlink-personal-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/unlink-personal-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardTransferTaskTaskIdRouteRoute =
  AppDashboardTransferTaskTaskIdRouteImport.update({
    id: '/transfer-task/$taskId',
    path: '/transfer-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/transfer-task.$taskId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardTaskEmailTaskIdRouteRoute =
  AppDashboardTaskEmailTaskIdRouteImport.update({
    id: '/task-email/$taskId',
    path: '/task-email/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/task-email.$taskId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardTaskCommentsTaskIdRouteRoute =
  AppDashboardTaskCommentsTaskIdRouteImport.update({
    id: '/task-comments/$taskId',
    path: '/task-comments/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/task-comments.$taskId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardTakeCollectiveTaskTaskIdRouteRoute =
  AppDashboardTakeCollectiveTaskTaskIdRouteImport.update({
    id: '/take-collective-task/$taskId',
    path: '/take-collective-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/take-collective-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardSchedulerEventDetailsEventIdRouteRoute =
  AppDashboardSchedulerEventDetailsEventIdRouteImport.update({
    id: '/scheduler-event-details/$eventId',
    path: '/scheduler-event-details/$eventId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/scheduler-event-details.$eventId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardPersonalTaskDetailsTaskIdRouteRoute =
  AppDashboardPersonalTaskDetailsTaskIdRouteImport.update({
    id: '/personal-task-details/$taskId',
    path: '/personal-task-details/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/personal-task-details.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardOtherPersonalTasksProfileIdRouteRoute =
  AppDashboardOtherPersonalTasksProfileIdRouteImport.update({
    id: '/other-personal-tasks/$profileId',
    path: '/other-personal-tasks/$profileId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/other-personal-tasks.$profileId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardLinkPersonalTaskTaskIdRouteRoute =
  AppDashboardLinkPersonalTaskTaskIdRouteImport.update({
    id: '/link-personal-task/$taskId',
    path: '/link-personal-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/link-personal-task.$taskId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute =
  AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteImport.update({
    id: '/delete-progressive-info/$progressiveInfoId',
    path: '/delete-progressive-info/$progressiveInfoId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/delete-progressive-info.$progressiveInfoId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardDeleteCollectiveTaskTaskIdRouteRoute =
  AppDashboardDeleteCollectiveTaskTaskIdRouteImport.update({
    id: '/delete-collective-task/$taskId',
    path: '/delete-collective-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/delete-collective-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardArchivePersonalTaskTaskIdRouteRoute =
  AppDashboardArchivePersonalTaskTaskIdRouteImport.update({
    id: '/archive-personal-task/$taskId',
    path: '/archive-personal-task/$taskId',
    getParentRoute: () => AppDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/dashboard/archive-personal-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdRouteRoute =
  AppBusinessesRmaRmaRmaIdRouteImport.update({
    id: '/businesses-rma_/rma/$rmaId',
    path: '/businesses-rma/rma/$rmaId',
    getParentRoute: () => AppRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/businesses-rma_/rma.$rmaId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaBusinessBusinessIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdRouteImport.update({
    id: '/businesses-rma_/business/$businessId',
    path: '/businesses-rma/business/$businessId',
    getParentRoute: () => AppRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/businesses-rma_/business.$businessId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaRmaRmaIdIndexRoute =
  AppBusinessesRmaRmaRmaIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdRouteRoute,
  } as any)

const AppBusinessesRmaBusinessBusinessIdIndexRoute =
  AppBusinessesRmaBusinessBusinessIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any)

const AppToolsVvaDeleteVvaIdRouteRoute =
  AppToolsVvaDeleteVvaIdRouteImport.update({
    id: '/delete/$vvaId',
    path: '/delete/$vvaId',
    getParentRoute: () => AppToolsVvaRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/vva/delete.$vvaId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsSchedulerDetailsRdvIdRouteRoute =
  AppToolsSchedulerDetailsRdvIdRouteImport.update({
    id: '/details/$rdvId',
    path: '/details/$rdvId',
    getParentRoute: () => AppToolsSchedulerRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/scheduler/details.$rdvId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsProductShelvesDeleteProductShelfIdRouteRoute =
  AppToolsProductShelvesDeleteProductShelfIdRouteImport.update({
    id: '/delete/$productShelfId',
    path: '/delete/$productShelfId',
    getParentRoute: () => AppToolsProductShelvesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/product-shelves/delete.$productShelfId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsProductInventoryUpdateStockIdRouteRoute =
  AppToolsProductInventoryUpdateStockIdRouteImport.update({
    id: '/update/$stockId',
    path: '/update/$stockId',
    getParentRoute: () => AppToolsProductInventoryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/product-inventory/update.$stockId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsProductFiltersUpdateProductFilterIdRouteRoute =
  AppToolsProductFiltersUpdateProductFilterIdRouteImport.update({
    id: '/update/$productFilterId',
    path: '/update/$productFilterId',
    getParentRoute: () => AppToolsProductFiltersRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/product-filters/update.$productFilterId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsProductFiltersDeleteProductFilterIdRouteRoute =
  AppToolsProductFiltersDeleteProductFilterIdRouteImport.update({
    id: '/delete/$productFilterId',
    path: '/delete/$productFilterId',
    getParentRoute: () => AppToolsProductFiltersRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/product-filters/delete.$productFilterId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute =
  AppToolsPredefinedTextsUpdatePredefinedTextIdRouteImport.update({
    id: '/update/$predefinedTextId',
    path: '/update/$predefinedTextId',
    getParentRoute: () => AppToolsPredefinedTextsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/predefined-texts/update.$predefinedTextId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute =
  AppToolsPredefinedTextsDeletePredefinedTextIdRouteImport.update({
    id: '/delete/$predefinedTextId',
    path: '/delete/$predefinedTextId',
    getParentRoute: () => AppToolsPredefinedTextsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/predefined-texts/delete.$predefinedTextId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute =
  AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteImport.update({
    id: '/update/$predefinedMessageId',
    path: '/update/$predefinedMessageId',
    getParentRoute: () => AppToolsPredefinedMessagesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/predefined-messages/update.$predefinedMessageId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute =
  AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteImport.update({
    id: '/delete/$predefinedMessageId',
    path: '/delete/$predefinedMessageId',
    getParentRoute: () => AppToolsPredefinedMessagesRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/predefined-messages/delete.$predefinedMessageId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsNewsUpdateNewsIdRouteRoute =
  AppToolsNewsUpdateNewsIdRouteImport.update({
    id: '/update/$newsId',
    path: '/update/$newsId',
    getParentRoute: () => AppToolsNewsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/news/update.$newsId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsNewsDeleteNewsIdRouteRoute =
  AppToolsNewsDeleteNewsIdRouteImport.update({
    id: '/delete/$newsId',
    path: '/delete/$newsId',
    getParentRoute: () => AppToolsNewsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/news/delete.$newsId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsMailsUpdateMailIdRouteRoute =
  AppToolsMailsUpdateMailIdRouteImport.update({
    id: '/update/$mailId',
    path: '/update/$mailId',
    getParentRoute: () => AppToolsMailsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/mails/update.$mailId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsMailsShowMailIdRouteRoute =
  AppToolsMailsShowMailIdRouteImport.update({
    id: '/show/$mailId',
    path: '/show/$mailId',
    getParentRoute: () => AppToolsMailsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/mails/show.$mailId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsMailsDeleteMailIdRouteRoute =
  AppToolsMailsDeleteMailIdRouteImport.update({
    id: '/delete/$mailId',
    path: '/delete/$mailId',
    getParentRoute: () => AppToolsMailsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/mails/delete.$mailId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsFormationsUpdateFormationIdRouteRoute =
  AppToolsFormationsUpdateFormationIdRouteImport.update({
    id: '/update/$formationId',
    path: '/update/$formationId',
    getParentRoute: () => AppToolsFormationsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/formations/update.$formationId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsFormationsSubscribersFormationDetailIdRouteRoute =
  AppToolsFormationsSubscribersFormationDetailIdRouteImport.update({
    id: '/subscribers/$formationDetailId',
    path: '/subscribers/$formationDetailId',
    getParentRoute: () => AppToolsFormationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/formations/subscribers.$formationDetailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsFormationsDeleteFormationIdRouteRoute =
  AppToolsFormationsDeleteFormationIdRouteImport.update({
    id: '/delete/$formationId',
    path: '/delete/$formationId',
    getParentRoute: () => AppToolsFormationsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/formations/delete.$formationId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsFormationsCreateDetailsRouteRoute =
  AppToolsFormationsCreateDetailsRouteImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AppToolsFormationsCreateRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/formations/create/details/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsFormationsCreateAddDetailRouteRoute =
  AppToolsFormationsCreateAddDetailRouteImport.update({
    id: '/add-detail',
    path: '/add-detail',
    getParentRoute: () => AppToolsFormationsCreateRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/formations/create/add-detail/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsExternalLinksUpdateExternalLinkIdRouteRoute =
  AppToolsExternalLinksUpdateExternalLinkIdRouteImport.update({
    id: '/update/$externalLinkId',
    path: '/update/$externalLinkId',
    getParentRoute: () => AppToolsExternalLinksRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/external-links/update.$externalLinkId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsExternalLinksDeleteExternalLinkIdRouteRoute =
  AppToolsExternalLinksDeleteExternalLinkIdRouteImport.update({
    id: '/delete/$externalLinkId',
    path: '/delete/$externalLinkId',
    getParentRoute: () => AppToolsExternalLinksRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/external-links/delete.$externalLinkId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsExternalLinksArchiveExternalLinkIdRouteRoute =
  AppToolsExternalLinksArchiveExternalLinkIdRouteImport.update({
    id: '/archive/$externalLinkId',
    path: '/archive/$externalLinkId',
    getParentRoute: () => AppToolsExternalLinksRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/external-links/archive.$externalLinkId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsEmailsEmailIdReplyRouteRoute =
  AppToolsEmailsEmailIdReplyRouteImport.update({
    id: '/reply',
    path: '/reply',
    getParentRoute: () => AppToolsEmailsEmailIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/emails/$emailId/reply/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppToolsDepartmentsUpdateDepartmentIdRouteRoute =
  AppToolsDepartmentsUpdateDepartmentIdRouteImport.update({
    id: '/update/$departmentId',
    path: '/update/$departmentId',
    getParentRoute: () => AppToolsDepartmentsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/departments/update.$departmentId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsDepartmentsDeleteDepartmentIdRouteRoute =
  AppToolsDepartmentsDeleteDepartmentIdRouteImport.update({
    id: '/delete/$departmentId',
    path: '/delete/$departmentId',
    getParentRoute: () => AppToolsDepartmentsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/departments/delete.$departmentId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsDdnsDeleteDdnsIdRouteRoute =
  AppToolsDdnsDeleteDdnsIdRouteImport.update({
    id: '/delete/$ddnsId',
    path: '/delete/$ddnsId',
    getParentRoute: () => AppToolsDdnsRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/tools/ddns/delete.$ddnsId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppProductsProductIdManageCreateVersionRouteRoute =
  AppProductsProductIdManageCreateVersionRouteImport.update({
    id: '/create-version',
    path: '/create-version',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/create-version/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageCreateStockRouteRoute =
  AppProductsProductIdManageCreateStockRouteImport.update({
    id: '/create-stock',
    path: '/create-stock',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/create-stock/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageAddSpecificationRouteRoute =
  AppProductsProductIdManageAddSpecificationRouteImport.update({
    id: '/add-specification',
    path: '/add-specification',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/add-specification/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageAddNomenclatureDetailRouteRoute =
  AppProductsProductIdManageAddNomenclatureDetailRouteImport.update({
    id: '/add-nomenclature-detail',
    path: '/add-nomenclature-detail',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/add-nomenclature-detail/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageAddAssociatedProductRouteRoute =
  AppProductsProductIdManageAddAssociatedProductRouteImport.update({
    id: '/add-associated-product',
    path: '/add-associated-product',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/add-associated-product/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsRenameGedObjectRouteRoute =
  AppProductsProductIdInformationsRenameGedObjectRouteImport.update({
    id: '/rename-ged-object',
    path: '/rename-ged-object',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/rename-ged-object/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsImportGedFilesRouteRoute =
  AppProductsProductIdInformationsImportGedFilesRouteImport.update({
    id: '/import-ged-files',
    path: '/import-ged-files',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/import-ged-files/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsDeleteGedObjectRouteRoute =
  AppProductsProductIdInformationsDeleteGedObjectRouteImport.update({
    id: '/delete-ged-object',
    path: '/delete-ged-object',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/delete-ged-object/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute =
  AppProductsProductIdInformationsCreateLifesheetCommentRouteImport.update({
    id: '/create-lifesheet-comment',
    path: '/create-lifesheet-comment',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/create-lifesheet-comment/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsCreateGedDirectoryRouteRoute =
  AppProductsProductIdInformationsCreateGedDirectoryRouteImport.update({
    id: '/create-ged-directory',
    path: '/create-ged-directory',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/create-ged-directory/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute =
  AppProductsSerialNumbersUpdateSerialNumberIdRouteImport.update({
    id: '/update/$serialNumberId',
    path: '/update/$serialNumberId',
    getParentRoute: () => AppProductsSerialNumbersRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products/serial-numbers/update.$serialNumberId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute =
  AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteImport.update({
    id: '/remove-from-business/$serialNumberId',
    path: '/remove-from-business/$serialNumberId',
    getParentRoute: () => AppProductsSerialNumbersRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products/serial-numbers/remove-from-business.$serialNumberId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute =
  AppProductsSerialNumbersDeleteSerialNumberIdRouteImport.update({
    id: '/delete/$serialNumberId',
    path: '/delete/$serialNumberId',
    getParentRoute: () => AppProductsSerialNumbersRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products/serial-numbers/delete.$serialNumberId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute =
  AppProductsSerialNumbersCreateRmaSerialNumberIdRouteImport.update({
    id: '/create-rma/$serialNumberId',
    path: '/create-rma/$serialNumberId',
    getParentRoute: () => AppProductsSerialNumbersRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products/serial-numbers/create-rma.$serialNumberId/route.lazy'
    ).then((d) => d.Route),
  )

const AppFaqGedFaqIdImportFilesRouteRoute =
  AppFaqGedFaqIdImportFilesRouteImport.update({
    id: '/import-files',
    path: '/import-files',
    getParentRoute: () => AppFaqGedFaqIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/faq/ged.$faqId/import-files/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppFaqGedFaqIdCreateDirectoryRouteRoute =
  AppFaqGedFaqIdCreateDirectoryRouteImport.update({
    id: '/create-directory',
    path: '/create-directory',
    getParentRoute: () => AppFaqGedFaqIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/faq/ged.$faqId/create-directory/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute =
  AppEnterprisesEnterpriseIdUpdateContactContactIdRouteImport.update({
    id: '/update-contact/$contactId',
    path: '/update-contact/$contactId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/update-contact.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute =
  AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteImport.update({
    id: '/update-contact-password/$contactId',
    path: '/update-contact-password/$contactId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/update-contact-password.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute =
  AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteImport.update({
    id: '/unrelate-business-rma/$businessRmaId',
    path: '/unrelate-business-rma/$businessRmaId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/unrelate-business-rma.$businessRmaId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute =
  AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteImport.update({
    id: '/unlink-task/$taskId',
    path: '/unlink-task/$taskId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/unlink-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRoute =
  AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteImport.update({
    id: '/task-email/$taskId',
    path: '/task-email/$taskId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/task-email.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute =
  AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteImport.update({
    id: '/send-email-to-contact/$contactId',
    path: '/send-email-to-contact/$contactId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/send-email-to-contact.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute =
  AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/rename-ged-object/$objectRelativePath',
      path: '/rename-ged-object/$objectRelativePath',
      getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/rename-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute =
  AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteImport.update({
    id: '/lifesheet-email/$lifesheetId',
    path: '/lifesheet-email/$lifesheetId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/lifesheet-email.$lifesheetId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute =
  AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/delete-ged-object/$objectRelativePath',
      path: '/delete-ged-object/$objectRelativePath',
      getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/delete-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute =
  AppEnterprisesEnterpriseIdDeleteContactContactIdRouteImport.update({
    id: '/delete-contact/$contactId',
    path: '/delete-contact/$contactId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/delete-contact.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute =
  AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteImport.update({
    id: '/create-contact-business/$contactId',
    path: '/create-contact-business/$contactId',
    getParentRoute: () => AppEnterprisesEnterpriseIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/create-contact-business.$contactId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdAddressBookImportRouteRoute =
  AppEnterprisesEnterpriseIdAddressBookImportRouteImport.update({
    id: '/import',
    path: '/import',
    getParentRoute: () => AppEnterprisesEnterpriseIdAddressBookRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/address-book/import/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute =
  AppEnterprisesEnterpriseIdAddressBookCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppEnterprisesEnterpriseIdAddressBookRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/address-book/create/route.lazy'
    ).then((d) => d.Route),
  )

const AppDashboardTaskEmailTaskIdReplyRouteRoute =
  AppDashboardTaskEmailTaskIdReplyRouteImport.update({
    id: '/reply',
    path: '/reply',
    getParentRoute: () => AppDashboardTaskEmailTaskIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/dashboard/task-email.$taskId/reply/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaRmaRmaIdSupportRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportRouteImport.update({
    id: '/support',
    path: '/support',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/businesses-rma_/rma.$rmaId/support/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaRmaRmaIdReceptionRouteRoute =
  AppBusinessesRmaRmaRmaIdReceptionRouteImport.update({
    id: '/reception',
    path: '/reception',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/businesses-rma_/rma.$rmaId/reception/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaRmaRmaIdDeliveryRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryRouteImport.update({
    id: '/delivery',
    path: '/delivery',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdRouteRoute,
  } as any).lazy(() =>
    import('./routes/app/businesses-rma_/rma.$rmaId/delivery/route.lazy').then(
      (d) => d.Route,
    ),
  )

const AppBusinessesRmaBusinessBusinessIdStudyRouteRoute =
  AppBusinessesRmaBusinessBusinessIdStudyRouteImport.update({
    id: '/businesses-rma_/business/$businessId_/study',
    path: '/businesses-rma/business/$businessId/study',
    getParentRoute: () => AppRouteRoute,
  } as any)

const AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationRouteImport.update({
    id: '/quotation',
    path: '/quotation',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardRouteImport.update({
    id: '/dashboard',
    path: '/dashboard',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpRouteImport.update({
    id: '/bp',
    path: '/bp',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBlRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBlRouteImport.update({
    id: '/bl',
    path: '/bl',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bl/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBillRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBillRouteImport.update({
    id: '/bill',
    path: '/bill',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bill/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdArcRouteRoute =
  AppBusinessesRmaBusinessBusinessIdArcRouteImport.update({
    id: '/arc',
    path: '/arc',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/arc/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdStudyIndexRoute =
  AppBusinessesRmaBusinessBusinessIdStudyIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdStudyRouteRoute,
  } as any)

const AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute =
  AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyImport.update({
    id: '/update',
    path: '/update',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBlRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bl/update/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsSchedulerDetailsRdvIdUpdateRouteRoute =
  AppToolsSchedulerDetailsRdvIdUpdateRouteImport.update({
    id: '/update',
    path: '/update',
    getParentRoute: () => AppToolsSchedulerDetailsRdvIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/scheduler/details.$rdvId/update/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsSchedulerDetailsRdvIdDeleteRouteRoute =
  AppToolsSchedulerDetailsRdvIdDeleteRouteImport.update({
    id: '/delete',
    path: '/delete',
    getParentRoute: () => AppToolsSchedulerDetailsRdvIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/scheduler/details.$rdvId/delete/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsFormationsUpdateFormationIdDetailsRouteRoute =
  AppToolsFormationsUpdateFormationIdDetailsRouteImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AppToolsFormationsUpdateFormationIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/formations/update.$formationId/details/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsFormationsUpdateFormationIdAddDetailRouteRoute =
  AppToolsFormationsUpdateFormationIdAddDetailRouteImport.update({
    id: '/add-detail',
    path: '/add-detail',
    getParentRoute: () => AppToolsFormationsUpdateFormationIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/formations/update.$formationId/add-detail/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute =
  AppToolsFormationsSubscribersFormationDetailIdCreateRouteImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () =>
      AppToolsFormationsSubscribersFormationDetailIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/tools/formations/subscribers.$formationDetailId/create/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageUpdateVersionVersionIdRouteRoute =
  AppProductsProductIdManageUpdateVersionVersionIdRouteImport.update({
    id: '/update-version/$versionId',
    path: '/update-version/$versionId',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/update-version.$versionId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageUpdateStockStockIdRouteRoute =
  AppProductsProductIdManageUpdateStockStockIdRouteImport.update({
    id: '/update-stock/$stockId',
    path: '/update-stock/$stockId',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/update-stock.$stockId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute =
  AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteImport.update(
    {
      id: '/update-specification/$specificationId',
      path: '/update-specification/$specificationId',
      getParentRoute: () => AppProductsProductIdManageRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/update-specification.$specificationId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute =
  AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteImport.update(
    {
      id: '/update-nomenclature-detail/$nomenclatureDetailId',
      path: '/update-nomenclature-detail/$nomenclatureDetailId',
      getParentRoute: () => AppProductsProductIdManageRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/update-nomenclature-detail.$nomenclatureDetailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageStockHistoryStockIdRouteRoute =
  AppProductsProductIdManageStockHistoryStockIdRouteImport.update({
    id: '/stock-history/$stockId',
    path: '/stock-history/$stockId',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/stock-history.$stockId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute =
  AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteImport.update(
    {
      id: '/remove-associated-product/$associatedProductId',
      path: '/remove-associated-product/$associatedProductId',
      getParentRoute: () => AppProductsProductIdManageRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/remove-associated-product.$associatedProductId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageDeleteVersionVersionIdRouteRoute =
  AppProductsProductIdManageDeleteVersionVersionIdRouteImport.update({
    id: '/delete-version/$versionId',
    path: '/delete-version/$versionId',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/delete-version.$versionId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageDeleteStockStockIdRouteRoute =
  AppProductsProductIdManageDeleteStockStockIdRouteImport.update({
    id: '/delete-stock/$stockId',
    path: '/delete-stock/$stockId',
    getParentRoute: () => AppProductsProductIdManageRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/delete-stock.$stockId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute =
  AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteImport.update(
    {
      id: '/delete-specification/$specificationId',
      path: '/delete-specification/$specificationId',
      getParentRoute: () => AppProductsProductIdManageRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/delete-specification.$specificationId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute =
  AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteImport.update(
    {
      id: '/delete-nomenclature-detail/$nomenclatureDetailId',
      path: '/delete-nomenclature-detail/$nomenclatureDetailId',
      getParentRoute: () => AppProductsProductIdManageRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/delete-nomenclature-detail.$nomenclatureDetailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdManageAddSpecificationFilterIdRouteRoute =
  AppProductsProductIdManageAddSpecificationFilterIdRouteImport.update({
    id: '/$filterId',
    path: '/$filterId',
    getParentRoute: () => AppProductsProductIdManageAddSpecificationRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/manage/add-specification/$filterId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute =
  AppProductsProductIdInformationsUnlinkTaskTaskIdRouteImport.update({
    id: '/unlink-task/$taskId',
    path: '/unlink-task/$taskId',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/unlink-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsTaskEmailTaskIdRouteRoute =
  AppProductsProductIdInformationsTaskEmailTaskIdRouteImport.update({
    id: '/task-email/$taskId',
    path: '/task-email/$taskId',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/task-email.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute =
  AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteImport.update({
    id: '/lifesheet-email/$lifesheetId',
    path: '/lifesheet-email/$lifesheetId',
    getParentRoute: () => AppProductsProductIdInformationsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/lifesheet-email.$lifesheetId/route.lazy'
    ).then((d) => d.Route),
  )

const AppFaqGedFaqIdRenameItemRelativePathRouteRoute =
  AppFaqGedFaqIdRenameItemRelativePathRouteImport.update({
    id: '/rename/$itemRelativePath',
    path: '/rename/$itemRelativePath',
    getParentRoute: () => AppFaqGedFaqIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/faq/ged.$faqId/rename.$itemRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppFaqGedFaqIdDeleteItemRelativePathRouteRoute =
  AppFaqGedFaqIdDeleteItemRelativePathRouteImport.update({
    id: '/delete/$itemRelativePath',
    path: '/delete/$itemRelativePath',
    getParentRoute: () => AppFaqGedFaqIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/faq/ged.$faqId/delete.$itemRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute =
  AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteImport.update({
    id: '/reply',
    path: '/reply',
    getParentRoute: () => AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/task-email.$taskId/reply/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRoute =
  AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteImport.update({
    id: '/email/$emailId',
    path: '/email/$emailId',
    getParentRoute: () => AppEnterprisesEnterpriseIdEmailHistoryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/email-history/email.$emailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute =
  AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteImport.update({
    id: '/update/$addressId',
    path: '/update/$addressId',
    getParentRoute: () => AppEnterprisesEnterpriseIdAddressBookRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/address-book/update.$addressId/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute =
  AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteImport.update({
    id: '/delete/$addressId',
    path: '/delete/$addressId',
    getParentRoute: () => AppEnterprisesEnterpriseIdAddressBookRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/address-book/delete.$addressId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportPdfRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportPdfRouteImport.update({
    id: '/pdf',
    path: '/pdf',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/pdf/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteImport.update({
    id: '/import-ged-files',
    path: '/import-ged-files',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/import-ged-files/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteImport.update({
    id: '/create-link',
    path: '/create-link',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/create-link/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteImport.update({
    id: '/create-lifesheet',
    path: '/create-lifesheet',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/create-lifesheet/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteImport.update({
    id: '/create-ged-directory',
    path: '/create-ged-directory',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/create-ged-directory/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteImport.update({
    id: '/create-detail',
    path: '/create-detail',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/create-detail/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdReceptionPdfRouteRoute =
  AppBusinessesRmaRmaRmaIdReceptionPdfRouteImport.update({
    id: '/pdf',
    path: '/pdf',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdReceptionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/reception/pdf/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute =
  AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteImport.update({
    id: '/create-detail',
    path: '/create-detail',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdReceptionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/reception/create-detail/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteImport.update({
    id: '/travel-voucher',
    path: '/travel-voucher',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdDeliveryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/delivery/travel-voucher/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryPdfRouteImport.update({
    id: '/pdf',
    path: '/pdf',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdDeliveryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/delivery/pdf/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteImport.update({
    id: '/create-detail',
    path: '/create-detail',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdDeliveryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/delivery/create-detail/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute =
  AppBusinessesRmaBusinessBusinessIdStudyExpertRouteImport.update({
    id: '/expert',
    path: '/expert',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdStudyRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/study/expert/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute =
  AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteImport.update({
    id: '/automatic',
    path: '/automatic',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdStudyRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/study/automatic/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport.update({
    id: '/businesses-rma_/business/$businessId_/assistance/$assistanceId',
    path: '/businesses-rma/business/$businessId/assistance/$assistanceId',
    getParentRoute: () => AppRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteImport.update(
    {
      id: '/update-shipping-price',
      path: '/update-shipping-price',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/update-shipping-price/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteImport.update({
    id: '/pdf',
    path: '/pdf',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/pdf/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteImport.update(
    {
      id: '/commercial-notice',
      path: '/commercial-notice',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/commercial-notice/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteImport.update(
    {
      id: '/update-responsible',
      path: '/update-responsible',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/update-responsible/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteImport.update(
    {
      id: '/update-representative',
      path: '/update-representative',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/update-representative/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteImport.update(
    {
      id: '/update-billing-address',
      path: '/update-billing-address',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/update-billing-address/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteImport.update({
    id: '/send-email',
    path: '/send-email',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/send-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteImport.update({
    id: '/import-ged-files',
    path: '/import-ged-files',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/import-ged-files/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteImport.update({
    id: '/email-history',
    path: '/email-history',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/email-history/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteImport.update({
    id: '/delete',
    path: '/delete',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/delete/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteImport.update({
    id: '/create-link',
    path: '/create-link',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/create-link/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteImport.update({
    id: '/create-lifesheet',
    path: '/create-lifesheet',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/create-lifesheet/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteImport.update(
    {
      id: '/create-ged-directory',
      path: '/create-ged-directory',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/create-ged-directory/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteImport.update({
    id: '/address-book',
    path: '/address-book',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteImport.update({
    id: '/travel-voucher',
    path: '/travel-voucher',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/travel-voucher/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBlRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bl/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBillRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bill/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBillCreditsRouteImport.update({
    id: '/credits',
    path: '/credits',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBillRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bill/credits/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute =
  AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteImport.update({
    id: '/update-shipping-price',
    path: '/update-shipping-price',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdArcRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/arc/update-shipping-price/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdArcPdfRouteRoute =
  AppBusinessesRmaBusinessBusinessIdArcPdfRouteImport.update({
    id: '/pdf',
    path: '/pdf',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdArcRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/arc/pdf/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute =
  AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteImport.update(
    {
      id: '/send-email/$subscriptionId',
      path: '/send-email/$subscriptionId',
      getParentRoute: () =>
        AppToolsFormationsSubscribersFormationDetailIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/tools/formations/subscribers.$formationDetailId/send-email.$subscriptionId/route.lazy'
    ).then((d) => d.Route),
  )

const AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute =
  AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteImport.update(
    {
      id: '/delete/$subscriptionId',
      path: '/delete/$subscriptionId',
      getParentRoute: () =>
        AppToolsFormationsSubscribersFormationDetailIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/tools/formations/subscribers.$formationDetailId/delete.$subscriptionId/route.lazy'
    ).then((d) => d.Route),
  )

const AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute =
  AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteImport.update({
    id: '/reply',
    path: '/reply',
    getParentRoute: () =>
      AppProductsProductIdInformationsTaskEmailTaskIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/products_.$productId/informations/task-email.$taskId/reply/route.lazy'
    ).then((d) => d.Route),
  )

const AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute =
  AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteImport.update({
    id: '/reply',
    path: '/reply',
    getParentRoute: () =>
      AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/enterprises_.$enterpriseId/email-history/email.$emailId/reply/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteImport.update({
    id: '/update-detail/$detailId',
    path: '/update-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/update-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteImport.update({
    id: '/unlink-task/$taskId',
    path: '/unlink-task/$taskId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/unlink-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteImport.update({
    id: '/task-email/$taskId',
    path: '/task-email/$taskId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/task-email.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/rename-ged-object/$objectRelativePath',
      path: '/rename-ged-object/$objectRelativePath',
      getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/rename-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportPdfRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/pdf/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteImport.update({
    id: '/lifesheet-email/$lifesheetId',
    path: '/lifesheet-email/$lifesheetId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/lifesheet-email.$lifesheetId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteImport.update({
    id: '/delete-link/$associatedId',
    path: '/delete-link/$associatedId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/delete-link.$associatedId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteImport.update({
    id: '/delete-ged-object/$relativePath',
    path: '/delete-ged-object/$relativePath',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/delete-ged-object.$relativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteImport.update({
    id: '/delete-detail/$detailId',
    path: '/delete-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdSupportRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/delete-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute =
  AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteImport.update({
    id: '/update-detail/$detailId',
    path: '/update-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdReceptionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/reception/update-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute =
  AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdReceptionPdfRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/reception/pdf/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute =
  AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteImport.update({
    id: '/delete-detail/$detailId',
    path: '/delete-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdReceptionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/reception/delete-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteImport.update({
    id: '/update-detail/$detailId',
    path: '/update-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdDeliveryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/delivery/update-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/delivery/pdf/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute =
  AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteImport.update({
    id: '/delete-detail/$detailId',
    path: '/delete-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaRmaRmaIdDeliveryRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/delivery/delete-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteImport.update(
    {
      id: '/pdf',
      path: '/pdf',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/pdf/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteImport.update(
    {
      id: '/import-ged-files',
      path: '/import-ged-files',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/import-ged-files/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteImport.update(
    {
      id: '/edit-subtitle',
      path: '/edit-subtitle',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-subtitle/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteImport.update(
    {
      id: '/edit-no-billed-time',
      path: '/edit-no-billed-time',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-no-billed-time/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteImport.update(
    {
      id: '/edit-cumulated-time',
      path: '/edit-cumulated-time',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-cumulated-time/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteImport.update(
    {
      id: '/delete',
      path: '/delete',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteImport.update(
    {
      id: '/create-link',
      path: '/create-link',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-link/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteImport.update(
    {
      id: '/create-lifesheet',
      path: '/create-lifesheet',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-lifesheet/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteImport.update(
    {
      id: '/create-ged-directory',
      path: '/create-ged-directory',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-ged-directory/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteImport.update(
    {
      id: '/create-faq',
      path: '/create-faq',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-faq/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteImport.update(
    {
      id: '/update-subquotation/$subquotationId',
      path: '/update-subquotation/$subquotationId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/update-subquotation.$subquotationId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteImport.update(
    {
      id: '/update-detail/$detailId',
      path: '/update-detail/$detailId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/update-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () =>
      AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/pdf/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteImport.update(
    {
      id: '/delete-subquotation/$subquotationId',
      path: '/delete-subquotation/$subquotationId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/delete-subquotation.$subquotationId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteImport.update(
    {
      id: '/delete-detail/$detailId',
      path: '/delete-detail/$detailId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/delete-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteImport.update(
    {
      id: '/create-detail/$subquotationId',
      path: '/create-detail/$subquotationId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/create-detail.$subquotationId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteImport.update(
    {
      id: '/create-associated-detail/$detailId',
      path: '/create-associated-detail/$detailId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/create-associated-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteImport.update(
    {
      id: '/send-by-email',
      path: '/send-by-email',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/quotation/commercial-notice/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteImport.update(
    {
      id: '/unlink-task/$taskId',
      path: '/unlink-task/$taskId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/unlink-task.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteImport.update({
    id: '/task-email/$taskId',
    path: '/task-email/$taskId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/task-email.$taskId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/rename-ged-object/$objectRelativePath',
      path: '/rename-ged-object/$objectRelativePath',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/rename-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteImport.update(
    {
      id: '/lifesheet-email/$lifesheetId',
      path: '/lifesheet-email/$lifesheetId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/lifesheet-email.$lifesheetId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteImport.update(
    {
      id: '/$emailId',
      path: '/$emailId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/email-history/$emailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteImport.update(
    {
      id: '/delete-link/$associatedId',
      path: '/delete-link/$associatedId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/delete-link.$associatedId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/delete-ged-object/$objectRelativePath',
      path: '/delete-ged-object/$objectRelativePath',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/delete-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteImport.update(
    {
      id: '/confirm-quotation-import/$otherBusinessId',
      path: '/confirm-quotation-import/$otherBusinessId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/confirm-quotation-import.$otherBusinessId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteImport.update(
    {
      id: '/create',
      path: '/create',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/create/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteImport.update({
    id: '/update-detail/$detailId',
    path: '/update-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/update-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteImport.update({
    id: '/delete-serial/$serialId',
    path: '/delete-serial/$serialId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/delete-serial.$serialId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteImport.update({
    id: '/delete-detail/$detailId',
    path: '/delete-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/delete-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteImport.update(
    {
      id: '/create-serial-rma/$serialId',
      path: '/create-serial-rma/$serialId',
      getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/create-serial-rma.$serialId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteImport.update(
    {
      id: '/create-detail-rma/$detailId',
      path: '/create-detail-rma/$detailId',
      getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/create-detail-rma.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteImport.update({
    id: '/add-serial/$detailId',
    path: '/add-serial/$detailId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdBpRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bp/add-serial.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () =>
      AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/bill/credits/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteImport.update({
    id: '/update-detail/$detailId',
    path: '/update-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdArcRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/arc/update-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteImport.update({
    id: '/send-by-email',
    path: '/send-by-email',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdArcPdfRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/arc/pdf/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteImport.update({
    id: '/delete-detail/$detailId',
    path: '/delete-detail/$detailId',
    getParentRoute: () => AppBusinessesRmaBusinessBusinessIdArcRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/arc/delete-detail.$detailId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute =
  AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteImport.update({
    id: '/reply',
    path: '/reply',
    getParentRoute: () =>
      AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/app/businesses-rma_/rma.$rmaId/support/task-email.$taskId/reply/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/rename-ged-object/$objectRelativePath',
      path: '/rename-ged-object/$objectRelativePath',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/rename-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteImport.update(
    {
      id: '/send-by-email',
      path: '/send-by-email',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/pdf/send-by-email/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteImport.update(
    {
      id: '/lifesheet-email/$lifesheetId',
      path: '/lifesheet-email/$lifesheetId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/lifesheet-email.$lifesheetId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteImport.update(
    {
      id: '/delete-link/$associatedId',
      path: '/delete-link/$associatedId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete-link.$associatedId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteImport.update(
    {
      id: '/delete-ged-object/$objectRelativePath',
      path: '/delete-ged-object/$objectRelativePath',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete-ged-object.$objectRelativePath/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute =
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteImport.update(
    {
      id: '/predefined-messages',
      path: '/predefined-messages',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRoute,
    } as any,
  )

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteImport.update(
    {
      id: '/reply',
      path: '/reply',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/email-history/$emailId/reply/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteImport.update(
    {
      id: '/update/$addressId',
      path: '/update/$addressId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/update.$addressId/route.lazy'
    ).then((d) => d.Route),
  )

const AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute =
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteImport.update(
    {
      id: '/delete/$addressId',
      path: '/delete/$addressId',
      getParentRoute: () =>
        AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/app/businesses-rma_/business.$businessId/dashboard/address-book/delete.$addressId/route.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/app': {
      id: '/app'
      path: '/app'
      fullPath: '/app'
      preLoaderRoute: typeof AppRouteImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/app/businesses-rma': {
      id: '/app/businesses-rma'
      path: '/businesses-rma'
      fullPath: '/app/businesses-rma'
      preLoaderRoute: typeof AppBusinessesRmaRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/dashboard': {
      id: '/app/dashboard'
      path: '/dashboard'
      fullPath: '/app/dashboard'
      preLoaderRoute: typeof AppDashboardRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/enterprises': {
      id: '/app/enterprises'
      path: '/enterprises'
      fullPath: '/app/enterprises'
      preLoaderRoute: typeof AppEnterprisesRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/external-links': {
      id: '/app/external-links'
      path: '/external-links'
      fullPath: '/app/external-links'
      preLoaderRoute: typeof AppExternalLinksRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/faq': {
      id: '/app/faq'
      path: '/faq'
      fullPath: '/app/faq'
      preLoaderRoute: typeof AppFaqRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/products': {
      id: '/app/products'
      path: '/products'
      fullPath: '/app/products'
      preLoaderRoute: typeof AppProductsRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/tools': {
      id: '/app/tools'
      path: '/tools'
      fullPath: '/app/tools'
      preLoaderRoute: typeof AppToolsRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginRouteImport
      parentRoute: typeof AuthRouteImport
    }
    '/auth/forgot-password': {
      id: '/auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/auth/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordRouteLazyImport
      parentRoute: typeof AuthRouteImport
    }
    '/app/': {
      id: '/app/'
      path: '/'
      fullPath: '/app/'
      preLoaderRoute: typeof AppIndexImport
      parentRoute: typeof AppRouteImport
    }
    '/auth/': {
      id: '/auth/'
      path: '/'
      fullPath: '/auth/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/app/businesses-rma/representative-turnover': {
      id: '/app/businesses-rma/representative-turnover'
      path: '/representative-turnover'
      fullPath: '/app/businesses-rma/representative-turnover'
      preLoaderRoute: typeof AppBusinessesRmaRepresentativeTurnoverRouteImport
      parentRoute: typeof AppBusinessesRmaRouteImport
    }
    '/app/businesses-rma/search-by-products': {
      id: '/app/businesses-rma/search-by-products'
      path: '/search-by-products'
      fullPath: '/app/businesses-rma/search-by-products'
      preLoaderRoute: typeof AppBusinessesRmaSearchByProductsRouteImport
      parentRoute: typeof AppBusinessesRmaRouteImport
    }
    '/app/dashboard/create-collective-task': {
      id: '/app/dashboard/create-collective-task'
      path: '/create-collective-task'
      fullPath: '/app/dashboard/create-collective-task'
      preLoaderRoute: typeof AppDashboardCreateCollectiveTaskRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/create-personal-task': {
      id: '/app/dashboard/create-personal-task'
      path: '/create-personal-task'
      fullPath: '/app/dashboard/create-personal-task'
      preLoaderRoute: typeof AppDashboardCreatePersonalTaskRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/create-progressive-info': {
      id: '/app/dashboard/create-progressive-info'
      path: '/create-progressive-info'
      fullPath: '/app/dashboard/create-progressive-info'
      preLoaderRoute: typeof AppDashboardCreateProgressiveInfoRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/delete-collective-tasks': {
      id: '/app/dashboard/delete-collective-tasks'
      path: '/delete-collective-tasks'
      fullPath: '/app/dashboard/delete-collective-tasks'
      preLoaderRoute: typeof AppDashboardDeleteCollectiveTasksRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/enterprises/create': {
      id: '/app/enterprises/create'
      path: '/create'
      fullPath: '/app/enterprises/create'
      preLoaderRoute: typeof AppEnterprisesCreateRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises_/$enterpriseId': {
      id: '/app/enterprises_/$enterpriseId'
      path: '/enterprises/$enterpriseId'
      fullPath: '/app/enterprises/$enterpriseId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/external-links_/$externalLinkId': {
      id: '/app/external-links_/$externalLinkId'
      path: '/external-links/$externalLinkId'
      fullPath: '/app/external-links/$externalLinkId'
      preLoaderRoute: typeof AppExternalLinksExternalLinkIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/faq/create': {
      id: '/app/faq/create'
      path: '/create'
      fullPath: '/app/faq/create'
      preLoaderRoute: typeof AppFaqCreateRouteImport
      parentRoute: typeof AppFaqRouteImport
    }
    '/app/products/create': {
      id: '/app/products/create'
      path: '/create'
      fullPath: '/app/products/create'
      preLoaderRoute: typeof AppProductsCreateRouteImport
      parentRoute: typeof AppProductsRouteImport
    }
    '/app/products/serial-numbers': {
      id: '/app/products/serial-numbers'
      path: '/serial-numbers'
      fullPath: '/app/products/serial-numbers'
      preLoaderRoute: typeof AppProductsSerialNumbersRouteImport
      parentRoute: typeof AppProductsRouteImport
    }
    '/app/products_/$productId': {
      id: '/app/products_/$productId'
      path: '/products/$productId'
      fullPath: '/app/products/$productId'
      preLoaderRoute: typeof AppProductsProductIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/tools/credit': {
      id: '/app/tools/credit'
      path: '/credit'
      fullPath: '/app/tools/credit'
      preLoaderRoute: typeof AppToolsCreditRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/ddns': {
      id: '/app/tools/ddns'
      path: '/ddns'
      fullPath: '/app/tools/ddns'
      preLoaderRoute: typeof AppToolsDdnsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/departments': {
      id: '/app/tools/departments'
      path: '/departments'
      fullPath: '/app/tools/departments'
      preLoaderRoute: typeof AppToolsDepartmentsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/emails': {
      id: '/app/tools/emails'
      path: '/emails'
      fullPath: '/app/tools/emails'
      preLoaderRoute: typeof AppToolsEmailsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/external-links': {
      id: '/app/tools/external-links'
      path: '/external-links'
      fullPath: '/app/tools/external-links'
      preLoaderRoute: typeof AppToolsExternalLinksRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/formations': {
      id: '/app/tools/formations'
      path: '/formations'
      fullPath: '/app/tools/formations'
      preLoaderRoute: typeof AppToolsFormationsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/global-turnover': {
      id: '/app/tools/global-turnover'
      path: '/global-turnover'
      fullPath: '/app/tools/global-turnover'
      preLoaderRoute: typeof AppToolsGlobalTurnoverRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/mails': {
      id: '/app/tools/mails'
      path: '/mails'
      fullPath: '/app/tools/mails'
      preLoaderRoute: typeof AppToolsMailsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/menu': {
      id: '/app/tools/menu'
      path: '/menu'
      fullPath: '/app/tools/menu'
      preLoaderRoute: typeof AppToolsMenuRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/news': {
      id: '/app/tools/news'
      path: '/news'
      fullPath: '/app/tools/news'
      preLoaderRoute: typeof AppToolsNewsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/predefined-messages': {
      id: '/app/tools/predefined-messages'
      path: '/predefined-messages'
      fullPath: '/app/tools/predefined-messages'
      preLoaderRoute: typeof AppToolsPredefinedMessagesRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/predefined-texts': {
      id: '/app/tools/predefined-texts'
      path: '/predefined-texts'
      fullPath: '/app/tools/predefined-texts'
      preLoaderRoute: typeof AppToolsPredefinedTextsRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/product-filters': {
      id: '/app/tools/product-filters'
      path: '/product-filters'
      fullPath: '/app/tools/product-filters'
      preLoaderRoute: typeof AppToolsProductFiltersRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/product-inventory': {
      id: '/app/tools/product-inventory'
      path: '/product-inventory'
      fullPath: '/app/tools/product-inventory'
      preLoaderRoute: typeof AppToolsProductInventoryRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/product-shelves': {
      id: '/app/tools/product-shelves'
      path: '/product-shelves'
      fullPath: '/app/tools/product-shelves'
      preLoaderRoute: typeof AppToolsProductShelvesRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/representatives-map': {
      id: '/app/tools/representatives-map'
      path: '/representatives-map'
      fullPath: '/app/tools/representatives-map'
      preLoaderRoute: typeof AppToolsRepresentativesMapRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/representatives-turnover': {
      id: '/app/tools/representatives-turnover'
      path: '/representatives-turnover'
      fullPath: '/app/tools/representatives-turnover'
      preLoaderRoute: typeof AppToolsRepresentativesTurnoverRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/scheduler': {
      id: '/app/tools/scheduler'
      path: '/scheduler'
      fullPath: '/app/tools/scheduler'
      preLoaderRoute: typeof AppToolsSchedulerRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/tools/vva': {
      id: '/app/tools/vva'
      path: '/vva'
      fullPath: '/app/tools/vva'
      preLoaderRoute: typeof AppToolsVvaRouteImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/auth/reset-password/$token': {
      id: '/auth/reset-password/$token'
      path: '/reset-password/$token'
      fullPath: '/auth/reset-password/$token'
      preLoaderRoute: typeof AuthResetPasswordTokenRouteLazyImport
      parentRoute: typeof AuthRouteImport
    }
    '/app/tools/': {
      id: '/app/tools/'
      path: '/'
      fullPath: '/app/tools/'
      preLoaderRoute: typeof AppToolsIndexImport
      parentRoute: typeof AppToolsRouteImport
    }
    '/app/businesses-rma_/business/$businessId': {
      id: '/app/businesses-rma_/business/$businessId'
      path: '/businesses-rma/business/$businessId'
      fullPath: '/app/businesses-rma/business/$businessId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId': {
      id: '/app/businesses-rma_/rma/$rmaId'
      path: '/businesses-rma/rma/$rmaId'
      fullPath: '/app/businesses-rma/rma/$rmaId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/dashboard/archive-personal-task/$taskId': {
      id: '/app/dashboard/archive-personal-task/$taskId'
      path: '/archive-personal-task/$taskId'
      fullPath: '/app/dashboard/archive-personal-task/$taskId'
      preLoaderRoute: typeof AppDashboardArchivePersonalTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/delete-collective-task/$taskId': {
      id: '/app/dashboard/delete-collective-task/$taskId'
      path: '/delete-collective-task/$taskId'
      fullPath: '/app/dashboard/delete-collective-task/$taskId'
      preLoaderRoute: typeof AppDashboardDeleteCollectiveTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/delete-progressive-info/$progressiveInfoId': {
      id: '/app/dashboard/delete-progressive-info/$progressiveInfoId'
      path: '/delete-progressive-info/$progressiveInfoId'
      fullPath: '/app/dashboard/delete-progressive-info/$progressiveInfoId'
      preLoaderRoute: typeof AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/link-personal-task/$taskId': {
      id: '/app/dashboard/link-personal-task/$taskId'
      path: '/link-personal-task/$taskId'
      fullPath: '/app/dashboard/link-personal-task/$taskId'
      preLoaderRoute: typeof AppDashboardLinkPersonalTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/other-personal-tasks/$profileId': {
      id: '/app/dashboard/other-personal-tasks/$profileId'
      path: '/other-personal-tasks/$profileId'
      fullPath: '/app/dashboard/other-personal-tasks/$profileId'
      preLoaderRoute: typeof AppDashboardOtherPersonalTasksProfileIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/personal-task-details/$taskId': {
      id: '/app/dashboard/personal-task-details/$taskId'
      path: '/personal-task-details/$taskId'
      fullPath: '/app/dashboard/personal-task-details/$taskId'
      preLoaderRoute: typeof AppDashboardPersonalTaskDetailsTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/scheduler-event-details/$eventId': {
      id: '/app/dashboard/scheduler-event-details/$eventId'
      path: '/scheduler-event-details/$eventId'
      fullPath: '/app/dashboard/scheduler-event-details/$eventId'
      preLoaderRoute: typeof AppDashboardSchedulerEventDetailsEventIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/take-collective-task/$taskId': {
      id: '/app/dashboard/take-collective-task/$taskId'
      path: '/take-collective-task/$taskId'
      fullPath: '/app/dashboard/take-collective-task/$taskId'
      preLoaderRoute: typeof AppDashboardTakeCollectiveTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/task-comments/$taskId': {
      id: '/app/dashboard/task-comments/$taskId'
      path: '/task-comments/$taskId'
      fullPath: '/app/dashboard/task-comments/$taskId'
      preLoaderRoute: typeof AppDashboardTaskCommentsTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/task-email/$taskId': {
      id: '/app/dashboard/task-email/$taskId'
      path: '/task-email/$taskId'
      fullPath: '/app/dashboard/task-email/$taskId'
      preLoaderRoute: typeof AppDashboardTaskEmailTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/transfer-task/$taskId': {
      id: '/app/dashboard/transfer-task/$taskId'
      path: '/transfer-task/$taskId'
      fullPath: '/app/dashboard/transfer-task/$taskId'
      preLoaderRoute: typeof AppDashboardTransferTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/unlink-personal-task/$taskId': {
      id: '/app/dashboard/unlink-personal-task/$taskId'
      path: '/unlink-personal-task/$taskId'
      fullPath: '/app/dashboard/unlink-personal-task/$taskId'
      preLoaderRoute: typeof AppDashboardUnlinkPersonalTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/update-personal-task-deadline/$taskId': {
      id: '/app/dashboard/update-personal-task-deadline/$taskId'
      path: '/update-personal-task-deadline/$taskId'
      fullPath: '/app/dashboard/update-personal-task-deadline/$taskId'
      preLoaderRoute: typeof AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/update-progressive-info/$progressiveInfoId': {
      id: '/app/dashboard/update-progressive-info/$progressiveInfoId'
      path: '/update-progressive-info/$progressiveInfoId'
      fullPath: '/app/dashboard/update-progressive-info/$progressiveInfoId'
      preLoaderRoute: typeof AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/dashboard/validate-personal-task/$taskId': {
      id: '/app/dashboard/validate-personal-task/$taskId'
      path: '/validate-personal-task/$taskId'
      fullPath: '/app/dashboard/validate-personal-task/$taskId'
      preLoaderRoute: typeof AppDashboardValidatePersonalTaskTaskIdRouteImport
      parentRoute: typeof AppDashboardRouteImport
    }
    '/app/enterprises/create-contact-business/$contactId': {
      id: '/app/enterprises/create-contact-business/$contactId'
      path: '/create-contact-business/$contactId'
      fullPath: '/app/enterprises/create-contact-business/$contactId'
      preLoaderRoute: typeof AppEnterprisesCreateContactBusinessContactIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/create-contact-travel-voucher/$contactId': {
      id: '/app/enterprises/create-contact-travel-voucher/$contactId'
      path: '/create-contact-travel-voucher/$contactId'
      fullPath: '/app/enterprises/create-contact-travel-voucher/$contactId'
      preLoaderRoute: typeof AppEnterprisesCreateContactTravelVoucherContactIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/create-contact/$enterpriseId': {
      id: '/app/enterprises/create-contact/$enterpriseId'
      path: '/create-contact/$enterpriseId'
      fullPath: '/app/enterprises/create-contact/$enterpriseId'
      preLoaderRoute: typeof AppEnterprisesCreateContactEnterpriseIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/create-enterprise-rma/$enterpriseId': {
      id: '/app/enterprises/create-enterprise-rma/$enterpriseId'
      path: '/create-enterprise-rma/$enterpriseId'
      fullPath: '/app/enterprises/create-enterprise-rma/$enterpriseId'
      preLoaderRoute: typeof AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/delete-contact/$contactId': {
      id: '/app/enterprises/delete-contact/$contactId'
      path: '/delete-contact/$contactId'
      fullPath: '/app/enterprises/delete-contact/$contactId'
      preLoaderRoute: typeof AppEnterprisesDeleteContactContactIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/send-email-to-contact/$contactId': {
      id: '/app/enterprises/send-email-to-contact/$contactId'
      path: '/send-email-to-contact/$contactId'
      fullPath: '/app/enterprises/send-email-to-contact/$contactId'
      preLoaderRoute: typeof AppEnterprisesSendEmailToContactContactIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/update-contact-password/$contactId': {
      id: '/app/enterprises/update-contact-password/$contactId'
      path: '/update-contact-password/$contactId'
      fullPath: '/app/enterprises/update-contact-password/$contactId'
      preLoaderRoute: typeof AppEnterprisesUpdateContactPasswordContactIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises/update-contact/$contactId': {
      id: '/app/enterprises/update-contact/$contactId'
      path: '/update-contact/$contactId'
      fullPath: '/app/enterprises/update-contact/$contactId'
      preLoaderRoute: typeof AppEnterprisesUpdateContactContactIdRouteImport
      parentRoute: typeof AppEnterprisesRouteImport
    }
    '/app/enterprises_/$enterpriseId/address-book': {
      id: '/app/enterprises_/$enterpriseId/address-book'
      path: '/address-book'
      fullPath: '/app/enterprises/$enterpriseId/address-book'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdAddressBookRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/create-contact': {
      id: '/app/enterprises_/$enterpriseId/create-contact'
      path: '/create-contact'
      fullPath: '/app/enterprises/$enterpriseId/create-contact'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdCreateContactRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/create-ged-directory': {
      id: '/app/enterprises_/$enterpriseId/create-ged-directory'
      path: '/create-ged-directory'
      fullPath: '/app/enterprises/$enterpriseId/create-ged-directory'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdCreateGedDirectoryRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/create-lifesheet-comment': {
      id: '/app/enterprises_/$enterpriseId/create-lifesheet-comment'
      path: '/create-lifesheet-comment'
      fullPath: '/app/enterprises/$enterpriseId/create-lifesheet-comment'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/delete': {
      id: '/app/enterprises_/$enterpriseId/delete'
      path: '/delete'
      fullPath: '/app/enterprises/$enterpriseId/delete'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdDeleteRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/email-history': {
      id: '/app/enterprises_/$enterpriseId/email-history'
      path: '/email-history'
      fullPath: '/app/enterprises/$enterpriseId/email-history'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/import-contacts': {
      id: '/app/enterprises_/$enterpriseId/import-contacts'
      path: '/import-contacts'
      fullPath: '/app/enterprises/$enterpriseId/import-contacts'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdImportContactsRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/import-ged-files': {
      id: '/app/enterprises_/$enterpriseId/import-ged-files'
      path: '/import-ged-files'
      fullPath: '/app/enterprises/$enterpriseId/import-ged-files'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdImportGedFilesRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/relate-business-rma': {
      id: '/app/enterprises_/$enterpriseId/relate-business-rma'
      path: '/relate-business-rma'
      fullPath: '/app/enterprises/$enterpriseId/relate-business-rma'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdRelateBusinessRmaRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/update': {
      id: '/app/enterprises_/$enterpriseId/update'
      path: '/update'
      fullPath: '/app/enterprises/$enterpriseId/update'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUpdateRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/update-accountability': {
      id: '/app/enterprises_/$enterpriseId/update-accountability'
      path: '/update-accountability'
      fullPath: '/app/enterprises/$enterpriseId/update-accountability'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUpdateAccountabilityRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/update-category': {
      id: '/app/enterprises_/$enterpriseId/update-category'
      path: '/update-category'
      fullPath: '/app/enterprises/$enterpriseId/update-category'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUpdateCategoryRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/update-representative': {
      id: '/app/enterprises_/$enterpriseId/update-representative'
      path: '/update-representative'
      fullPath: '/app/enterprises/$enterpriseId/update-representative'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUpdateRepresentativeRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/faq/archive/$faqId': {
      id: '/app/faq/archive/$faqId'
      path: '/archive/$faqId'
      fullPath: '/app/faq/archive/$faqId'
      preLoaderRoute: typeof AppFaqArchiveFaqIdRouteImport
      parentRoute: typeof AppFaqRouteImport
    }
    '/app/faq/delete/$faqId': {
      id: '/app/faq/delete/$faqId'
      path: '/delete/$faqId'
      fullPath: '/app/faq/delete/$faqId'
      preLoaderRoute: typeof AppFaqDeleteFaqIdRouteImport
      parentRoute: typeof AppFaqRouteImport
    }
    '/app/faq/ged/$faqId': {
      id: '/app/faq/ged/$faqId'
      path: '/ged/$faqId'
      fullPath: '/app/faq/ged/$faqId'
      preLoaderRoute: typeof AppFaqGedFaqIdRouteImport
      parentRoute: typeof AppFaqRouteImport
    }
    '/app/faq/send-by-email/$faqId': {
      id: '/app/faq/send-by-email/$faqId'
      path: '/send-by-email/$faqId'
      fullPath: '/app/faq/send-by-email/$faqId'
      preLoaderRoute: typeof AppFaqSendByEmailFaqIdRouteImport
      parentRoute: typeof AppFaqRouteImport
    }
    '/app/faq/update/$faqId': {
      id: '/app/faq/update/$faqId'
      path: '/update/$faqId'
      fullPath: '/app/faq/update/$faqId'
      preLoaderRoute: typeof AppFaqUpdateFaqIdRouteImport
      parentRoute: typeof AppFaqRouteImport
    }
    '/app/products/serial-numbers/create': {
      id: '/app/products/serial-numbers/create'
      path: '/create'
      fullPath: '/app/products/serial-numbers/create'
      preLoaderRoute: typeof AppProductsSerialNumbersCreateRouteImport
      parentRoute: typeof AppProductsSerialNumbersRouteImport
    }
    '/app/products_/$productId/informations': {
      id: '/app/products_/$productId/informations'
      path: '/informations'
      fullPath: '/app/products/$productId/informations'
      preLoaderRoute: typeof AppProductsProductIdInformationsRouteImport
      parentRoute: typeof AppProductsProductIdRouteImport
    }
    '/app/products_/$productId/manage': {
      id: '/app/products_/$productId/manage'
      path: '/manage'
      fullPath: '/app/products/$productId/manage'
      preLoaderRoute: typeof AppProductsProductIdManageRouteImport
      parentRoute: typeof AppProductsProductIdRouteImport
    }
    '/app/tools/credit/details': {
      id: '/app/tools/credit/details'
      path: '/details'
      fullPath: '/app/tools/credit/details'
      preLoaderRoute: typeof AppToolsCreditDetailsRouteImport
      parentRoute: typeof AppToolsCreditRouteImport
    }
    '/app/tools/credit/show': {
      id: '/app/tools/credit/show'
      path: '/show'
      fullPath: '/app/tools/credit/show'
      preLoaderRoute: typeof AppToolsCreditShowRouteImport
      parentRoute: typeof AppToolsCreditRouteImport
    }
    '/app/tools/ddns/create': {
      id: '/app/tools/ddns/create'
      path: '/create'
      fullPath: '/app/tools/ddns/create'
      preLoaderRoute: typeof AppToolsDdnsCreateRouteImport
      parentRoute: typeof AppToolsDdnsRouteImport
    }
    '/app/tools/departments/create': {
      id: '/app/tools/departments/create'
      path: '/create'
      fullPath: '/app/tools/departments/create'
      preLoaderRoute: typeof AppToolsDepartmentsCreateRouteImport
      parentRoute: typeof AppToolsDepartmentsRouteImport
    }
    '/app/tools/emails/$emailId': {
      id: '/app/tools/emails/$emailId'
      path: '/$emailId'
      fullPath: '/app/tools/emails/$emailId'
      preLoaderRoute: typeof AppToolsEmailsEmailIdRouteImport
      parentRoute: typeof AppToolsEmailsRouteImport
    }
    '/app/tools/external-links/create': {
      id: '/app/tools/external-links/create'
      path: '/create'
      fullPath: '/app/tools/external-links/create'
      preLoaderRoute: typeof AppToolsExternalLinksCreateRouteImport
      parentRoute: typeof AppToolsExternalLinksRouteImport
    }
    '/app/tools/formations/create': {
      id: '/app/tools/formations/create'
      path: '/create'
      fullPath: '/app/tools/formations/create'
      preLoaderRoute: typeof AppToolsFormationsCreateRouteImport
      parentRoute: typeof AppToolsFormationsRouteImport
    }
    '/app/tools/mails/create': {
      id: '/app/tools/mails/create'
      path: '/create'
      fullPath: '/app/tools/mails/create'
      preLoaderRoute: typeof AppToolsMailsCreateRouteImport
      parentRoute: typeof AppToolsMailsRouteImport
    }
    '/app/tools/menu/create-enterprise': {
      id: '/app/tools/menu/create-enterprise'
      path: '/create-enterprise'
      fullPath: '/app/tools/menu/create-enterprise'
      preLoaderRoute: typeof AppToolsMenuCreateEnterpriseRouteImport
      parentRoute: typeof AppToolsMenuRouteImport
    }
    '/app/tools/menu/create-product': {
      id: '/app/tools/menu/create-product'
      path: '/create-product'
      fullPath: '/app/tools/menu/create-product'
      preLoaderRoute: typeof AppToolsMenuCreateProductRouteImport
      parentRoute: typeof AppToolsMenuRouteImport
    }
    '/app/tools/news/create': {
      id: '/app/tools/news/create'
      path: '/create'
      fullPath: '/app/tools/news/create'
      preLoaderRoute: typeof AppToolsNewsCreateRouteImport
      parentRoute: typeof AppToolsNewsRouteImport
    }
    '/app/tools/predefined-messages/create': {
      id: '/app/tools/predefined-messages/create'
      path: '/create'
      fullPath: '/app/tools/predefined-messages/create'
      preLoaderRoute: typeof AppToolsPredefinedMessagesCreateRouteImport
      parentRoute: typeof AppToolsPredefinedMessagesRouteImport
    }
    '/app/tools/predefined-texts/create': {
      id: '/app/tools/predefined-texts/create'
      path: '/create'
      fullPath: '/app/tools/predefined-texts/create'
      preLoaderRoute: typeof AppToolsPredefinedTextsCreateRouteImport
      parentRoute: typeof AppToolsPredefinedTextsRouteImport
    }
    '/app/tools/product-filters/create': {
      id: '/app/tools/product-filters/create'
      path: '/create'
      fullPath: '/app/tools/product-filters/create'
      preLoaderRoute: typeof AppToolsProductFiltersCreateRouteImport
      parentRoute: typeof AppToolsProductFiltersRouteImport
    }
    '/app/tools/product-inventory/validate-quantities': {
      id: '/app/tools/product-inventory/validate-quantities'
      path: '/validate-quantities'
      fullPath: '/app/tools/product-inventory/validate-quantities'
      preLoaderRoute: typeof AppToolsProductInventoryValidateQuantitiesRouteImport
      parentRoute: typeof AppToolsProductInventoryRouteImport
    }
    '/app/tools/product-shelves/create': {
      id: '/app/tools/product-shelves/create'
      path: '/create'
      fullPath: '/app/tools/product-shelves/create'
      preLoaderRoute: typeof AppToolsProductShelvesCreateRouteImport
      parentRoute: typeof AppToolsProductShelvesRouteImport
    }
    '/app/tools/scheduler/create': {
      id: '/app/tools/scheduler/create'
      path: '/create'
      fullPath: '/app/tools/scheduler/create'
      preLoaderRoute: typeof AppToolsSchedulerCreateRouteImport
      parentRoute: typeof AppToolsSchedulerRouteImport
    }
    '/app/tools/vva/create': {
      id: '/app/tools/vva/create'
      path: '/create'
      fullPath: '/app/tools/vva/create'
      preLoaderRoute: typeof AppToolsVvaCreateRouteImport
      parentRoute: typeof AppToolsVvaRouteImport
    }
    '/app/products_/$productId/': {
      id: '/app/products_/$productId/'
      path: '/'
      fullPath: '/app/products/$productId/'
      preLoaderRoute: typeof AppProductsProductIdIndexImport
      parentRoute: typeof AppProductsProductIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/arc': {
      id: '/app/businesses-rma_/business/$businessId/arc'
      path: '/arc'
      fullPath: '/app/businesses-rma/business/$businessId/arc'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdArcRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bill': {
      id: '/app/businesses-rma_/business/$businessId/bill'
      path: '/bill'
      fullPath: '/app/businesses-rma/business/$businessId/bill'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBillRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bl': {
      id: '/app/businesses-rma_/business/$businessId/bl'
      path: '/bl'
      fullPath: '/app/businesses-rma/business/$businessId/bl'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBlRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp': {
      id: '/app/businesses-rma_/business/$businessId/bp'
      path: '/bp'
      fullPath: '/app/businesses-rma/business/$businessId/bp'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard': {
      id: '/app/businesses-rma_/business/$businessId/dashboard'
      path: '/dashboard'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation': {
      id: '/app/businesses-rma_/business/$businessId/quotation'
      path: '/quotation'
      fullPath: '/app/businesses-rma/business/$businessId/quotation'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/study': {
      id: '/app/businesses-rma_/business/$businessId_/study'
      path: '/businesses-rma/business/$businessId/study'
      fullPath: '/app/businesses-rma/business/$businessId/study'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery'
      path: '/delivery'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/reception': {
      id: '/app/businesses-rma_/rma/$rmaId/reception'
      path: '/reception'
      fullPath: '/app/businesses-rma/rma/$rmaId/reception'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdReceptionRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support': {
      id: '/app/businesses-rma_/rma/$rmaId/support'
      path: '/support'
      fullPath: '/app/businesses-rma/rma/$rmaId/support'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdRouteImport
    }
    '/app/dashboard/task-email/$taskId/reply': {
      id: '/app/dashboard/task-email/$taskId/reply'
      path: '/reply'
      fullPath: '/app/dashboard/task-email/$taskId/reply'
      preLoaderRoute: typeof AppDashboardTaskEmailTaskIdReplyRouteImport
      parentRoute: typeof AppDashboardTaskEmailTaskIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/address-book/create': {
      id: '/app/enterprises_/$enterpriseId/address-book/create'
      path: '/create'
      fullPath: '/app/enterprises/$enterpriseId/address-book/create'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdAddressBookCreateRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdAddressBookRouteImport
    }
    '/app/enterprises_/$enterpriseId/address-book/import': {
      id: '/app/enterprises_/$enterpriseId/address-book/import'
      path: '/import'
      fullPath: '/app/enterprises/$enterpriseId/address-book/import'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdAddressBookImportRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdAddressBookRouteImport
    }
    '/app/enterprises_/$enterpriseId/create-contact-business/$contactId': {
      id: '/app/enterprises_/$enterpriseId/create-contact-business/$contactId'
      path: '/create-contact-business/$contactId'
      fullPath: '/app/enterprises/$enterpriseId/create-contact-business/$contactId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/delete-contact/$contactId': {
      id: '/app/enterprises_/$enterpriseId/delete-contact/$contactId'
      path: '/delete-contact/$contactId'
      fullPath: '/app/enterprises/$enterpriseId/delete-contact/$contactId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdDeleteContactContactIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/delete-ged-object/$objectRelativePath': {
      id: '/app/enterprises_/$enterpriseId/delete-ged-object/$objectRelativePath'
      path: '/delete-ged-object/$objectRelativePath'
      fullPath: '/app/enterprises/$enterpriseId/delete-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/lifesheet-email/$lifesheetId': {
      id: '/app/enterprises_/$enterpriseId/lifesheet-email/$lifesheetId'
      path: '/lifesheet-email/$lifesheetId'
      fullPath: '/app/enterprises/$enterpriseId/lifesheet-email/$lifesheetId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/rename-ged-object/$objectRelativePath': {
      id: '/app/enterprises_/$enterpriseId/rename-ged-object/$objectRelativePath'
      path: '/rename-ged-object/$objectRelativePath'
      fullPath: '/app/enterprises/$enterpriseId/rename-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/send-email-to-contact/$contactId': {
      id: '/app/enterprises_/$enterpriseId/send-email-to-contact/$contactId'
      path: '/send-email-to-contact/$contactId'
      fullPath: '/app/enterprises/$enterpriseId/send-email-to-contact/$contactId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/task-email/$taskId': {
      id: '/app/enterprises_/$enterpriseId/task-email/$taskId'
      path: '/task-email/$taskId'
      fullPath: '/app/enterprises/$enterpriseId/task-email/$taskId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/unlink-task/$taskId': {
      id: '/app/enterprises_/$enterpriseId/unlink-task/$taskId'
      path: '/unlink-task/$taskId'
      fullPath: '/app/enterprises/$enterpriseId/unlink-task/$taskId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/unrelate-business-rma/$businessRmaId': {
      id: '/app/enterprises_/$enterpriseId/unrelate-business-rma/$businessRmaId'
      path: '/unrelate-business-rma/$businessRmaId'
      fullPath: '/app/enterprises/$enterpriseId/unrelate-business-rma/$businessRmaId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/update-contact-password/$contactId': {
      id: '/app/enterprises_/$enterpriseId/update-contact-password/$contactId'
      path: '/update-contact-password/$contactId'
      fullPath: '/app/enterprises/$enterpriseId/update-contact-password/$contactId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/enterprises_/$enterpriseId/update-contact/$contactId': {
      id: '/app/enterprises_/$enterpriseId/update-contact/$contactId'
      path: '/update-contact/$contactId'
      fullPath: '/app/enterprises/$enterpriseId/update-contact/$contactId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdUpdateContactContactIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdRouteImport
    }
    '/app/faq/ged/$faqId/create-directory': {
      id: '/app/faq/ged/$faqId/create-directory'
      path: '/create-directory'
      fullPath: '/app/faq/ged/$faqId/create-directory'
      preLoaderRoute: typeof AppFaqGedFaqIdCreateDirectoryRouteImport
      parentRoute: typeof AppFaqGedFaqIdRouteImport
    }
    '/app/faq/ged/$faqId/import-files': {
      id: '/app/faq/ged/$faqId/import-files'
      path: '/import-files'
      fullPath: '/app/faq/ged/$faqId/import-files'
      preLoaderRoute: typeof AppFaqGedFaqIdImportFilesRouteImport
      parentRoute: typeof AppFaqGedFaqIdRouteImport
    }
    '/app/products/serial-numbers/create-rma/$serialNumberId': {
      id: '/app/products/serial-numbers/create-rma/$serialNumberId'
      path: '/create-rma/$serialNumberId'
      fullPath: '/app/products/serial-numbers/create-rma/$serialNumberId'
      preLoaderRoute: typeof AppProductsSerialNumbersCreateRmaSerialNumberIdRouteImport
      parentRoute: typeof AppProductsSerialNumbersRouteImport
    }
    '/app/products/serial-numbers/delete/$serialNumberId': {
      id: '/app/products/serial-numbers/delete/$serialNumberId'
      path: '/delete/$serialNumberId'
      fullPath: '/app/products/serial-numbers/delete/$serialNumberId'
      preLoaderRoute: typeof AppProductsSerialNumbersDeleteSerialNumberIdRouteImport
      parentRoute: typeof AppProductsSerialNumbersRouteImport
    }
    '/app/products/serial-numbers/remove-from-business/$serialNumberId': {
      id: '/app/products/serial-numbers/remove-from-business/$serialNumberId'
      path: '/remove-from-business/$serialNumberId'
      fullPath: '/app/products/serial-numbers/remove-from-business/$serialNumberId'
      preLoaderRoute: typeof AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteImport
      parentRoute: typeof AppProductsSerialNumbersRouteImport
    }
    '/app/products/serial-numbers/update/$serialNumberId': {
      id: '/app/products/serial-numbers/update/$serialNumberId'
      path: '/update/$serialNumberId'
      fullPath: '/app/products/serial-numbers/update/$serialNumberId'
      preLoaderRoute: typeof AppProductsSerialNumbersUpdateSerialNumberIdRouteImport
      parentRoute: typeof AppProductsSerialNumbersRouteImport
    }
    '/app/products_/$productId/informations/create-ged-directory': {
      id: '/app/products_/$productId/informations/create-ged-directory'
      path: '/create-ged-directory'
      fullPath: '/app/products/$productId/informations/create-ged-directory'
      preLoaderRoute: typeof AppProductsProductIdInformationsCreateGedDirectoryRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/informations/create-lifesheet-comment': {
      id: '/app/products_/$productId/informations/create-lifesheet-comment'
      path: '/create-lifesheet-comment'
      fullPath: '/app/products/$productId/informations/create-lifesheet-comment'
      preLoaderRoute: typeof AppProductsProductIdInformationsCreateLifesheetCommentRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/informations/delete-ged-object': {
      id: '/app/products_/$productId/informations/delete-ged-object'
      path: '/delete-ged-object'
      fullPath: '/app/products/$productId/informations/delete-ged-object'
      preLoaderRoute: typeof AppProductsProductIdInformationsDeleteGedObjectRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/informations/import-ged-files': {
      id: '/app/products_/$productId/informations/import-ged-files'
      path: '/import-ged-files'
      fullPath: '/app/products/$productId/informations/import-ged-files'
      preLoaderRoute: typeof AppProductsProductIdInformationsImportGedFilesRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/informations/rename-ged-object': {
      id: '/app/products_/$productId/informations/rename-ged-object'
      path: '/rename-ged-object'
      fullPath: '/app/products/$productId/informations/rename-ged-object'
      preLoaderRoute: typeof AppProductsProductIdInformationsRenameGedObjectRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/manage/add-associated-product': {
      id: '/app/products_/$productId/manage/add-associated-product'
      path: '/add-associated-product'
      fullPath: '/app/products/$productId/manage/add-associated-product'
      preLoaderRoute: typeof AppProductsProductIdManageAddAssociatedProductRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/add-nomenclature-detail': {
      id: '/app/products_/$productId/manage/add-nomenclature-detail'
      path: '/add-nomenclature-detail'
      fullPath: '/app/products/$productId/manage/add-nomenclature-detail'
      preLoaderRoute: typeof AppProductsProductIdManageAddNomenclatureDetailRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/add-specification': {
      id: '/app/products_/$productId/manage/add-specification'
      path: '/add-specification'
      fullPath: '/app/products/$productId/manage/add-specification'
      preLoaderRoute: typeof AppProductsProductIdManageAddSpecificationRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/create-stock': {
      id: '/app/products_/$productId/manage/create-stock'
      path: '/create-stock'
      fullPath: '/app/products/$productId/manage/create-stock'
      preLoaderRoute: typeof AppProductsProductIdManageCreateStockRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/create-version': {
      id: '/app/products_/$productId/manage/create-version'
      path: '/create-version'
      fullPath: '/app/products/$productId/manage/create-version'
      preLoaderRoute: typeof AppProductsProductIdManageCreateVersionRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/tools/ddns/delete/$ddnsId': {
      id: '/app/tools/ddns/delete/$ddnsId'
      path: '/delete/$ddnsId'
      fullPath: '/app/tools/ddns/delete/$ddnsId'
      preLoaderRoute: typeof AppToolsDdnsDeleteDdnsIdRouteImport
      parentRoute: typeof AppToolsDdnsRouteImport
    }
    '/app/tools/departments/delete/$departmentId': {
      id: '/app/tools/departments/delete/$departmentId'
      path: '/delete/$departmentId'
      fullPath: '/app/tools/departments/delete/$departmentId'
      preLoaderRoute: typeof AppToolsDepartmentsDeleteDepartmentIdRouteImport
      parentRoute: typeof AppToolsDepartmentsRouteImport
    }
    '/app/tools/departments/update/$departmentId': {
      id: '/app/tools/departments/update/$departmentId'
      path: '/update/$departmentId'
      fullPath: '/app/tools/departments/update/$departmentId'
      preLoaderRoute: typeof AppToolsDepartmentsUpdateDepartmentIdRouteImport
      parentRoute: typeof AppToolsDepartmentsRouteImport
    }
    '/app/tools/emails/$emailId/reply': {
      id: '/app/tools/emails/$emailId/reply'
      path: '/reply'
      fullPath: '/app/tools/emails/$emailId/reply'
      preLoaderRoute: typeof AppToolsEmailsEmailIdReplyRouteImport
      parentRoute: typeof AppToolsEmailsEmailIdRouteImport
    }
    '/app/tools/external-links/archive/$externalLinkId': {
      id: '/app/tools/external-links/archive/$externalLinkId'
      path: '/archive/$externalLinkId'
      fullPath: '/app/tools/external-links/archive/$externalLinkId'
      preLoaderRoute: typeof AppToolsExternalLinksArchiveExternalLinkIdRouteImport
      parentRoute: typeof AppToolsExternalLinksRouteImport
    }
    '/app/tools/external-links/delete/$externalLinkId': {
      id: '/app/tools/external-links/delete/$externalLinkId'
      path: '/delete/$externalLinkId'
      fullPath: '/app/tools/external-links/delete/$externalLinkId'
      preLoaderRoute: typeof AppToolsExternalLinksDeleteExternalLinkIdRouteImport
      parentRoute: typeof AppToolsExternalLinksRouteImport
    }
    '/app/tools/external-links/update/$externalLinkId': {
      id: '/app/tools/external-links/update/$externalLinkId'
      path: '/update/$externalLinkId'
      fullPath: '/app/tools/external-links/update/$externalLinkId'
      preLoaderRoute: typeof AppToolsExternalLinksUpdateExternalLinkIdRouteImport
      parentRoute: typeof AppToolsExternalLinksRouteImport
    }
    '/app/tools/formations/create/add-detail': {
      id: '/app/tools/formations/create/add-detail'
      path: '/add-detail'
      fullPath: '/app/tools/formations/create/add-detail'
      preLoaderRoute: typeof AppToolsFormationsCreateAddDetailRouteImport
      parentRoute: typeof AppToolsFormationsCreateRouteImport
    }
    '/app/tools/formations/create/details': {
      id: '/app/tools/formations/create/details'
      path: '/details'
      fullPath: '/app/tools/formations/create/details'
      preLoaderRoute: typeof AppToolsFormationsCreateDetailsRouteImport
      parentRoute: typeof AppToolsFormationsCreateRouteImport
    }
    '/app/tools/formations/delete/$formationId': {
      id: '/app/tools/formations/delete/$formationId'
      path: '/delete/$formationId'
      fullPath: '/app/tools/formations/delete/$formationId'
      preLoaderRoute: typeof AppToolsFormationsDeleteFormationIdRouteImport
      parentRoute: typeof AppToolsFormationsRouteImport
    }
    '/app/tools/formations/subscribers/$formationDetailId': {
      id: '/app/tools/formations/subscribers/$formationDetailId'
      path: '/subscribers/$formationDetailId'
      fullPath: '/app/tools/formations/subscribers/$formationDetailId'
      preLoaderRoute: typeof AppToolsFormationsSubscribersFormationDetailIdRouteImport
      parentRoute: typeof AppToolsFormationsRouteImport
    }
    '/app/tools/formations/update/$formationId': {
      id: '/app/tools/formations/update/$formationId'
      path: '/update/$formationId'
      fullPath: '/app/tools/formations/update/$formationId'
      preLoaderRoute: typeof AppToolsFormationsUpdateFormationIdRouteImport
      parentRoute: typeof AppToolsFormationsRouteImport
    }
    '/app/tools/mails/delete/$mailId': {
      id: '/app/tools/mails/delete/$mailId'
      path: '/delete/$mailId'
      fullPath: '/app/tools/mails/delete/$mailId'
      preLoaderRoute: typeof AppToolsMailsDeleteMailIdRouteImport
      parentRoute: typeof AppToolsMailsRouteImport
    }
    '/app/tools/mails/show/$mailId': {
      id: '/app/tools/mails/show/$mailId'
      path: '/show/$mailId'
      fullPath: '/app/tools/mails/show/$mailId'
      preLoaderRoute: typeof AppToolsMailsShowMailIdRouteImport
      parentRoute: typeof AppToolsMailsRouteImport
    }
    '/app/tools/mails/update/$mailId': {
      id: '/app/tools/mails/update/$mailId'
      path: '/update/$mailId'
      fullPath: '/app/tools/mails/update/$mailId'
      preLoaderRoute: typeof AppToolsMailsUpdateMailIdRouteImport
      parentRoute: typeof AppToolsMailsRouteImport
    }
    '/app/tools/news/delete/$newsId': {
      id: '/app/tools/news/delete/$newsId'
      path: '/delete/$newsId'
      fullPath: '/app/tools/news/delete/$newsId'
      preLoaderRoute: typeof AppToolsNewsDeleteNewsIdRouteImport
      parentRoute: typeof AppToolsNewsRouteImport
    }
    '/app/tools/news/update/$newsId': {
      id: '/app/tools/news/update/$newsId'
      path: '/update/$newsId'
      fullPath: '/app/tools/news/update/$newsId'
      preLoaderRoute: typeof AppToolsNewsUpdateNewsIdRouteImport
      parentRoute: typeof AppToolsNewsRouteImport
    }
    '/app/tools/predefined-messages/delete/$predefinedMessageId': {
      id: '/app/tools/predefined-messages/delete/$predefinedMessageId'
      path: '/delete/$predefinedMessageId'
      fullPath: '/app/tools/predefined-messages/delete/$predefinedMessageId'
      preLoaderRoute: typeof AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteImport
      parentRoute: typeof AppToolsPredefinedMessagesRouteImport
    }
    '/app/tools/predefined-messages/update/$predefinedMessageId': {
      id: '/app/tools/predefined-messages/update/$predefinedMessageId'
      path: '/update/$predefinedMessageId'
      fullPath: '/app/tools/predefined-messages/update/$predefinedMessageId'
      preLoaderRoute: typeof AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteImport
      parentRoute: typeof AppToolsPredefinedMessagesRouteImport
    }
    '/app/tools/predefined-texts/delete/$predefinedTextId': {
      id: '/app/tools/predefined-texts/delete/$predefinedTextId'
      path: '/delete/$predefinedTextId'
      fullPath: '/app/tools/predefined-texts/delete/$predefinedTextId'
      preLoaderRoute: typeof AppToolsPredefinedTextsDeletePredefinedTextIdRouteImport
      parentRoute: typeof AppToolsPredefinedTextsRouteImport
    }
    '/app/tools/predefined-texts/update/$predefinedTextId': {
      id: '/app/tools/predefined-texts/update/$predefinedTextId'
      path: '/update/$predefinedTextId'
      fullPath: '/app/tools/predefined-texts/update/$predefinedTextId'
      preLoaderRoute: typeof AppToolsPredefinedTextsUpdatePredefinedTextIdRouteImport
      parentRoute: typeof AppToolsPredefinedTextsRouteImport
    }
    '/app/tools/product-filters/delete/$productFilterId': {
      id: '/app/tools/product-filters/delete/$productFilterId'
      path: '/delete/$productFilterId'
      fullPath: '/app/tools/product-filters/delete/$productFilterId'
      preLoaderRoute: typeof AppToolsProductFiltersDeleteProductFilterIdRouteImport
      parentRoute: typeof AppToolsProductFiltersRouteImport
    }
    '/app/tools/product-filters/update/$productFilterId': {
      id: '/app/tools/product-filters/update/$productFilterId'
      path: '/update/$productFilterId'
      fullPath: '/app/tools/product-filters/update/$productFilterId'
      preLoaderRoute: typeof AppToolsProductFiltersUpdateProductFilterIdRouteImport
      parentRoute: typeof AppToolsProductFiltersRouteImport
    }
    '/app/tools/product-inventory/update/$stockId': {
      id: '/app/tools/product-inventory/update/$stockId'
      path: '/update/$stockId'
      fullPath: '/app/tools/product-inventory/update/$stockId'
      preLoaderRoute: typeof AppToolsProductInventoryUpdateStockIdRouteImport
      parentRoute: typeof AppToolsProductInventoryRouteImport
    }
    '/app/tools/product-shelves/delete/$productShelfId': {
      id: '/app/tools/product-shelves/delete/$productShelfId'
      path: '/delete/$productShelfId'
      fullPath: '/app/tools/product-shelves/delete/$productShelfId'
      preLoaderRoute: typeof AppToolsProductShelvesDeleteProductShelfIdRouteImport
      parentRoute: typeof AppToolsProductShelvesRouteImport
    }
    '/app/tools/scheduler/details/$rdvId': {
      id: '/app/tools/scheduler/details/$rdvId'
      path: '/details/$rdvId'
      fullPath: '/app/tools/scheduler/details/$rdvId'
      preLoaderRoute: typeof AppToolsSchedulerDetailsRdvIdRouteImport
      parentRoute: typeof AppToolsSchedulerRouteImport
    }
    '/app/tools/vva/delete/$vvaId': {
      id: '/app/tools/vva/delete/$vvaId'
      path: '/delete/$vvaId'
      fullPath: '/app/tools/vva/delete/$vvaId'
      preLoaderRoute: typeof AppToolsVvaDeleteVvaIdRouteImport
      parentRoute: typeof AppToolsVvaRouteImport
    }
    '/app/businesses-rma_/business/$businessId/': {
      id: '/app/businesses-rma_/business/$businessId/'
      path: '/'
      fullPath: '/app/businesses-rma/business/$businessId/'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdIndexImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/': {
      id: '/app/businesses-rma_/rma/$rmaId/'
      path: '/'
      fullPath: '/app/businesses-rma/rma/$rmaId/'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdIndexImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/arc/pdf': {
      id: '/app/businesses-rma_/business/$businessId/arc/pdf'
      path: '/pdf'
      fullPath: '/app/businesses-rma/business/$businessId/arc/pdf'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdArcPdfRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdArcRouteImport
    }
    '/app/businesses-rma_/business/$businessId/arc/update-shipping-price': {
      id: '/app/businesses-rma_/business/$businessId/arc/update-shipping-price'
      path: '/update-shipping-price'
      fullPath: '/app/businesses-rma/business/$businessId/arc/update-shipping-price'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdArcRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bill/credits': {
      id: '/app/businesses-rma_/business/$businessId/bill/credits'
      path: '/credits'
      fullPath: '/app/businesses-rma/business/$businessId/bill/credits'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBillCreditsRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBillRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bill/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId/bill/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/bill/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBillRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bl/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId/bl/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/bl/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBlRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/travel-voucher': {
      id: '/app/businesses-rma_/business/$businessId/bp/travel-voucher'
      path: '/travel-voucher'
      fullPath: '/app/businesses-rma/business/$businessId/bp/travel-voucher'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/address-book': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/address-book'
      path: '/address-book'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/address-book'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/create-ged-directory': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/create-ged-directory'
      path: '/create-ged-directory'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/create-ged-directory'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/create-lifesheet': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/create-lifesheet'
      path: '/create-lifesheet'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/create-lifesheet'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/create-link': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/create-link'
      path: '/create-link'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/create-link'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/delete': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/delete'
      path: '/delete'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/delete'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/email-history': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/email-history'
      path: '/email-history'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/email-history'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/import-ged-files': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/import-ged-files'
      path: '/import-ged-files'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/import-ged-files'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/send-email': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/send-email'
      path: '/send-email'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/send-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/update-billing-address': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/update-billing-address'
      path: '/update-billing-address'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/update-billing-address'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/update-representative': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/update-representative'
      path: '/update-representative'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/update-representative'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/update-responsible': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/update-responsible'
      path: '/update-responsible'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/update-responsible'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/commercial-notice': {
      id: '/app/businesses-rma_/business/$businessId/quotation/commercial-notice'
      path: '/commercial-notice'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/commercial-notice'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/pdf': {
      id: '/app/businesses-rma_/business/$businessId/quotation/pdf'
      path: '/pdf'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/pdf'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/update-shipping-price': {
      id: '/app/businesses-rma_/business/$businessId/quotation/update-shipping-price'
      path: '/update-shipping-price'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/update-shipping-price'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId'
      path: '/businesses-rma/business/$businessId/assistance/$assistanceId'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/study/automatic': {
      id: '/app/businesses-rma_/business/$businessId_/study/automatic'
      path: '/automatic'
      fullPath: '/app/businesses-rma/business/$businessId/study/automatic'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/study/expert': {
      id: '/app/businesses-rma_/business/$businessId_/study/expert'
      path: '/expert'
      fullPath: '/app/businesses-rma/business/$businessId/study/expert'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyExpertRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery/create-detail': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery/create-detail'
      path: '/create-detail'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery/create-detail'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery/pdf': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery/pdf'
      path: '/pdf'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery/pdf'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryPdfRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery/travel-voucher': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery/travel-voucher'
      path: '/travel-voucher'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery/travel-voucher'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/reception/create-detail': {
      id: '/app/businesses-rma_/rma/$rmaId/reception/create-detail'
      path: '/create-detail'
      fullPath: '/app/businesses-rma/rma/$rmaId/reception/create-detail'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdReceptionRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/reception/pdf': {
      id: '/app/businesses-rma_/rma/$rmaId/reception/pdf'
      path: '/pdf'
      fullPath: '/app/businesses-rma/rma/$rmaId/reception/pdf'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdReceptionPdfRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdReceptionRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/create-detail': {
      id: '/app/businesses-rma_/rma/$rmaId/support/create-detail'
      path: '/create-detail'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/create-detail'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/create-ged-directory': {
      id: '/app/businesses-rma_/rma/$rmaId/support/create-ged-directory'
      path: '/create-ged-directory'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/create-ged-directory'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/create-lifesheet': {
      id: '/app/businesses-rma_/rma/$rmaId/support/create-lifesheet'
      path: '/create-lifesheet'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/create-lifesheet'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/create-link': {
      id: '/app/businesses-rma_/rma/$rmaId/support/create-link'
      path: '/create-link'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/create-link'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/import-ged-files': {
      id: '/app/businesses-rma_/rma/$rmaId/support/import-ged-files'
      path: '/import-ged-files'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/import-ged-files'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/pdf': {
      id: '/app/businesses-rma_/rma/$rmaId/support/pdf'
      path: '/pdf'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/pdf'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportPdfRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/enterprises_/$enterpriseId/address-book/delete/$addressId': {
      id: '/app/enterprises_/$enterpriseId/address-book/delete/$addressId'
      path: '/delete/$addressId'
      fullPath: '/app/enterprises/$enterpriseId/address-book/delete/$addressId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdAddressBookRouteImport
    }
    '/app/enterprises_/$enterpriseId/address-book/update/$addressId': {
      id: '/app/enterprises_/$enterpriseId/address-book/update/$addressId'
      path: '/update/$addressId'
      fullPath: '/app/enterprises/$enterpriseId/address-book/update/$addressId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdAddressBookRouteImport
    }
    '/app/enterprises_/$enterpriseId/email-history/email/$emailId': {
      id: '/app/enterprises_/$enterpriseId/email-history/email/$emailId'
      path: '/email/$emailId'
      fullPath: '/app/enterprises/$enterpriseId/email-history/email/$emailId'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryRouteImport
    }
    '/app/enterprises_/$enterpriseId/task-email/$taskId/reply': {
      id: '/app/enterprises_/$enterpriseId/task-email/$taskId/reply'
      path: '/reply'
      fullPath: '/app/enterprises/$enterpriseId/task-email/$taskId/reply'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteImport
    }
    '/app/faq/ged/$faqId/delete/$itemRelativePath': {
      id: '/app/faq/ged/$faqId/delete/$itemRelativePath'
      path: '/delete/$itemRelativePath'
      fullPath: '/app/faq/ged/$faqId/delete/$itemRelativePath'
      preLoaderRoute: typeof AppFaqGedFaqIdDeleteItemRelativePathRouteImport
      parentRoute: typeof AppFaqGedFaqIdRouteImport
    }
    '/app/faq/ged/$faqId/rename/$itemRelativePath': {
      id: '/app/faq/ged/$faqId/rename/$itemRelativePath'
      path: '/rename/$itemRelativePath'
      fullPath: '/app/faq/ged/$faqId/rename/$itemRelativePath'
      preLoaderRoute: typeof AppFaqGedFaqIdRenameItemRelativePathRouteImport
      parentRoute: typeof AppFaqGedFaqIdRouteImport
    }
    '/app/products_/$productId/informations/lifesheet-email/$lifesheetId': {
      id: '/app/products_/$productId/informations/lifesheet-email/$lifesheetId'
      path: '/lifesheet-email/$lifesheetId'
      fullPath: '/app/products/$productId/informations/lifesheet-email/$lifesheetId'
      preLoaderRoute: typeof AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/informations/task-email/$taskId': {
      id: '/app/products_/$productId/informations/task-email/$taskId'
      path: '/task-email/$taskId'
      fullPath: '/app/products/$productId/informations/task-email/$taskId'
      preLoaderRoute: typeof AppProductsProductIdInformationsTaskEmailTaskIdRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/informations/unlink-task/$taskId': {
      id: '/app/products_/$productId/informations/unlink-task/$taskId'
      path: '/unlink-task/$taskId'
      fullPath: '/app/products/$productId/informations/unlink-task/$taskId'
      preLoaderRoute: typeof AppProductsProductIdInformationsUnlinkTaskTaskIdRouteImport
      parentRoute: typeof AppProductsProductIdInformationsRouteImport
    }
    '/app/products_/$productId/manage/add-specification/$filterId': {
      id: '/app/products_/$productId/manage/add-specification/$filterId'
      path: '/$filterId'
      fullPath: '/app/products/$productId/manage/add-specification/$filterId'
      preLoaderRoute: typeof AppProductsProductIdManageAddSpecificationFilterIdRouteImport
      parentRoute: typeof AppProductsProductIdManageAddSpecificationRouteImport
    }
    '/app/products_/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId': {
      id: '/app/products_/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId'
      path: '/delete-nomenclature-detail/$nomenclatureDetailId'
      fullPath: '/app/products/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId'
      preLoaderRoute: typeof AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/delete-specification/$specificationId': {
      id: '/app/products_/$productId/manage/delete-specification/$specificationId'
      path: '/delete-specification/$specificationId'
      fullPath: '/app/products/$productId/manage/delete-specification/$specificationId'
      preLoaderRoute: typeof AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/delete-stock/$stockId': {
      id: '/app/products_/$productId/manage/delete-stock/$stockId'
      path: '/delete-stock/$stockId'
      fullPath: '/app/products/$productId/manage/delete-stock/$stockId'
      preLoaderRoute: typeof AppProductsProductIdManageDeleteStockStockIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/delete-version/$versionId': {
      id: '/app/products_/$productId/manage/delete-version/$versionId'
      path: '/delete-version/$versionId'
      fullPath: '/app/products/$productId/manage/delete-version/$versionId'
      preLoaderRoute: typeof AppProductsProductIdManageDeleteVersionVersionIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/remove-associated-product/$associatedProductId': {
      id: '/app/products_/$productId/manage/remove-associated-product/$associatedProductId'
      path: '/remove-associated-product/$associatedProductId'
      fullPath: '/app/products/$productId/manage/remove-associated-product/$associatedProductId'
      preLoaderRoute: typeof AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/stock-history/$stockId': {
      id: '/app/products_/$productId/manage/stock-history/$stockId'
      path: '/stock-history/$stockId'
      fullPath: '/app/products/$productId/manage/stock-history/$stockId'
      preLoaderRoute: typeof AppProductsProductIdManageStockHistoryStockIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId': {
      id: '/app/products_/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId'
      path: '/update-nomenclature-detail/$nomenclatureDetailId'
      fullPath: '/app/products/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId'
      preLoaderRoute: typeof AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/update-specification/$specificationId': {
      id: '/app/products_/$productId/manage/update-specification/$specificationId'
      path: '/update-specification/$specificationId'
      fullPath: '/app/products/$productId/manage/update-specification/$specificationId'
      preLoaderRoute: typeof AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/update-stock/$stockId': {
      id: '/app/products_/$productId/manage/update-stock/$stockId'
      path: '/update-stock/$stockId'
      fullPath: '/app/products/$productId/manage/update-stock/$stockId'
      preLoaderRoute: typeof AppProductsProductIdManageUpdateStockStockIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/products_/$productId/manage/update-version/$versionId': {
      id: '/app/products_/$productId/manage/update-version/$versionId'
      path: '/update-version/$versionId'
      fullPath: '/app/products/$productId/manage/update-version/$versionId'
      preLoaderRoute: typeof AppProductsProductIdManageUpdateVersionVersionIdRouteImport
      parentRoute: typeof AppProductsProductIdManageRouteImport
    }
    '/app/tools/formations/subscribers/$formationDetailId/create': {
      id: '/app/tools/formations/subscribers/$formationDetailId/create'
      path: '/create'
      fullPath: '/app/tools/formations/subscribers/$formationDetailId/create'
      preLoaderRoute: typeof AppToolsFormationsSubscribersFormationDetailIdCreateRouteImport
      parentRoute: typeof AppToolsFormationsSubscribersFormationDetailIdRouteImport
    }
    '/app/tools/formations/update/$formationId/add-detail': {
      id: '/app/tools/formations/update/$formationId/add-detail'
      path: '/add-detail'
      fullPath: '/app/tools/formations/update/$formationId/add-detail'
      preLoaderRoute: typeof AppToolsFormationsUpdateFormationIdAddDetailRouteImport
      parentRoute: typeof AppToolsFormationsUpdateFormationIdRouteImport
    }
    '/app/tools/formations/update/$formationId/details': {
      id: '/app/tools/formations/update/$formationId/details'
      path: '/details'
      fullPath: '/app/tools/formations/update/$formationId/details'
      preLoaderRoute: typeof AppToolsFormationsUpdateFormationIdDetailsRouteImport
      parentRoute: typeof AppToolsFormationsUpdateFormationIdRouteImport
    }
    '/app/tools/scheduler/details/$rdvId/delete': {
      id: '/app/tools/scheduler/details/$rdvId/delete'
      path: '/delete'
      fullPath: '/app/tools/scheduler/details/$rdvId/delete'
      preLoaderRoute: typeof AppToolsSchedulerDetailsRdvIdDeleteRouteImport
      parentRoute: typeof AppToolsSchedulerDetailsRdvIdRouteImport
    }
    '/app/tools/scheduler/details/$rdvId/update': {
      id: '/app/tools/scheduler/details/$rdvId/update'
      path: '/update'
      fullPath: '/app/tools/scheduler/details/$rdvId/update'
      preLoaderRoute: typeof AppToolsSchedulerDetailsRdvIdUpdateRouteImport
      parentRoute: typeof AppToolsSchedulerDetailsRdvIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bl/update': {
      id: '/app/businesses-rma_/business/$businessId/bl/update'
      path: '/update'
      fullPath: '/app/businesses-rma/business/$businessId/bl/update'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBlRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/study/': {
      id: '/app/businesses-rma_/business/$businessId_/study/'
      path: '/'
      fullPath: '/app/businesses-rma/business/$businessId/study/'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyIndexImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyRouteImport
    }
    '/app/businesses-rma_/business/$businessId/arc/delete-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/arc/delete-detail/$detailId'
      path: '/delete-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/arc/delete-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdArcRouteImport
    }
    '/app/businesses-rma_/business/$businessId/arc/pdf/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId/arc/pdf/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/arc/pdf/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdArcPdfRouteImport
    }
    '/app/businesses-rma_/business/$businessId/arc/update-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/arc/update-detail/$detailId'
      path: '/update-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/arc/update-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdArcRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bill/credits/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId/bill/credits/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/bill/credits/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBillCreditsRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/add-serial/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/bp/add-serial/$detailId'
      path: '/add-serial/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/bp/add-serial/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/create-detail-rma/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/bp/create-detail-rma/$detailId'
      path: '/create-detail-rma/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/bp/create-detail-rma/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/create-serial-rma/$serialId': {
      id: '/app/businesses-rma_/business/$businessId/bp/create-serial-rma/$serialId'
      path: '/create-serial-rma/$serialId'
      fullPath: '/app/businesses-rma/business/$businessId/bp/create-serial-rma/$serialId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/delete-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/bp/delete-detail/$detailId'
      path: '/delete-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/bp/delete-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/delete-serial/$serialId': {
      id: '/app/businesses-rma_/business/$businessId/bp/delete-serial/$serialId'
      path: '/delete-serial/$serialId'
      fullPath: '/app/businesses-rma/business/$businessId/bp/delete-serial/$serialId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/bp/update-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/bp/update-detail/$detailId'
      path: '/update-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/bp/update-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/address-book/create': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/address-book/create'
      path: '/create'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/address-book/create'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId'
      path: '/confirm-quotation-import/$otherBusinessId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/delete-ged-object/$objectRelativePath': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/delete-ged-object/$objectRelativePath'
      path: '/delete-ged-object/$objectRelativePath'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/delete-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/delete-link/$associatedId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/delete-link/$associatedId'
      path: '/delete-link/$associatedId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/delete-link/$associatedId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId'
      path: '/$emailId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/lifesheet-email/$lifesheetId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/lifesheet-email/$lifesheetId'
      path: '/lifesheet-email/$lifesheetId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/lifesheet-email/$lifesheetId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/rename-ged-object/$objectRelativePath': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/rename-ged-object/$objectRelativePath'
      path: '/rename-ged-object/$objectRelativePath'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/rename-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/task-email/$taskId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/task-email/$taskId'
      path: '/task-email/$taskId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/task-email/$taskId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/unlink-task/$taskId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/unlink-task/$taskId'
      path: '/unlink-task/$taskId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/unlink-task/$taskId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/create-associated-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/quotation/create-associated-detail/$detailId'
      path: '/create-associated-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/create-associated-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/create-detail/$subquotationId': {
      id: '/app/businesses-rma_/business/$businessId/quotation/create-detail/$subquotationId'
      path: '/create-detail/$subquotationId'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/create-detail/$subquotationId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/delete-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/quotation/delete-detail/$detailId'
      path: '/delete-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/delete-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/delete-subquotation/$subquotationId': {
      id: '/app/businesses-rma_/business/$businessId/quotation/delete-subquotation/$subquotationId'
      path: '/delete-subquotation/$subquotationId'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/delete-subquotation/$subquotationId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/pdf/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId/quotation/pdf/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/pdf/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/update-detail/$detailId': {
      id: '/app/businesses-rma_/business/$businessId/quotation/update-detail/$detailId'
      path: '/update-detail/$detailId'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/update-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/update-subquotation/$subquotationId': {
      id: '/app/businesses-rma_/business/$businessId/quotation/update-subquotation/$subquotationId'
      path: '/update-subquotation/$subquotationId'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/update-subquotation/$subquotationId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-faq': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-faq'
      path: '/create-faq'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-faq'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-ged-directory': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-ged-directory'
      path: '/create-ged-directory'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-ged-directory'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-lifesheet': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-lifesheet'
      path: '/create-lifesheet'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-lifesheet'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-link': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-link'
      path: '/create-link'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-link'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete'
      path: '/delete'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-cumulated-time': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-cumulated-time'
      path: '/edit-cumulated-time'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-cumulated-time'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-no-billed-time': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-no-billed-time'
      path: '/edit-no-billed-time'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-no-billed-time'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-subtitle': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-subtitle'
      path: '/edit-subtitle'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-subtitle'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/import-ged-files': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/import-ged-files'
      path: '/import-ged-files'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/import-ged-files'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf'
      path: '/pdf'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery/delete-detail/$detailId': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery/delete-detail/$detailId'
      path: '/delete-detail/$detailId'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery/delete-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery/pdf/send-by-email': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery/pdf/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery/pdf/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryPdfRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/delivery/update-detail/$detailId': {
      id: '/app/businesses-rma_/rma/$rmaId/delivery/update-detail/$detailId'
      path: '/update-detail/$detailId'
      fullPath: '/app/businesses-rma/rma/$rmaId/delivery/update-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/reception/delete-detail/$detailId': {
      id: '/app/businesses-rma_/rma/$rmaId/reception/delete-detail/$detailId'
      path: '/delete-detail/$detailId'
      fullPath: '/app/businesses-rma/rma/$rmaId/reception/delete-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdReceptionRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/reception/pdf/send-by-email': {
      id: '/app/businesses-rma_/rma/$rmaId/reception/pdf/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/rma/$rmaId/reception/pdf/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdReceptionPdfRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/reception/update-detail/$detailId': {
      id: '/app/businesses-rma_/rma/$rmaId/reception/update-detail/$detailId'
      path: '/update-detail/$detailId'
      fullPath: '/app/businesses-rma/rma/$rmaId/reception/update-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdReceptionRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/delete-detail/$detailId': {
      id: '/app/businesses-rma_/rma/$rmaId/support/delete-detail/$detailId'
      path: '/delete-detail/$detailId'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/delete-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/delete-ged-object/$relativePath': {
      id: '/app/businesses-rma_/rma/$rmaId/support/delete-ged-object/$relativePath'
      path: '/delete-ged-object/$relativePath'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/delete-ged-object/$relativePath'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/delete-link/$associatedId': {
      id: '/app/businesses-rma_/rma/$rmaId/support/delete-link/$associatedId'
      path: '/delete-link/$associatedId'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/delete-link/$associatedId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/lifesheet-email/$lifesheetId': {
      id: '/app/businesses-rma_/rma/$rmaId/support/lifesheet-email/$lifesheetId'
      path: '/lifesheet-email/$lifesheetId'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/lifesheet-email/$lifesheetId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/pdf/send-by-email': {
      id: '/app/businesses-rma_/rma/$rmaId/support/pdf/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/pdf/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportPdfRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/rename-ged-object/$objectRelativePath': {
      id: '/app/businesses-rma_/rma/$rmaId/support/rename-ged-object/$objectRelativePath'
      path: '/rename-ged-object/$objectRelativePath'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/rename-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId': {
      id: '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId'
      path: '/task-email/$taskId'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/unlink-task/$taskId': {
      id: '/app/businesses-rma_/rma/$rmaId/support/unlink-task/$taskId'
      path: '/unlink-task/$taskId'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/unlink-task/$taskId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/update-detail/$detailId': {
      id: '/app/businesses-rma_/rma/$rmaId/support/update-detail/$detailId'
      path: '/update-detail/$detailId'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/update-detail/$detailId'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteImport
    }
    '/app/enterprises_/$enterpriseId/email-history/email/$emailId/reply': {
      id: '/app/enterprises_/$enterpriseId/email-history/email/$emailId/reply'
      path: '/reply'
      fullPath: '/app/enterprises/$enterpriseId/email-history/email/$emailId/reply'
      preLoaderRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteImport
      parentRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteImport
    }
    '/app/products_/$productId/informations/task-email/$taskId/reply': {
      id: '/app/products_/$productId/informations/task-email/$taskId/reply'
      path: '/reply'
      fullPath: '/app/products/$productId/informations/task-email/$taskId/reply'
      preLoaderRoute: typeof AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteImport
      parentRoute: typeof AppProductsProductIdInformationsTaskEmailTaskIdRouteImport
    }
    '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId': {
      id: '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId'
      path: '/delete/$subscriptionId'
      fullPath: '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId'
      preLoaderRoute: typeof AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteImport
      parentRoute: typeof AppToolsFormationsSubscribersFormationDetailIdRouteImport
    }
    '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId': {
      id: '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId'
      path: '/send-email/$subscriptionId'
      fullPath: '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId'
      preLoaderRoute: typeof AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteImport
      parentRoute: typeof AppToolsFormationsSubscribersFormationDetailIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/address-book/delete/$addressId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/address-book/delete/$addressId'
      path: '/delete/$addressId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/address-book/delete/$addressId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/address-book/update/$addressId': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/address-book/update/$addressId'
      path: '/update/$addressId'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/address-book/update/$addressId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteImport
    }
    '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId/reply': {
      id: '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId/reply'
      path: '/reply'
      fullPath: '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId/reply'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages': {
      id: '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages'
      path: '/predefined-messages'
      fullPath: '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-ged-object/$objectRelativePath': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-ged-object/$objectRelativePath'
      path: '/delete-ged-object/$objectRelativePath'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-link/$associatedId': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-link/$associatedId'
      path: '/delete-link/$associatedId'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-link/$associatedId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/lifesheet-email/$lifesheetId': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/lifesheet-email/$lifesheetId'
      path: '/lifesheet-email/$lifesheetId'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/lifesheet-email/$lifesheetId'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf/send-by-email': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf/send-by-email'
      path: '/send-by-email'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf/send-by-email'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteImport
    }
    '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/rename-ged-object/$objectRelativePath': {
      id: '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/rename-ged-object/$objectRelativePath'
      path: '/rename-ged-object/$objectRelativePath'
      fullPath: '/app/businesses-rma/business/$businessId/assistance/$assistanceId/rename-ged-object/$objectRelativePath'
      preLoaderRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteImport
      parentRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteImport
    }
    '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId/reply': {
      id: '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId/reply'
      path: '/reply'
      fullPath: '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId/reply'
      preLoaderRoute: typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteImport
      parentRoute: typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteImport
    }
  }
}

// Create and export the route tree

interface AppBusinessesRmaRouteRouteChildren {
  AppBusinessesRmaRepresentativeTurnoverRouteRoute: typeof AppBusinessesRmaRepresentativeTurnoverRouteRoute
  AppBusinessesRmaSearchByProductsRouteRoute: typeof AppBusinessesRmaSearchByProductsRouteRoute
}

const AppBusinessesRmaRouteRouteChildren: AppBusinessesRmaRouteRouteChildren = {
  AppBusinessesRmaRepresentativeTurnoverRouteRoute:
    AppBusinessesRmaRepresentativeTurnoverRouteRoute,
  AppBusinessesRmaSearchByProductsRouteRoute:
    AppBusinessesRmaSearchByProductsRouteRoute,
}

const AppBusinessesRmaRouteRouteWithChildren =
  AppBusinessesRmaRouteRoute._addFileChildren(
    AppBusinessesRmaRouteRouteChildren,
  )

interface AppDashboardTaskEmailTaskIdRouteRouteChildren {
  AppDashboardTaskEmailTaskIdReplyRouteRoute: typeof AppDashboardTaskEmailTaskIdReplyRouteRoute
}

const AppDashboardTaskEmailTaskIdRouteRouteChildren: AppDashboardTaskEmailTaskIdRouteRouteChildren =
  {
    AppDashboardTaskEmailTaskIdReplyRouteRoute:
      AppDashboardTaskEmailTaskIdReplyRouteRoute,
  }

const AppDashboardTaskEmailTaskIdRouteRouteWithChildren =
  AppDashboardTaskEmailTaskIdRouteRoute._addFileChildren(
    AppDashboardTaskEmailTaskIdRouteRouteChildren,
  )

interface AppDashboardRouteRouteChildren {
  AppDashboardCreateCollectiveTaskRouteRoute: typeof AppDashboardCreateCollectiveTaskRouteRoute
  AppDashboardCreatePersonalTaskRouteRoute: typeof AppDashboardCreatePersonalTaskRouteRoute
  AppDashboardCreateProgressiveInfoRouteRoute: typeof AppDashboardCreateProgressiveInfoRouteRoute
  AppDashboardDeleteCollectiveTasksRouteRoute: typeof AppDashboardDeleteCollectiveTasksRouteRoute
  AppDashboardArchivePersonalTaskTaskIdRouteRoute: typeof AppDashboardArchivePersonalTaskTaskIdRouteRoute
  AppDashboardDeleteCollectiveTaskTaskIdRouteRoute: typeof AppDashboardDeleteCollectiveTaskTaskIdRouteRoute
  AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute: typeof AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute
  AppDashboardLinkPersonalTaskTaskIdRouteRoute: typeof AppDashboardLinkPersonalTaskTaskIdRouteRoute
  AppDashboardOtherPersonalTasksProfileIdRouteRoute: typeof AppDashboardOtherPersonalTasksProfileIdRouteRoute
  AppDashboardPersonalTaskDetailsTaskIdRouteRoute: typeof AppDashboardPersonalTaskDetailsTaskIdRouteRoute
  AppDashboardSchedulerEventDetailsEventIdRouteRoute: typeof AppDashboardSchedulerEventDetailsEventIdRouteRoute
  AppDashboardTakeCollectiveTaskTaskIdRouteRoute: typeof AppDashboardTakeCollectiveTaskTaskIdRouteRoute
  AppDashboardTaskCommentsTaskIdRouteRoute: typeof AppDashboardTaskCommentsTaskIdRouteRoute
  AppDashboardTaskEmailTaskIdRouteRoute: typeof AppDashboardTaskEmailTaskIdRouteRouteWithChildren
  AppDashboardTransferTaskTaskIdRouteRoute: typeof AppDashboardTransferTaskTaskIdRouteRoute
  AppDashboardUnlinkPersonalTaskTaskIdRouteRoute: typeof AppDashboardUnlinkPersonalTaskTaskIdRouteRoute
  AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute: typeof AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute
  AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute: typeof AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute
  AppDashboardValidatePersonalTaskTaskIdRouteRoute: typeof AppDashboardValidatePersonalTaskTaskIdRouteRoute
}

const AppDashboardRouteRouteChildren: AppDashboardRouteRouteChildren = {
  AppDashboardCreateCollectiveTaskRouteRoute:
    AppDashboardCreateCollectiveTaskRouteRoute,
  AppDashboardCreatePersonalTaskRouteRoute:
    AppDashboardCreatePersonalTaskRouteRoute,
  AppDashboardCreateProgressiveInfoRouteRoute:
    AppDashboardCreateProgressiveInfoRouteRoute,
  AppDashboardDeleteCollectiveTasksRouteRoute:
    AppDashboardDeleteCollectiveTasksRouteRoute,
  AppDashboardArchivePersonalTaskTaskIdRouteRoute:
    AppDashboardArchivePersonalTaskTaskIdRouteRoute,
  AppDashboardDeleteCollectiveTaskTaskIdRouteRoute:
    AppDashboardDeleteCollectiveTaskTaskIdRouteRoute,
  AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute:
    AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute,
  AppDashboardLinkPersonalTaskTaskIdRouteRoute:
    AppDashboardLinkPersonalTaskTaskIdRouteRoute,
  AppDashboardOtherPersonalTasksProfileIdRouteRoute:
    AppDashboardOtherPersonalTasksProfileIdRouteRoute,
  AppDashboardPersonalTaskDetailsTaskIdRouteRoute:
    AppDashboardPersonalTaskDetailsTaskIdRouteRoute,
  AppDashboardSchedulerEventDetailsEventIdRouteRoute:
    AppDashboardSchedulerEventDetailsEventIdRouteRoute,
  AppDashboardTakeCollectiveTaskTaskIdRouteRoute:
    AppDashboardTakeCollectiveTaskTaskIdRouteRoute,
  AppDashboardTaskCommentsTaskIdRouteRoute:
    AppDashboardTaskCommentsTaskIdRouteRoute,
  AppDashboardTaskEmailTaskIdRouteRoute:
    AppDashboardTaskEmailTaskIdRouteRouteWithChildren,
  AppDashboardTransferTaskTaskIdRouteRoute:
    AppDashboardTransferTaskTaskIdRouteRoute,
  AppDashboardUnlinkPersonalTaskTaskIdRouteRoute:
    AppDashboardUnlinkPersonalTaskTaskIdRouteRoute,
  AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute:
    AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute,
  AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute:
    AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute,
  AppDashboardValidatePersonalTaskTaskIdRouteRoute:
    AppDashboardValidatePersonalTaskTaskIdRouteRoute,
}

const AppDashboardRouteRouteWithChildren =
  AppDashboardRouteRoute._addFileChildren(AppDashboardRouteRouteChildren)

interface AppEnterprisesRouteRouteChildren {
  AppEnterprisesCreateRouteRoute: typeof AppEnterprisesCreateRouteRoute
  AppEnterprisesCreateContactBusinessContactIdRouteRoute: typeof AppEnterprisesCreateContactBusinessContactIdRouteRoute
  AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute: typeof AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute
  AppEnterprisesCreateContactEnterpriseIdRouteRoute: typeof AppEnterprisesCreateContactEnterpriseIdRouteRoute
  AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute: typeof AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute
  AppEnterprisesDeleteContactContactIdRouteRoute: typeof AppEnterprisesDeleteContactContactIdRouteRoute
  AppEnterprisesSendEmailToContactContactIdRouteRoute: typeof AppEnterprisesSendEmailToContactContactIdRouteRoute
  AppEnterprisesUpdateContactPasswordContactIdRouteRoute: typeof AppEnterprisesUpdateContactPasswordContactIdRouteRoute
  AppEnterprisesUpdateContactContactIdRouteRoute: typeof AppEnterprisesUpdateContactContactIdRouteRoute
}

const AppEnterprisesRouteRouteChildren: AppEnterprisesRouteRouteChildren = {
  AppEnterprisesCreateRouteRoute: AppEnterprisesCreateRouteRoute,
  AppEnterprisesCreateContactBusinessContactIdRouteRoute:
    AppEnterprisesCreateContactBusinessContactIdRouteRoute,
  AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute:
    AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute,
  AppEnterprisesCreateContactEnterpriseIdRouteRoute:
    AppEnterprisesCreateContactEnterpriseIdRouteRoute,
  AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute:
    AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute,
  AppEnterprisesDeleteContactContactIdRouteRoute:
    AppEnterprisesDeleteContactContactIdRouteRoute,
  AppEnterprisesSendEmailToContactContactIdRouteRoute:
    AppEnterprisesSendEmailToContactContactIdRouteRoute,
  AppEnterprisesUpdateContactPasswordContactIdRouteRoute:
    AppEnterprisesUpdateContactPasswordContactIdRouteRoute,
  AppEnterprisesUpdateContactContactIdRouteRoute:
    AppEnterprisesUpdateContactContactIdRouteRoute,
}

const AppEnterprisesRouteRouteWithChildren =
  AppEnterprisesRouteRoute._addFileChildren(AppEnterprisesRouteRouteChildren)

interface AppFaqGedFaqIdRouteRouteChildren {
  AppFaqGedFaqIdCreateDirectoryRouteRoute: typeof AppFaqGedFaqIdCreateDirectoryRouteRoute
  AppFaqGedFaqIdImportFilesRouteRoute: typeof AppFaqGedFaqIdImportFilesRouteRoute
  AppFaqGedFaqIdDeleteItemRelativePathRouteRoute: typeof AppFaqGedFaqIdDeleteItemRelativePathRouteRoute
  AppFaqGedFaqIdRenameItemRelativePathRouteRoute: typeof AppFaqGedFaqIdRenameItemRelativePathRouteRoute
}

const AppFaqGedFaqIdRouteRouteChildren: AppFaqGedFaqIdRouteRouteChildren = {
  AppFaqGedFaqIdCreateDirectoryRouteRoute:
    AppFaqGedFaqIdCreateDirectoryRouteRoute,
  AppFaqGedFaqIdImportFilesRouteRoute: AppFaqGedFaqIdImportFilesRouteRoute,
  AppFaqGedFaqIdDeleteItemRelativePathRouteRoute:
    AppFaqGedFaqIdDeleteItemRelativePathRouteRoute,
  AppFaqGedFaqIdRenameItemRelativePathRouteRoute:
    AppFaqGedFaqIdRenameItemRelativePathRouteRoute,
}

const AppFaqGedFaqIdRouteRouteWithChildren =
  AppFaqGedFaqIdRouteRoute._addFileChildren(AppFaqGedFaqIdRouteRouteChildren)

interface AppFaqRouteRouteChildren {
  AppFaqCreateRouteRoute: typeof AppFaqCreateRouteRoute
  AppFaqArchiveFaqIdRouteRoute: typeof AppFaqArchiveFaqIdRouteRoute
  AppFaqDeleteFaqIdRouteRoute: typeof AppFaqDeleteFaqIdRouteRoute
  AppFaqGedFaqIdRouteRoute: typeof AppFaqGedFaqIdRouteRouteWithChildren
  AppFaqSendByEmailFaqIdRouteRoute: typeof AppFaqSendByEmailFaqIdRouteRoute
  AppFaqUpdateFaqIdRouteRoute: typeof AppFaqUpdateFaqIdRouteRoute
}

const AppFaqRouteRouteChildren: AppFaqRouteRouteChildren = {
  AppFaqCreateRouteRoute: AppFaqCreateRouteRoute,
  AppFaqArchiveFaqIdRouteRoute: AppFaqArchiveFaqIdRouteRoute,
  AppFaqDeleteFaqIdRouteRoute: AppFaqDeleteFaqIdRouteRoute,
  AppFaqGedFaqIdRouteRoute: AppFaqGedFaqIdRouteRouteWithChildren,
  AppFaqSendByEmailFaqIdRouteRoute: AppFaqSendByEmailFaqIdRouteRoute,
  AppFaqUpdateFaqIdRouteRoute: AppFaqUpdateFaqIdRouteRoute,
}

const AppFaqRouteRouteWithChildren = AppFaqRouteRoute._addFileChildren(
  AppFaqRouteRouteChildren,
)

interface AppProductsSerialNumbersRouteRouteChildren {
  AppProductsSerialNumbersCreateRouteRoute: typeof AppProductsSerialNumbersCreateRouteRoute
  AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute: typeof AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute
  AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute: typeof AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute
  AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute: typeof AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute
  AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute: typeof AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute
}

const AppProductsSerialNumbersRouteRouteChildren: AppProductsSerialNumbersRouteRouteChildren =
  {
    AppProductsSerialNumbersCreateRouteRoute:
      AppProductsSerialNumbersCreateRouteRoute,
    AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute:
      AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute,
    AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute:
      AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute,
    AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute:
      AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute,
    AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute:
      AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute,
  }

const AppProductsSerialNumbersRouteRouteWithChildren =
  AppProductsSerialNumbersRouteRoute._addFileChildren(
    AppProductsSerialNumbersRouteRouteChildren,
  )

interface AppProductsRouteRouteChildren {
  AppProductsCreateRouteRoute: typeof AppProductsCreateRouteRoute
  AppProductsSerialNumbersRouteRoute: typeof AppProductsSerialNumbersRouteRouteWithChildren
}

const AppProductsRouteRouteChildren: AppProductsRouteRouteChildren = {
  AppProductsCreateRouteRoute: AppProductsCreateRouteRoute,
  AppProductsSerialNumbersRouteRoute:
    AppProductsSerialNumbersRouteRouteWithChildren,
}

const AppProductsRouteRouteWithChildren =
  AppProductsRouteRoute._addFileChildren(AppProductsRouteRouteChildren)

interface AppToolsCreditRouteRouteChildren {
  AppToolsCreditDetailsRouteRoute: typeof AppToolsCreditDetailsRouteRoute
  AppToolsCreditShowRouteRoute: typeof AppToolsCreditShowRouteRoute
}

const AppToolsCreditRouteRouteChildren: AppToolsCreditRouteRouteChildren = {
  AppToolsCreditDetailsRouteRoute: AppToolsCreditDetailsRouteRoute,
  AppToolsCreditShowRouteRoute: AppToolsCreditShowRouteRoute,
}

const AppToolsCreditRouteRouteWithChildren =
  AppToolsCreditRouteRoute._addFileChildren(AppToolsCreditRouteRouteChildren)

interface AppToolsDdnsRouteRouteChildren {
  AppToolsDdnsCreateRouteRoute: typeof AppToolsDdnsCreateRouteRoute
  AppToolsDdnsDeleteDdnsIdRouteRoute: typeof AppToolsDdnsDeleteDdnsIdRouteRoute
}

const AppToolsDdnsRouteRouteChildren: AppToolsDdnsRouteRouteChildren = {
  AppToolsDdnsCreateRouteRoute: AppToolsDdnsCreateRouteRoute,
  AppToolsDdnsDeleteDdnsIdRouteRoute: AppToolsDdnsDeleteDdnsIdRouteRoute,
}

const AppToolsDdnsRouteRouteWithChildren =
  AppToolsDdnsRouteRoute._addFileChildren(AppToolsDdnsRouteRouteChildren)

interface AppToolsDepartmentsRouteRouteChildren {
  AppToolsDepartmentsCreateRouteRoute: typeof AppToolsDepartmentsCreateRouteRoute
  AppToolsDepartmentsDeleteDepartmentIdRouteRoute: typeof AppToolsDepartmentsDeleteDepartmentIdRouteRoute
  AppToolsDepartmentsUpdateDepartmentIdRouteRoute: typeof AppToolsDepartmentsUpdateDepartmentIdRouteRoute
}

const AppToolsDepartmentsRouteRouteChildren: AppToolsDepartmentsRouteRouteChildren =
  {
    AppToolsDepartmentsCreateRouteRoute: AppToolsDepartmentsCreateRouteRoute,
    AppToolsDepartmentsDeleteDepartmentIdRouteRoute:
      AppToolsDepartmentsDeleteDepartmentIdRouteRoute,
    AppToolsDepartmentsUpdateDepartmentIdRouteRoute:
      AppToolsDepartmentsUpdateDepartmentIdRouteRoute,
  }

const AppToolsDepartmentsRouteRouteWithChildren =
  AppToolsDepartmentsRouteRoute._addFileChildren(
    AppToolsDepartmentsRouteRouteChildren,
  )

interface AppToolsEmailsEmailIdRouteRouteChildren {
  AppToolsEmailsEmailIdReplyRouteRoute: typeof AppToolsEmailsEmailIdReplyRouteRoute
}

const AppToolsEmailsEmailIdRouteRouteChildren: AppToolsEmailsEmailIdRouteRouteChildren =
  {
    AppToolsEmailsEmailIdReplyRouteRoute: AppToolsEmailsEmailIdReplyRouteRoute,
  }

const AppToolsEmailsEmailIdRouteRouteWithChildren =
  AppToolsEmailsEmailIdRouteRoute._addFileChildren(
    AppToolsEmailsEmailIdRouteRouteChildren,
  )

interface AppToolsEmailsRouteRouteChildren {
  AppToolsEmailsEmailIdRouteRoute: typeof AppToolsEmailsEmailIdRouteRouteWithChildren
}

const AppToolsEmailsRouteRouteChildren: AppToolsEmailsRouteRouteChildren = {
  AppToolsEmailsEmailIdRouteRoute: AppToolsEmailsEmailIdRouteRouteWithChildren,
}

const AppToolsEmailsRouteRouteWithChildren =
  AppToolsEmailsRouteRoute._addFileChildren(AppToolsEmailsRouteRouteChildren)

interface AppToolsExternalLinksRouteRouteChildren {
  AppToolsExternalLinksCreateRouteRoute: typeof AppToolsExternalLinksCreateRouteRoute
  AppToolsExternalLinksArchiveExternalLinkIdRouteRoute: typeof AppToolsExternalLinksArchiveExternalLinkIdRouteRoute
  AppToolsExternalLinksDeleteExternalLinkIdRouteRoute: typeof AppToolsExternalLinksDeleteExternalLinkIdRouteRoute
  AppToolsExternalLinksUpdateExternalLinkIdRouteRoute: typeof AppToolsExternalLinksUpdateExternalLinkIdRouteRoute
}

const AppToolsExternalLinksRouteRouteChildren: AppToolsExternalLinksRouteRouteChildren =
  {
    AppToolsExternalLinksCreateRouteRoute:
      AppToolsExternalLinksCreateRouteRoute,
    AppToolsExternalLinksArchiveExternalLinkIdRouteRoute:
      AppToolsExternalLinksArchiveExternalLinkIdRouteRoute,
    AppToolsExternalLinksDeleteExternalLinkIdRouteRoute:
      AppToolsExternalLinksDeleteExternalLinkIdRouteRoute,
    AppToolsExternalLinksUpdateExternalLinkIdRouteRoute:
      AppToolsExternalLinksUpdateExternalLinkIdRouteRoute,
  }

const AppToolsExternalLinksRouteRouteWithChildren =
  AppToolsExternalLinksRouteRoute._addFileChildren(
    AppToolsExternalLinksRouteRouteChildren,
  )

interface AppToolsFormationsCreateRouteRouteChildren {
  AppToolsFormationsCreateAddDetailRouteRoute: typeof AppToolsFormationsCreateAddDetailRouteRoute
  AppToolsFormationsCreateDetailsRouteRoute: typeof AppToolsFormationsCreateDetailsRouteRoute
}

const AppToolsFormationsCreateRouteRouteChildren: AppToolsFormationsCreateRouteRouteChildren =
  {
    AppToolsFormationsCreateAddDetailRouteRoute:
      AppToolsFormationsCreateAddDetailRouteRoute,
    AppToolsFormationsCreateDetailsRouteRoute:
      AppToolsFormationsCreateDetailsRouteRoute,
  }

const AppToolsFormationsCreateRouteRouteWithChildren =
  AppToolsFormationsCreateRouteRoute._addFileChildren(
    AppToolsFormationsCreateRouteRouteChildren,
  )

interface AppToolsFormationsSubscribersFormationDetailIdRouteRouteChildren {
  AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute: typeof AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute
  AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute: typeof AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute
  AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute: typeof AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute
}

const AppToolsFormationsSubscribersFormationDetailIdRouteRouteChildren: AppToolsFormationsSubscribersFormationDetailIdRouteRouteChildren =
  {
    AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute:
      AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute,
    AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute:
      AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute,
    AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute:
      AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute,
  }

const AppToolsFormationsSubscribersFormationDetailIdRouteRouteWithChildren =
  AppToolsFormationsSubscribersFormationDetailIdRouteRoute._addFileChildren(
    AppToolsFormationsSubscribersFormationDetailIdRouteRouteChildren,
  )

interface AppToolsFormationsUpdateFormationIdRouteRouteChildren {
  AppToolsFormationsUpdateFormationIdAddDetailRouteRoute: typeof AppToolsFormationsUpdateFormationIdAddDetailRouteRoute
  AppToolsFormationsUpdateFormationIdDetailsRouteRoute: typeof AppToolsFormationsUpdateFormationIdDetailsRouteRoute
}

const AppToolsFormationsUpdateFormationIdRouteRouteChildren: AppToolsFormationsUpdateFormationIdRouteRouteChildren =
  {
    AppToolsFormationsUpdateFormationIdAddDetailRouteRoute:
      AppToolsFormationsUpdateFormationIdAddDetailRouteRoute,
    AppToolsFormationsUpdateFormationIdDetailsRouteRoute:
      AppToolsFormationsUpdateFormationIdDetailsRouteRoute,
  }

const AppToolsFormationsUpdateFormationIdRouteRouteWithChildren =
  AppToolsFormationsUpdateFormationIdRouteRoute._addFileChildren(
    AppToolsFormationsUpdateFormationIdRouteRouteChildren,
  )

interface AppToolsFormationsRouteRouteChildren {
  AppToolsFormationsCreateRouteRoute: typeof AppToolsFormationsCreateRouteRouteWithChildren
  AppToolsFormationsDeleteFormationIdRouteRoute: typeof AppToolsFormationsDeleteFormationIdRouteRoute
  AppToolsFormationsSubscribersFormationDetailIdRouteRoute: typeof AppToolsFormationsSubscribersFormationDetailIdRouteRouteWithChildren
  AppToolsFormationsUpdateFormationIdRouteRoute: typeof AppToolsFormationsUpdateFormationIdRouteRouteWithChildren
}

const AppToolsFormationsRouteRouteChildren: AppToolsFormationsRouteRouteChildren =
  {
    AppToolsFormationsCreateRouteRoute:
      AppToolsFormationsCreateRouteRouteWithChildren,
    AppToolsFormationsDeleteFormationIdRouteRoute:
      AppToolsFormationsDeleteFormationIdRouteRoute,
    AppToolsFormationsSubscribersFormationDetailIdRouteRoute:
      AppToolsFormationsSubscribersFormationDetailIdRouteRouteWithChildren,
    AppToolsFormationsUpdateFormationIdRouteRoute:
      AppToolsFormationsUpdateFormationIdRouteRouteWithChildren,
  }

const AppToolsFormationsRouteRouteWithChildren =
  AppToolsFormationsRouteRoute._addFileChildren(
    AppToolsFormationsRouteRouteChildren,
  )

interface AppToolsMailsRouteRouteChildren {
  AppToolsMailsCreateRouteRoute: typeof AppToolsMailsCreateRouteRoute
  AppToolsMailsDeleteMailIdRouteRoute: typeof AppToolsMailsDeleteMailIdRouteRoute
  AppToolsMailsShowMailIdRouteRoute: typeof AppToolsMailsShowMailIdRouteRoute
  AppToolsMailsUpdateMailIdRouteRoute: typeof AppToolsMailsUpdateMailIdRouteRoute
}

const AppToolsMailsRouteRouteChildren: AppToolsMailsRouteRouteChildren = {
  AppToolsMailsCreateRouteRoute: AppToolsMailsCreateRouteRoute,
  AppToolsMailsDeleteMailIdRouteRoute: AppToolsMailsDeleteMailIdRouteRoute,
  AppToolsMailsShowMailIdRouteRoute: AppToolsMailsShowMailIdRouteRoute,
  AppToolsMailsUpdateMailIdRouteRoute: AppToolsMailsUpdateMailIdRouteRoute,
}

const AppToolsMailsRouteRouteWithChildren =
  AppToolsMailsRouteRoute._addFileChildren(AppToolsMailsRouteRouteChildren)

interface AppToolsMenuRouteRouteChildren {
  AppToolsMenuCreateEnterpriseRouteRoute: typeof AppToolsMenuCreateEnterpriseRouteRoute
  AppToolsMenuCreateProductRouteRoute: typeof AppToolsMenuCreateProductRouteRoute
}

const AppToolsMenuRouteRouteChildren: AppToolsMenuRouteRouteChildren = {
  AppToolsMenuCreateEnterpriseRouteRoute:
    AppToolsMenuCreateEnterpriseRouteRoute,
  AppToolsMenuCreateProductRouteRoute: AppToolsMenuCreateProductRouteRoute,
}

const AppToolsMenuRouteRouteWithChildren =
  AppToolsMenuRouteRoute._addFileChildren(AppToolsMenuRouteRouteChildren)

interface AppToolsNewsRouteRouteChildren {
  AppToolsNewsCreateRouteRoute: typeof AppToolsNewsCreateRouteRoute
  AppToolsNewsDeleteNewsIdRouteRoute: typeof AppToolsNewsDeleteNewsIdRouteRoute
  AppToolsNewsUpdateNewsIdRouteRoute: typeof AppToolsNewsUpdateNewsIdRouteRoute
}

const AppToolsNewsRouteRouteChildren: AppToolsNewsRouteRouteChildren = {
  AppToolsNewsCreateRouteRoute: AppToolsNewsCreateRouteRoute,
  AppToolsNewsDeleteNewsIdRouteRoute: AppToolsNewsDeleteNewsIdRouteRoute,
  AppToolsNewsUpdateNewsIdRouteRoute: AppToolsNewsUpdateNewsIdRouteRoute,
}

const AppToolsNewsRouteRouteWithChildren =
  AppToolsNewsRouteRoute._addFileChildren(AppToolsNewsRouteRouteChildren)

interface AppToolsPredefinedMessagesRouteRouteChildren {
  AppToolsPredefinedMessagesCreateRouteRoute: typeof AppToolsPredefinedMessagesCreateRouteRoute
  AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute: typeof AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute
  AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute: typeof AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute
}

const AppToolsPredefinedMessagesRouteRouteChildren: AppToolsPredefinedMessagesRouteRouteChildren =
  {
    AppToolsPredefinedMessagesCreateRouteRoute:
      AppToolsPredefinedMessagesCreateRouteRoute,
    AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute:
      AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute,
    AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute:
      AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute,
  }

const AppToolsPredefinedMessagesRouteRouteWithChildren =
  AppToolsPredefinedMessagesRouteRoute._addFileChildren(
    AppToolsPredefinedMessagesRouteRouteChildren,
  )

interface AppToolsPredefinedTextsRouteRouteChildren {
  AppToolsPredefinedTextsCreateRouteRoute: typeof AppToolsPredefinedTextsCreateRouteRoute
  AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute: typeof AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute
  AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute: typeof AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute
}

const AppToolsPredefinedTextsRouteRouteChildren: AppToolsPredefinedTextsRouteRouteChildren =
  {
    AppToolsPredefinedTextsCreateRouteRoute:
      AppToolsPredefinedTextsCreateRouteRoute,
    AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute:
      AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute,
    AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute:
      AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute,
  }

const AppToolsPredefinedTextsRouteRouteWithChildren =
  AppToolsPredefinedTextsRouteRoute._addFileChildren(
    AppToolsPredefinedTextsRouteRouteChildren,
  )

interface AppToolsProductFiltersRouteRouteChildren {
  AppToolsProductFiltersCreateRouteRoute: typeof AppToolsProductFiltersCreateRouteRoute
  AppToolsProductFiltersDeleteProductFilterIdRouteRoute: typeof AppToolsProductFiltersDeleteProductFilterIdRouteRoute
  AppToolsProductFiltersUpdateProductFilterIdRouteRoute: typeof AppToolsProductFiltersUpdateProductFilterIdRouteRoute
}

const AppToolsProductFiltersRouteRouteChildren: AppToolsProductFiltersRouteRouteChildren =
  {
    AppToolsProductFiltersCreateRouteRoute:
      AppToolsProductFiltersCreateRouteRoute,
    AppToolsProductFiltersDeleteProductFilterIdRouteRoute:
      AppToolsProductFiltersDeleteProductFilterIdRouteRoute,
    AppToolsProductFiltersUpdateProductFilterIdRouteRoute:
      AppToolsProductFiltersUpdateProductFilterIdRouteRoute,
  }

const AppToolsProductFiltersRouteRouteWithChildren =
  AppToolsProductFiltersRouteRoute._addFileChildren(
    AppToolsProductFiltersRouteRouteChildren,
  )

interface AppToolsProductInventoryRouteRouteChildren {
  AppToolsProductInventoryValidateQuantitiesRouteRoute: typeof AppToolsProductInventoryValidateQuantitiesRouteRoute
  AppToolsProductInventoryUpdateStockIdRouteRoute: typeof AppToolsProductInventoryUpdateStockIdRouteRoute
}

const AppToolsProductInventoryRouteRouteChildren: AppToolsProductInventoryRouteRouteChildren =
  {
    AppToolsProductInventoryValidateQuantitiesRouteRoute:
      AppToolsProductInventoryValidateQuantitiesRouteRoute,
    AppToolsProductInventoryUpdateStockIdRouteRoute:
      AppToolsProductInventoryUpdateStockIdRouteRoute,
  }

const AppToolsProductInventoryRouteRouteWithChildren =
  AppToolsProductInventoryRouteRoute._addFileChildren(
    AppToolsProductInventoryRouteRouteChildren,
  )

interface AppToolsProductShelvesRouteRouteChildren {
  AppToolsProductShelvesCreateRouteRoute: typeof AppToolsProductShelvesCreateRouteRoute
  AppToolsProductShelvesDeleteProductShelfIdRouteRoute: typeof AppToolsProductShelvesDeleteProductShelfIdRouteRoute
}

const AppToolsProductShelvesRouteRouteChildren: AppToolsProductShelvesRouteRouteChildren =
  {
    AppToolsProductShelvesCreateRouteRoute:
      AppToolsProductShelvesCreateRouteRoute,
    AppToolsProductShelvesDeleteProductShelfIdRouteRoute:
      AppToolsProductShelvesDeleteProductShelfIdRouteRoute,
  }

const AppToolsProductShelvesRouteRouteWithChildren =
  AppToolsProductShelvesRouteRoute._addFileChildren(
    AppToolsProductShelvesRouteRouteChildren,
  )

interface AppToolsSchedulerDetailsRdvIdRouteRouteChildren {
  AppToolsSchedulerDetailsRdvIdDeleteRouteRoute: typeof AppToolsSchedulerDetailsRdvIdDeleteRouteRoute
  AppToolsSchedulerDetailsRdvIdUpdateRouteRoute: typeof AppToolsSchedulerDetailsRdvIdUpdateRouteRoute
}

const AppToolsSchedulerDetailsRdvIdRouteRouteChildren: AppToolsSchedulerDetailsRdvIdRouteRouteChildren =
  {
    AppToolsSchedulerDetailsRdvIdDeleteRouteRoute:
      AppToolsSchedulerDetailsRdvIdDeleteRouteRoute,
    AppToolsSchedulerDetailsRdvIdUpdateRouteRoute:
      AppToolsSchedulerDetailsRdvIdUpdateRouteRoute,
  }

const AppToolsSchedulerDetailsRdvIdRouteRouteWithChildren =
  AppToolsSchedulerDetailsRdvIdRouteRoute._addFileChildren(
    AppToolsSchedulerDetailsRdvIdRouteRouteChildren,
  )

interface AppToolsSchedulerRouteRouteChildren {
  AppToolsSchedulerCreateRouteRoute: typeof AppToolsSchedulerCreateRouteRoute
  AppToolsSchedulerDetailsRdvIdRouteRoute: typeof AppToolsSchedulerDetailsRdvIdRouteRouteWithChildren
}

const AppToolsSchedulerRouteRouteChildren: AppToolsSchedulerRouteRouteChildren =
  {
    AppToolsSchedulerCreateRouteRoute: AppToolsSchedulerCreateRouteRoute,
    AppToolsSchedulerDetailsRdvIdRouteRoute:
      AppToolsSchedulerDetailsRdvIdRouteRouteWithChildren,
  }

const AppToolsSchedulerRouteRouteWithChildren =
  AppToolsSchedulerRouteRoute._addFileChildren(
    AppToolsSchedulerRouteRouteChildren,
  )

interface AppToolsVvaRouteRouteChildren {
  AppToolsVvaCreateRouteRoute: typeof AppToolsVvaCreateRouteRoute
  AppToolsVvaDeleteVvaIdRouteRoute: typeof AppToolsVvaDeleteVvaIdRouteRoute
}

const AppToolsVvaRouteRouteChildren: AppToolsVvaRouteRouteChildren = {
  AppToolsVvaCreateRouteRoute: AppToolsVvaCreateRouteRoute,
  AppToolsVvaDeleteVvaIdRouteRoute: AppToolsVvaDeleteVvaIdRouteRoute,
}

const AppToolsVvaRouteRouteWithChildren =
  AppToolsVvaRouteRoute._addFileChildren(AppToolsVvaRouteRouteChildren)

interface AppToolsRouteRouteChildren {
  AppToolsCreditRouteRoute: typeof AppToolsCreditRouteRouteWithChildren
  AppToolsDdnsRouteRoute: typeof AppToolsDdnsRouteRouteWithChildren
  AppToolsDepartmentsRouteRoute: typeof AppToolsDepartmentsRouteRouteWithChildren
  AppToolsEmailsRouteRoute: typeof AppToolsEmailsRouteRouteWithChildren
  AppToolsExternalLinksRouteRoute: typeof AppToolsExternalLinksRouteRouteWithChildren
  AppToolsFormationsRouteRoute: typeof AppToolsFormationsRouteRouteWithChildren
  AppToolsGlobalTurnoverRouteRoute: typeof AppToolsGlobalTurnoverRouteRoute
  AppToolsMailsRouteRoute: typeof AppToolsMailsRouteRouteWithChildren
  AppToolsMenuRouteRoute: typeof AppToolsMenuRouteRouteWithChildren
  AppToolsNewsRouteRoute: typeof AppToolsNewsRouteRouteWithChildren
  AppToolsPredefinedMessagesRouteRoute: typeof AppToolsPredefinedMessagesRouteRouteWithChildren
  AppToolsPredefinedTextsRouteRoute: typeof AppToolsPredefinedTextsRouteRouteWithChildren
  AppToolsProductFiltersRouteRoute: typeof AppToolsProductFiltersRouteRouteWithChildren
  AppToolsProductInventoryRouteRoute: typeof AppToolsProductInventoryRouteRouteWithChildren
  AppToolsProductShelvesRouteRoute: typeof AppToolsProductShelvesRouteRouteWithChildren
  AppToolsRepresentativesMapRouteRoute: typeof AppToolsRepresentativesMapRouteRoute
  AppToolsRepresentativesTurnoverRouteRoute: typeof AppToolsRepresentativesTurnoverRouteRoute
  AppToolsSchedulerRouteRoute: typeof AppToolsSchedulerRouteRouteWithChildren
  AppToolsVvaRouteRoute: typeof AppToolsVvaRouteRouteWithChildren
  AppToolsIndexRoute: typeof AppToolsIndexRoute
}

const AppToolsRouteRouteChildren: AppToolsRouteRouteChildren = {
  AppToolsCreditRouteRoute: AppToolsCreditRouteRouteWithChildren,
  AppToolsDdnsRouteRoute: AppToolsDdnsRouteRouteWithChildren,
  AppToolsDepartmentsRouteRoute: AppToolsDepartmentsRouteRouteWithChildren,
  AppToolsEmailsRouteRoute: AppToolsEmailsRouteRouteWithChildren,
  AppToolsExternalLinksRouteRoute: AppToolsExternalLinksRouteRouteWithChildren,
  AppToolsFormationsRouteRoute: AppToolsFormationsRouteRouteWithChildren,
  AppToolsGlobalTurnoverRouteRoute: AppToolsGlobalTurnoverRouteRoute,
  AppToolsMailsRouteRoute: AppToolsMailsRouteRouteWithChildren,
  AppToolsMenuRouteRoute: AppToolsMenuRouteRouteWithChildren,
  AppToolsNewsRouteRoute: AppToolsNewsRouteRouteWithChildren,
  AppToolsPredefinedMessagesRouteRoute:
    AppToolsPredefinedMessagesRouteRouteWithChildren,
  AppToolsPredefinedTextsRouteRoute:
    AppToolsPredefinedTextsRouteRouteWithChildren,
  AppToolsProductFiltersRouteRoute:
    AppToolsProductFiltersRouteRouteWithChildren,
  AppToolsProductInventoryRouteRoute:
    AppToolsProductInventoryRouteRouteWithChildren,
  AppToolsProductShelvesRouteRoute:
    AppToolsProductShelvesRouteRouteWithChildren,
  AppToolsRepresentativesMapRouteRoute: AppToolsRepresentativesMapRouteRoute,
  AppToolsRepresentativesTurnoverRouteRoute:
    AppToolsRepresentativesTurnoverRouteRoute,
  AppToolsSchedulerRouteRoute: AppToolsSchedulerRouteRouteWithChildren,
  AppToolsVvaRouteRoute: AppToolsVvaRouteRouteWithChildren,
  AppToolsIndexRoute: AppToolsIndexRoute,
}

const AppToolsRouteRouteWithChildren = AppToolsRouteRoute._addFileChildren(
  AppToolsRouteRouteChildren,
)

interface AppEnterprisesEnterpriseIdAddressBookRouteRouteChildren {
  AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute: typeof AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute
  AppEnterprisesEnterpriseIdAddressBookImportRouteRoute: typeof AppEnterprisesEnterpriseIdAddressBookImportRouteRoute
  AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute: typeof AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute
  AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute: typeof AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute
}

const AppEnterprisesEnterpriseIdAddressBookRouteRouteChildren: AppEnterprisesEnterpriseIdAddressBookRouteRouteChildren =
  {
    AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute:
      AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute,
    AppEnterprisesEnterpriseIdAddressBookImportRouteRoute:
      AppEnterprisesEnterpriseIdAddressBookImportRouteRoute,
    AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute:
      AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute,
    AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute:
      AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute,
  }

const AppEnterprisesEnterpriseIdAddressBookRouteRouteWithChildren =
  AppEnterprisesEnterpriseIdAddressBookRouteRoute._addFileChildren(
    AppEnterprisesEnterpriseIdAddressBookRouteRouteChildren,
  )

interface AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteChildren {
  AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute
}

const AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteChildren: AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteChildren =
  {
    AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute:
      AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute,
  }

const AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteWithChildren =
  AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRoute._addFileChildren(
    AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteChildren,
  )

interface AppEnterprisesEnterpriseIdEmailHistoryRouteRouteChildren {
  AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteWithChildren
}

const AppEnterprisesEnterpriseIdEmailHistoryRouteRouteChildren: AppEnterprisesEnterpriseIdEmailHistoryRouteRouteChildren =
  {
    AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRoute:
      AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteWithChildren,
  }

const AppEnterprisesEnterpriseIdEmailHistoryRouteRouteWithChildren =
  AppEnterprisesEnterpriseIdEmailHistoryRouteRoute._addFileChildren(
    AppEnterprisesEnterpriseIdEmailHistoryRouteRouteChildren,
  )

interface AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteChildren {
  AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute: typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute
}

const AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteChildren: AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteChildren =
  {
    AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute:
      AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute,
  }

const AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteWithChildren =
  AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRoute._addFileChildren(
    AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteChildren,
  )

interface AppEnterprisesEnterpriseIdRouteRouteChildren {
  AppEnterprisesEnterpriseIdAddressBookRouteRoute: typeof AppEnterprisesEnterpriseIdAddressBookRouteRouteWithChildren
  AppEnterprisesEnterpriseIdCreateContactRouteRoute: typeof AppEnterprisesEnterpriseIdCreateContactRouteRoute
  AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute: typeof AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute
  AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute: typeof AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute
  AppEnterprisesEnterpriseIdDeleteRouteRoute: typeof AppEnterprisesEnterpriseIdDeleteRouteRoute
  AppEnterprisesEnterpriseIdEmailHistoryRouteRoute: typeof AppEnterprisesEnterpriseIdEmailHistoryRouteRouteWithChildren
  AppEnterprisesEnterpriseIdImportContactsRouteRoute: typeof AppEnterprisesEnterpriseIdImportContactsRouteRoute
  AppEnterprisesEnterpriseIdImportGedFilesRouteRoute: typeof AppEnterprisesEnterpriseIdImportGedFilesRouteRoute
  AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute: typeof AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute
  AppEnterprisesEnterpriseIdUpdateRouteRoute: typeof AppEnterprisesEnterpriseIdUpdateRouteRoute
  AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute: typeof AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute
  AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute: typeof AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute
  AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute: typeof AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute
  AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute: typeof AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute
  AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute: typeof AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute
  AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute: typeof AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute
  AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute: typeof AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute
  AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute: typeof AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute
  AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute: typeof AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute
  AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRoute: typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteWithChildren
  AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute: typeof AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute
  AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute: typeof AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute
  AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute: typeof AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute
  AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute: typeof AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute
}

const AppEnterprisesEnterpriseIdRouteRouteChildren: AppEnterprisesEnterpriseIdRouteRouteChildren =
  {
    AppEnterprisesEnterpriseIdAddressBookRouteRoute:
      AppEnterprisesEnterpriseIdAddressBookRouteRouteWithChildren,
    AppEnterprisesEnterpriseIdCreateContactRouteRoute:
      AppEnterprisesEnterpriseIdCreateContactRouteRoute,
    AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute:
      AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute,
    AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute:
      AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute,
    AppEnterprisesEnterpriseIdDeleteRouteRoute:
      AppEnterprisesEnterpriseIdDeleteRouteRoute,
    AppEnterprisesEnterpriseIdEmailHistoryRouteRoute:
      AppEnterprisesEnterpriseIdEmailHistoryRouteRouteWithChildren,
    AppEnterprisesEnterpriseIdImportContactsRouteRoute:
      AppEnterprisesEnterpriseIdImportContactsRouteRoute,
    AppEnterprisesEnterpriseIdImportGedFilesRouteRoute:
      AppEnterprisesEnterpriseIdImportGedFilesRouteRoute,
    AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute:
      AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute,
    AppEnterprisesEnterpriseIdUpdateRouteRoute:
      AppEnterprisesEnterpriseIdUpdateRouteRoute,
    AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute:
      AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute,
    AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute:
      AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute,
    AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute:
      AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute,
    AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute:
      AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute,
    AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute:
      AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute,
    AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute:
      AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute,
    AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute:
      AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute,
    AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute:
      AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute,
    AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute:
      AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute,
    AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRoute:
      AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteWithChildren,
    AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute:
      AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute,
    AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute:
      AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute,
    AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute:
      AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute,
    AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute:
      AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute,
  }

const AppEnterprisesEnterpriseIdRouteRouteWithChildren =
  AppEnterprisesEnterpriseIdRouteRoute._addFileChildren(
    AppEnterprisesEnterpriseIdRouteRouteChildren,
  )

interface AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteChildren {
  AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute: typeof AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute
}

const AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteChildren: AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteChildren =
  {
    AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute:
      AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute,
  }

const AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteWithChildren =
  AppProductsProductIdInformationsTaskEmailTaskIdRouteRoute._addFileChildren(
    AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteChildren,
  )

interface AppProductsProductIdInformationsRouteRouteChildren {
  AppProductsProductIdInformationsCreateGedDirectoryRouteRoute: typeof AppProductsProductIdInformationsCreateGedDirectoryRouteRoute
  AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute: typeof AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute
  AppProductsProductIdInformationsDeleteGedObjectRouteRoute: typeof AppProductsProductIdInformationsDeleteGedObjectRouteRoute
  AppProductsProductIdInformationsImportGedFilesRouteRoute: typeof AppProductsProductIdInformationsImportGedFilesRouteRoute
  AppProductsProductIdInformationsRenameGedObjectRouteRoute: typeof AppProductsProductIdInformationsRenameGedObjectRouteRoute
  AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute: typeof AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute
  AppProductsProductIdInformationsTaskEmailTaskIdRouteRoute: typeof AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteWithChildren
  AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute: typeof AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute
}

const AppProductsProductIdInformationsRouteRouteChildren: AppProductsProductIdInformationsRouteRouteChildren =
  {
    AppProductsProductIdInformationsCreateGedDirectoryRouteRoute:
      AppProductsProductIdInformationsCreateGedDirectoryRouteRoute,
    AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute:
      AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute,
    AppProductsProductIdInformationsDeleteGedObjectRouteRoute:
      AppProductsProductIdInformationsDeleteGedObjectRouteRoute,
    AppProductsProductIdInformationsImportGedFilesRouteRoute:
      AppProductsProductIdInformationsImportGedFilesRouteRoute,
    AppProductsProductIdInformationsRenameGedObjectRouteRoute:
      AppProductsProductIdInformationsRenameGedObjectRouteRoute,
    AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute:
      AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute,
    AppProductsProductIdInformationsTaskEmailTaskIdRouteRoute:
      AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteWithChildren,
    AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute:
      AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute,
  }

const AppProductsProductIdInformationsRouteRouteWithChildren =
  AppProductsProductIdInformationsRouteRoute._addFileChildren(
    AppProductsProductIdInformationsRouteRouteChildren,
  )

interface AppProductsProductIdManageAddSpecificationRouteRouteChildren {
  AppProductsProductIdManageAddSpecificationFilterIdRouteRoute: typeof AppProductsProductIdManageAddSpecificationFilterIdRouteRoute
}

const AppProductsProductIdManageAddSpecificationRouteRouteChildren: AppProductsProductIdManageAddSpecificationRouteRouteChildren =
  {
    AppProductsProductIdManageAddSpecificationFilterIdRouteRoute:
      AppProductsProductIdManageAddSpecificationFilterIdRouteRoute,
  }

const AppProductsProductIdManageAddSpecificationRouteRouteWithChildren =
  AppProductsProductIdManageAddSpecificationRouteRoute._addFileChildren(
    AppProductsProductIdManageAddSpecificationRouteRouteChildren,
  )

interface AppProductsProductIdManageRouteRouteChildren {
  AppProductsProductIdManageAddAssociatedProductRouteRoute: typeof AppProductsProductIdManageAddAssociatedProductRouteRoute
  AppProductsProductIdManageAddNomenclatureDetailRouteRoute: typeof AppProductsProductIdManageAddNomenclatureDetailRouteRoute
  AppProductsProductIdManageAddSpecificationRouteRoute: typeof AppProductsProductIdManageAddSpecificationRouteRouteWithChildren
  AppProductsProductIdManageCreateStockRouteRoute: typeof AppProductsProductIdManageCreateStockRouteRoute
  AppProductsProductIdManageCreateVersionRouteRoute: typeof AppProductsProductIdManageCreateVersionRouteRoute
  AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute: typeof AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute
  AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute: typeof AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute
  AppProductsProductIdManageDeleteStockStockIdRouteRoute: typeof AppProductsProductIdManageDeleteStockStockIdRouteRoute
  AppProductsProductIdManageDeleteVersionVersionIdRouteRoute: typeof AppProductsProductIdManageDeleteVersionVersionIdRouteRoute
  AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute: typeof AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute
  AppProductsProductIdManageStockHistoryStockIdRouteRoute: typeof AppProductsProductIdManageStockHistoryStockIdRouteRoute
  AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute: typeof AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute
  AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute: typeof AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute
  AppProductsProductIdManageUpdateStockStockIdRouteRoute: typeof AppProductsProductIdManageUpdateStockStockIdRouteRoute
  AppProductsProductIdManageUpdateVersionVersionIdRouteRoute: typeof AppProductsProductIdManageUpdateVersionVersionIdRouteRoute
}

const AppProductsProductIdManageRouteRouteChildren: AppProductsProductIdManageRouteRouteChildren =
  {
    AppProductsProductIdManageAddAssociatedProductRouteRoute:
      AppProductsProductIdManageAddAssociatedProductRouteRoute,
    AppProductsProductIdManageAddNomenclatureDetailRouteRoute:
      AppProductsProductIdManageAddNomenclatureDetailRouteRoute,
    AppProductsProductIdManageAddSpecificationRouteRoute:
      AppProductsProductIdManageAddSpecificationRouteRouteWithChildren,
    AppProductsProductIdManageCreateStockRouteRoute:
      AppProductsProductIdManageCreateStockRouteRoute,
    AppProductsProductIdManageCreateVersionRouteRoute:
      AppProductsProductIdManageCreateVersionRouteRoute,
    AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute:
      AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute,
    AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute:
      AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute,
    AppProductsProductIdManageDeleteStockStockIdRouteRoute:
      AppProductsProductIdManageDeleteStockStockIdRouteRoute,
    AppProductsProductIdManageDeleteVersionVersionIdRouteRoute:
      AppProductsProductIdManageDeleteVersionVersionIdRouteRoute,
    AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute:
      AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute,
    AppProductsProductIdManageStockHistoryStockIdRouteRoute:
      AppProductsProductIdManageStockHistoryStockIdRouteRoute,
    AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute:
      AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute,
    AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute:
      AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute,
    AppProductsProductIdManageUpdateStockStockIdRouteRoute:
      AppProductsProductIdManageUpdateStockStockIdRouteRoute,
    AppProductsProductIdManageUpdateVersionVersionIdRouteRoute:
      AppProductsProductIdManageUpdateVersionVersionIdRouteRoute,
  }

const AppProductsProductIdManageRouteRouteWithChildren =
  AppProductsProductIdManageRouteRoute._addFileChildren(
    AppProductsProductIdManageRouteRouteChildren,
  )

interface AppProductsProductIdRouteRouteChildren {
  AppProductsProductIdInformationsRouteRoute: typeof AppProductsProductIdInformationsRouteRouteWithChildren
  AppProductsProductIdManageRouteRoute: typeof AppProductsProductIdManageRouteRouteWithChildren
  AppProductsProductIdIndexRoute: typeof AppProductsProductIdIndexRoute
}

const AppProductsProductIdRouteRouteChildren: AppProductsProductIdRouteRouteChildren =
  {
    AppProductsProductIdInformationsRouteRoute:
      AppProductsProductIdInformationsRouteRouteWithChildren,
    AppProductsProductIdManageRouteRoute:
      AppProductsProductIdManageRouteRouteWithChildren,
    AppProductsProductIdIndexRoute: AppProductsProductIdIndexRoute,
  }

const AppProductsProductIdRouteRouteWithChildren =
  AppProductsProductIdRouteRoute._addFileChildren(
    AppProductsProductIdRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdArcPdfRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdArcRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdArcPdfRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute
  AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdArcRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdArcRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdArcPdfRouteRoute:
      AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute:
      AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute,
    AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdArcRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdArcRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdArcRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdBillRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdBillRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdBillRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdBillRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdBillRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdBillRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdBlRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute
  AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute: typeof AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute
}

const AppBusinessesRmaBusinessBusinessIdBlRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdBlRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute:
      AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute,
  }

const AppBusinessesRmaBusinessBusinessIdBlRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdBlRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdBlRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdBpRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute
  AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdBpRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdBpRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdBpRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdBpRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdBpRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteWithChildren
}

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteWithChildren,
  }

const AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteWithChildren
}

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteWithChildren,
  }

const AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute
  AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute,
    AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdArcRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdArcRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdBillRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBillRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdBlRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBlRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdBpRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdBpRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdIndexRoute: typeof AppBusinessesRmaBusinessBusinessIdIndexRoute
}

const AppBusinessesRmaBusinessBusinessIdRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdArcRouteRoute:
      AppBusinessesRmaBusinessBusinessIdArcRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdBillRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBillRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdBlRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBlRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdBpRouteRoute:
      AppBusinessesRmaBusinessBusinessIdBpRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdDashboardRouteRoute:
      AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdQuotationRouteRoute:
      AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdIndexRoute:
      AppBusinessesRmaBusinessBusinessIdIndexRoute,
  }

const AppBusinessesRmaBusinessBusinessIdRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute
}

const AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteChildren: AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdDeliveryRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute
  AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute
  AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute
  AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute
}

const AppBusinessesRmaRmaRmaIdDeliveryRouteRouteChildren: AppBusinessesRmaRmaRmaIdDeliveryRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute,
    AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute,
    AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute,
    AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdDeliveryRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdDeliveryRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdDeliveryRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute: typeof AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute
}

const AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteChildren: AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute:
      AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdReceptionPdfRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdReceptionRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute: typeof AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute
  AppBusinessesRmaRmaRmaIdReceptionPdfRouteRoute: typeof AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute
  AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute
}

const AppBusinessesRmaRmaRmaIdReceptionRouteRouteChildren: AppBusinessesRmaRmaRmaIdReceptionRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute:
      AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute,
    AppBusinessesRmaRmaRmaIdReceptionPdfRouteRoute:
      AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute:
      AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute,
    AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute:
      AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdReceptionRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdReceptionRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdReceptionRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute
}

const AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteChildren: AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdSupportPdfRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute
}

const AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteChildren: AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdSupportRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute
  AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute
  AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute
  AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute
  AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute
  AppBusinessesRmaRmaRmaIdSupportPdfRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute
  AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute
  AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute
  AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute
  AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute
  AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute
  AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute
}

const AppBusinessesRmaRmaRmaIdSupportRouteRouteChildren: AppBusinessesRmaRmaRmaIdSupportRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportPdfRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute,
    AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute,
  }

const AppBusinessesRmaRmaRmaIdSupportRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdSupportRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdSupportRouteRouteChildren,
  )

interface AppBusinessesRmaRmaRmaIdRouteRouteChildren {
  AppBusinessesRmaRmaRmaIdDeliveryRouteRoute: typeof AppBusinessesRmaRmaRmaIdDeliveryRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdReceptionRouteRoute: typeof AppBusinessesRmaRmaRmaIdReceptionRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdSupportRouteRoute: typeof AppBusinessesRmaRmaRmaIdSupportRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdIndexRoute: typeof AppBusinessesRmaRmaRmaIdIndexRoute
}

const AppBusinessesRmaRmaRmaIdRouteRouteChildren: AppBusinessesRmaRmaRmaIdRouteRouteChildren =
  {
    AppBusinessesRmaRmaRmaIdDeliveryRouteRoute:
      AppBusinessesRmaRmaRmaIdDeliveryRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdReceptionRouteRoute:
      AppBusinessesRmaRmaRmaIdReceptionRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdSupportRouteRoute:
      AppBusinessesRmaRmaRmaIdSupportRouteRouteWithChildren,
    AppBusinessesRmaRmaRmaIdIndexRoute: AppBusinessesRmaRmaRmaIdIndexRoute,
  }

const AppBusinessesRmaRmaRmaIdRouteRouteWithChildren =
  AppBusinessesRmaRmaRmaIdRouteRoute._addFileChildren(
    AppBusinessesRmaRmaRmaIdRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdStudyRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute
  AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute
  AppBusinessesRmaBusinessBusinessIdStudyIndexRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyIndexRoute
}

const AppBusinessesRmaBusinessBusinessIdStudyRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdStudyRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute:
      AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute,
    AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute:
      AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute,
    AppBusinessesRmaBusinessBusinessIdStudyIndexRoute:
      AppBusinessesRmaBusinessBusinessIdStudyIndexRoute,
  }

const AppBusinessesRmaBusinessBusinessIdStudyRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdStudyRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdStudyRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteChildren,
  )

interface AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteChildren {
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute
}

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteChildren: AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteChildren =
  {
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteWithChildren,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute,
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute:
      AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute,
  }

const AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteWithChildren =
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute._addFileChildren(
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteChildren,
  )

interface AppRouteRouteChildren {
  AppBusinessesRmaRouteRoute: typeof AppBusinessesRmaRouteRouteWithChildren
  AppDashboardRouteRoute: typeof AppDashboardRouteRouteWithChildren
  AppEnterprisesRouteRoute: typeof AppEnterprisesRouteRouteWithChildren
  AppExternalLinksRouteRoute: typeof AppExternalLinksRouteRoute
  AppFaqRouteRoute: typeof AppFaqRouteRouteWithChildren
  AppProductsRouteRoute: typeof AppProductsRouteRouteWithChildren
  AppToolsRouteRoute: typeof AppToolsRouteRouteWithChildren
  AppIndexRoute: typeof AppIndexRoute
  AppEnterprisesEnterpriseIdRouteRoute: typeof AppEnterprisesEnterpriseIdRouteRouteWithChildren
  AppExternalLinksExternalLinkIdRouteRoute: typeof AppExternalLinksExternalLinkIdRouteRoute
  AppProductsProductIdRouteRoute: typeof AppProductsProductIdRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdRouteRouteWithChildren
  AppBusinessesRmaRmaRmaIdRouteRoute: typeof AppBusinessesRmaRmaRmaIdRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdStudyRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdStudyRouteRouteWithChildren
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute: typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteWithChildren
}

const AppRouteRouteChildren: AppRouteRouteChildren = {
  AppBusinessesRmaRouteRoute: AppBusinessesRmaRouteRouteWithChildren,
  AppDashboardRouteRoute: AppDashboardRouteRouteWithChildren,
  AppEnterprisesRouteRoute: AppEnterprisesRouteRouteWithChildren,
  AppExternalLinksRouteRoute: AppExternalLinksRouteRoute,
  AppFaqRouteRoute: AppFaqRouteRouteWithChildren,
  AppProductsRouteRoute: AppProductsRouteRouteWithChildren,
  AppToolsRouteRoute: AppToolsRouteRouteWithChildren,
  AppIndexRoute: AppIndexRoute,
  AppEnterprisesEnterpriseIdRouteRoute:
    AppEnterprisesEnterpriseIdRouteRouteWithChildren,
  AppExternalLinksExternalLinkIdRouteRoute:
    AppExternalLinksExternalLinkIdRouteRoute,
  AppProductsProductIdRouteRoute: AppProductsProductIdRouteRouteWithChildren,
  AppBusinessesRmaBusinessBusinessIdRouteRoute:
    AppBusinessesRmaBusinessBusinessIdRouteRouteWithChildren,
  AppBusinessesRmaRmaRmaIdRouteRoute:
    AppBusinessesRmaRmaRmaIdRouteRouteWithChildren,
  AppBusinessesRmaBusinessBusinessIdStudyRouteRoute:
    AppBusinessesRmaBusinessBusinessIdStudyRouteRouteWithChildren,
  AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRoute:
    AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteWithChildren,
}

const AppRouteRouteWithChildren = AppRouteRoute._addFileChildren(
  AppRouteRouteChildren,
)

interface AuthRouteRouteChildren {
  AuthLoginRouteRoute: typeof AuthLoginRouteRoute
  AuthForgotPasswordRouteLazyRoute: typeof AuthForgotPasswordRouteLazyRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthResetPasswordTokenRouteLazyRoute: typeof AuthResetPasswordTokenRouteLazyRoute
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthLoginRouteRoute: AuthLoginRouteRoute,
  AuthForgotPasswordRouteLazyRoute: AuthForgotPasswordRouteLazyRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthResetPasswordTokenRouteLazyRoute: AuthResetPasswordTokenRouteLazyRoute,
}

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/app': typeof AppRouteRouteWithChildren
  '/auth': typeof AuthRouteRouteWithChildren
  '/app/businesses-rma': typeof AppBusinessesRmaRouteRouteWithChildren
  '/app/dashboard': typeof AppDashboardRouteRouteWithChildren
  '/app/enterprises': typeof AppEnterprisesRouteRouteWithChildren
  '/app/external-links': typeof AppExternalLinksRouteRoute
  '/app/faq': typeof AppFaqRouteRouteWithChildren
  '/app/products': typeof AppProductsRouteRouteWithChildren
  '/app/tools': typeof AppToolsRouteRouteWithChildren
  '/auth/login': typeof AuthLoginRouteRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRouteLazyRoute
  '/app/': typeof AppIndexRoute
  '/auth/': typeof AuthIndexRoute
  '/app/businesses-rma/representative-turnover': typeof AppBusinessesRmaRepresentativeTurnoverRouteRoute
  '/app/businesses-rma/search-by-products': typeof AppBusinessesRmaSearchByProductsRouteRoute
  '/app/dashboard/create-collective-task': typeof AppDashboardCreateCollectiveTaskRouteRoute
  '/app/dashboard/create-personal-task': typeof AppDashboardCreatePersonalTaskRouteRoute
  '/app/dashboard/create-progressive-info': typeof AppDashboardCreateProgressiveInfoRouteRoute
  '/app/dashboard/delete-collective-tasks': typeof AppDashboardDeleteCollectiveTasksRouteRoute
  '/app/enterprises/create': typeof AppEnterprisesCreateRouteRoute
  '/app/enterprises/$enterpriseId': typeof AppEnterprisesEnterpriseIdRouteRouteWithChildren
  '/app/external-links/$externalLinkId': typeof AppExternalLinksExternalLinkIdRouteRoute
  '/app/faq/create': typeof AppFaqCreateRouteRoute
  '/app/products/create': typeof AppProductsCreateRouteRoute
  '/app/products/serial-numbers': typeof AppProductsSerialNumbersRouteRouteWithChildren
  '/app/products/$productId': typeof AppProductsProductIdRouteRouteWithChildren
  '/app/tools/credit': typeof AppToolsCreditRouteRouteWithChildren
  '/app/tools/ddns': typeof AppToolsDdnsRouteRouteWithChildren
  '/app/tools/departments': typeof AppToolsDepartmentsRouteRouteWithChildren
  '/app/tools/emails': typeof AppToolsEmailsRouteRouteWithChildren
  '/app/tools/external-links': typeof AppToolsExternalLinksRouteRouteWithChildren
  '/app/tools/formations': typeof AppToolsFormationsRouteRouteWithChildren
  '/app/tools/global-turnover': typeof AppToolsGlobalTurnoverRouteRoute
  '/app/tools/mails': typeof AppToolsMailsRouteRouteWithChildren
  '/app/tools/menu': typeof AppToolsMenuRouteRouteWithChildren
  '/app/tools/news': typeof AppToolsNewsRouteRouteWithChildren
  '/app/tools/predefined-messages': typeof AppToolsPredefinedMessagesRouteRouteWithChildren
  '/app/tools/predefined-texts': typeof AppToolsPredefinedTextsRouteRouteWithChildren
  '/app/tools/product-filters': typeof AppToolsProductFiltersRouteRouteWithChildren
  '/app/tools/product-inventory': typeof AppToolsProductInventoryRouteRouteWithChildren
  '/app/tools/product-shelves': typeof AppToolsProductShelvesRouteRouteWithChildren
  '/app/tools/representatives-map': typeof AppToolsRepresentativesMapRouteRoute
  '/app/tools/representatives-turnover': typeof AppToolsRepresentativesTurnoverRouteRoute
  '/app/tools/scheduler': typeof AppToolsSchedulerRouteRouteWithChildren
  '/app/tools/vva': typeof AppToolsVvaRouteRouteWithChildren
  '/auth/reset-password/$token': typeof AuthResetPasswordTokenRouteLazyRoute
  '/app/tools/': typeof AppToolsIndexRoute
  '/app/businesses-rma/business/$businessId': typeof AppBusinessesRmaBusinessBusinessIdRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId': typeof AppBusinessesRmaRmaRmaIdRouteRouteWithChildren
  '/app/dashboard/archive-personal-task/$taskId': typeof AppDashboardArchivePersonalTaskTaskIdRouteRoute
  '/app/dashboard/delete-collective-task/$taskId': typeof AppDashboardDeleteCollectiveTaskTaskIdRouteRoute
  '/app/dashboard/delete-progressive-info/$progressiveInfoId': typeof AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute
  '/app/dashboard/link-personal-task/$taskId': typeof AppDashboardLinkPersonalTaskTaskIdRouteRoute
  '/app/dashboard/other-personal-tasks/$profileId': typeof AppDashboardOtherPersonalTasksProfileIdRouteRoute
  '/app/dashboard/personal-task-details/$taskId': typeof AppDashboardPersonalTaskDetailsTaskIdRouteRoute
  '/app/dashboard/scheduler-event-details/$eventId': typeof AppDashboardSchedulerEventDetailsEventIdRouteRoute
  '/app/dashboard/take-collective-task/$taskId': typeof AppDashboardTakeCollectiveTaskTaskIdRouteRoute
  '/app/dashboard/task-comments/$taskId': typeof AppDashboardTaskCommentsTaskIdRouteRoute
  '/app/dashboard/task-email/$taskId': typeof AppDashboardTaskEmailTaskIdRouteRouteWithChildren
  '/app/dashboard/transfer-task/$taskId': typeof AppDashboardTransferTaskTaskIdRouteRoute
  '/app/dashboard/unlink-personal-task/$taskId': typeof AppDashboardUnlinkPersonalTaskTaskIdRouteRoute
  '/app/dashboard/update-personal-task-deadline/$taskId': typeof AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute
  '/app/dashboard/update-progressive-info/$progressiveInfoId': typeof AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute
  '/app/dashboard/validate-personal-task/$taskId': typeof AppDashboardValidatePersonalTaskTaskIdRouteRoute
  '/app/enterprises/create-contact-business/$contactId': typeof AppEnterprisesCreateContactBusinessContactIdRouteRoute
  '/app/enterprises/create-contact-travel-voucher/$contactId': typeof AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute
  '/app/enterprises/create-contact/$enterpriseId': typeof AppEnterprisesCreateContactEnterpriseIdRouteRoute
  '/app/enterprises/create-enterprise-rma/$enterpriseId': typeof AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute
  '/app/enterprises/delete-contact/$contactId': typeof AppEnterprisesDeleteContactContactIdRouteRoute
  '/app/enterprises/send-email-to-contact/$contactId': typeof AppEnterprisesSendEmailToContactContactIdRouteRoute
  '/app/enterprises/update-contact-password/$contactId': typeof AppEnterprisesUpdateContactPasswordContactIdRouteRoute
  '/app/enterprises/update-contact/$contactId': typeof AppEnterprisesUpdateContactContactIdRouteRoute
  '/app/enterprises/$enterpriseId/address-book': typeof AppEnterprisesEnterpriseIdAddressBookRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/create-contact': typeof AppEnterprisesEnterpriseIdCreateContactRouteRoute
  '/app/enterprises/$enterpriseId/create-ged-directory': typeof AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute
  '/app/enterprises/$enterpriseId/create-lifesheet-comment': typeof AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute
  '/app/enterprises/$enterpriseId/delete': typeof AppEnterprisesEnterpriseIdDeleteRouteRoute
  '/app/enterprises/$enterpriseId/email-history': typeof AppEnterprisesEnterpriseIdEmailHistoryRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/import-contacts': typeof AppEnterprisesEnterpriseIdImportContactsRouteRoute
  '/app/enterprises/$enterpriseId/import-ged-files': typeof AppEnterprisesEnterpriseIdImportGedFilesRouteRoute
  '/app/enterprises/$enterpriseId/relate-business-rma': typeof AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute
  '/app/enterprises/$enterpriseId/update': typeof AppEnterprisesEnterpriseIdUpdateRouteRoute
  '/app/enterprises/$enterpriseId/update-accountability': typeof AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute
  '/app/enterprises/$enterpriseId/update-category': typeof AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute
  '/app/enterprises/$enterpriseId/update-representative': typeof AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute
  '/app/faq/archive/$faqId': typeof AppFaqArchiveFaqIdRouteRoute
  '/app/faq/delete/$faqId': typeof AppFaqDeleteFaqIdRouteRoute
  '/app/faq/ged/$faqId': typeof AppFaqGedFaqIdRouteRouteWithChildren
  '/app/faq/send-by-email/$faqId': typeof AppFaqSendByEmailFaqIdRouteRoute
  '/app/faq/update/$faqId': typeof AppFaqUpdateFaqIdRouteRoute
  '/app/products/serial-numbers/create': typeof AppProductsSerialNumbersCreateRouteRoute
  '/app/products/$productId/informations': typeof AppProductsProductIdInformationsRouteRouteWithChildren
  '/app/products/$productId/manage': typeof AppProductsProductIdManageRouteRouteWithChildren
  '/app/tools/credit/details': typeof AppToolsCreditDetailsRouteRoute
  '/app/tools/credit/show': typeof AppToolsCreditShowRouteRoute
  '/app/tools/ddns/create': typeof AppToolsDdnsCreateRouteRoute
  '/app/tools/departments/create': typeof AppToolsDepartmentsCreateRouteRoute
  '/app/tools/emails/$emailId': typeof AppToolsEmailsEmailIdRouteRouteWithChildren
  '/app/tools/external-links/create': typeof AppToolsExternalLinksCreateRouteRoute
  '/app/tools/formations/create': typeof AppToolsFormationsCreateRouteRouteWithChildren
  '/app/tools/mails/create': typeof AppToolsMailsCreateRouteRoute
  '/app/tools/menu/create-enterprise': typeof AppToolsMenuCreateEnterpriseRouteRoute
  '/app/tools/menu/create-product': typeof AppToolsMenuCreateProductRouteRoute
  '/app/tools/news/create': typeof AppToolsNewsCreateRouteRoute
  '/app/tools/predefined-messages/create': typeof AppToolsPredefinedMessagesCreateRouteRoute
  '/app/tools/predefined-texts/create': typeof AppToolsPredefinedTextsCreateRouteRoute
  '/app/tools/product-filters/create': typeof AppToolsProductFiltersCreateRouteRoute
  '/app/tools/product-inventory/validate-quantities': typeof AppToolsProductInventoryValidateQuantitiesRouteRoute
  '/app/tools/product-shelves/create': typeof AppToolsProductShelvesCreateRouteRoute
  '/app/tools/scheduler/create': typeof AppToolsSchedulerCreateRouteRoute
  '/app/tools/vva/create': typeof AppToolsVvaCreateRouteRoute
  '/app/products/$productId/': typeof AppProductsProductIdIndexRoute
  '/app/businesses-rma/business/$businessId/arc': typeof AppBusinessesRmaBusinessBusinessIdArcRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bill': typeof AppBusinessesRmaBusinessBusinessIdBillRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bl': typeof AppBusinessesRmaBusinessBusinessIdBlRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bp': typeof AppBusinessesRmaBusinessBusinessIdBpRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard': typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation': typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/study': typeof AppBusinessesRmaBusinessBusinessIdStudyRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/delivery': typeof AppBusinessesRmaRmaRmaIdDeliveryRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/reception': typeof AppBusinessesRmaRmaRmaIdReceptionRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/support': typeof AppBusinessesRmaRmaRmaIdSupportRouteRouteWithChildren
  '/app/dashboard/task-email/$taskId/reply': typeof AppDashboardTaskEmailTaskIdReplyRouteRoute
  '/app/enterprises/$enterpriseId/address-book/create': typeof AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute
  '/app/enterprises/$enterpriseId/address-book/import': typeof AppEnterprisesEnterpriseIdAddressBookImportRouteRoute
  '/app/enterprises/$enterpriseId/create-contact-business/$contactId': typeof AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute
  '/app/enterprises/$enterpriseId/delete-contact/$contactId': typeof AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute
  '/app/enterprises/$enterpriseId/delete-ged-object/$objectRelativePath': typeof AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute
  '/app/enterprises/$enterpriseId/lifesheet-email/$lifesheetId': typeof AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute
  '/app/enterprises/$enterpriseId/rename-ged-object/$objectRelativePath': typeof AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute
  '/app/enterprises/$enterpriseId/send-email-to-contact/$contactId': typeof AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute
  '/app/enterprises/$enterpriseId/task-email/$taskId': typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/unlink-task/$taskId': typeof AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute
  '/app/enterprises/$enterpriseId/unrelate-business-rma/$businessRmaId': typeof AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute
  '/app/enterprises/$enterpriseId/update-contact-password/$contactId': typeof AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute
  '/app/enterprises/$enterpriseId/update-contact/$contactId': typeof AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute
  '/app/faq/ged/$faqId/create-directory': typeof AppFaqGedFaqIdCreateDirectoryRouteRoute
  '/app/faq/ged/$faqId/import-files': typeof AppFaqGedFaqIdImportFilesRouteRoute
  '/app/products/serial-numbers/create-rma/$serialNumberId': typeof AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute
  '/app/products/serial-numbers/delete/$serialNumberId': typeof AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute
  '/app/products/serial-numbers/remove-from-business/$serialNumberId': typeof AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute
  '/app/products/serial-numbers/update/$serialNumberId': typeof AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute
  '/app/products/$productId/informations/create-ged-directory': typeof AppProductsProductIdInformationsCreateGedDirectoryRouteRoute
  '/app/products/$productId/informations/create-lifesheet-comment': typeof AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute
  '/app/products/$productId/informations/delete-ged-object': typeof AppProductsProductIdInformationsDeleteGedObjectRouteRoute
  '/app/products/$productId/informations/import-ged-files': typeof AppProductsProductIdInformationsImportGedFilesRouteRoute
  '/app/products/$productId/informations/rename-ged-object': typeof AppProductsProductIdInformationsRenameGedObjectRouteRoute
  '/app/products/$productId/manage/add-associated-product': typeof AppProductsProductIdManageAddAssociatedProductRouteRoute
  '/app/products/$productId/manage/add-nomenclature-detail': typeof AppProductsProductIdManageAddNomenclatureDetailRouteRoute
  '/app/products/$productId/manage/add-specification': typeof AppProductsProductIdManageAddSpecificationRouteRouteWithChildren
  '/app/products/$productId/manage/create-stock': typeof AppProductsProductIdManageCreateStockRouteRoute
  '/app/products/$productId/manage/create-version': typeof AppProductsProductIdManageCreateVersionRouteRoute
  '/app/tools/ddns/delete/$ddnsId': typeof AppToolsDdnsDeleteDdnsIdRouteRoute
  '/app/tools/departments/delete/$departmentId': typeof AppToolsDepartmentsDeleteDepartmentIdRouteRoute
  '/app/tools/departments/update/$departmentId': typeof AppToolsDepartmentsUpdateDepartmentIdRouteRoute
  '/app/tools/emails/$emailId/reply': typeof AppToolsEmailsEmailIdReplyRouteRoute
  '/app/tools/external-links/archive/$externalLinkId': typeof AppToolsExternalLinksArchiveExternalLinkIdRouteRoute
  '/app/tools/external-links/delete/$externalLinkId': typeof AppToolsExternalLinksDeleteExternalLinkIdRouteRoute
  '/app/tools/external-links/update/$externalLinkId': typeof AppToolsExternalLinksUpdateExternalLinkIdRouteRoute
  '/app/tools/formations/create/add-detail': typeof AppToolsFormationsCreateAddDetailRouteRoute
  '/app/tools/formations/create/details': typeof AppToolsFormationsCreateDetailsRouteRoute
  '/app/tools/formations/delete/$formationId': typeof AppToolsFormationsDeleteFormationIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId': typeof AppToolsFormationsSubscribersFormationDetailIdRouteRouteWithChildren
  '/app/tools/formations/update/$formationId': typeof AppToolsFormationsUpdateFormationIdRouteRouteWithChildren
  '/app/tools/mails/delete/$mailId': typeof AppToolsMailsDeleteMailIdRouteRoute
  '/app/tools/mails/show/$mailId': typeof AppToolsMailsShowMailIdRouteRoute
  '/app/tools/mails/update/$mailId': typeof AppToolsMailsUpdateMailIdRouteRoute
  '/app/tools/news/delete/$newsId': typeof AppToolsNewsDeleteNewsIdRouteRoute
  '/app/tools/news/update/$newsId': typeof AppToolsNewsUpdateNewsIdRouteRoute
  '/app/tools/predefined-messages/delete/$predefinedMessageId': typeof AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute
  '/app/tools/predefined-messages/update/$predefinedMessageId': typeof AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute
  '/app/tools/predefined-texts/delete/$predefinedTextId': typeof AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute
  '/app/tools/predefined-texts/update/$predefinedTextId': typeof AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute
  '/app/tools/product-filters/delete/$productFilterId': typeof AppToolsProductFiltersDeleteProductFilterIdRouteRoute
  '/app/tools/product-filters/update/$productFilterId': typeof AppToolsProductFiltersUpdateProductFilterIdRouteRoute
  '/app/tools/product-inventory/update/$stockId': typeof AppToolsProductInventoryUpdateStockIdRouteRoute
  '/app/tools/product-shelves/delete/$productShelfId': typeof AppToolsProductShelvesDeleteProductShelfIdRouteRoute
  '/app/tools/scheduler/details/$rdvId': typeof AppToolsSchedulerDetailsRdvIdRouteRouteWithChildren
  '/app/tools/vva/delete/$vvaId': typeof AppToolsVvaDeleteVvaIdRouteRoute
  '/app/businesses-rma/business/$businessId/': typeof AppBusinessesRmaBusinessBusinessIdIndexRoute
  '/app/businesses-rma/rma/$rmaId/': typeof AppBusinessesRmaRmaRmaIdIndexRoute
  '/app/businesses-rma/business/$businessId/arc/pdf': typeof AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/arc/update-shipping-price': typeof AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute
  '/app/businesses-rma/business/$businessId/bill/credits': typeof AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bill/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/bl/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/bp/travel-voucher': typeof AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard/create-ged-directory': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/create-lifesheet': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/create-link': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/delete': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/email-history': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard/import-ged-files': typeof AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/send-email': typeof AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/update-billing-address': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/update-representative': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/update-responsible': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/commercial-notice': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation/pdf': typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation/update-shipping-price': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/study/automatic': typeof AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute
  '/app/businesses-rma/business/$businessId/study/expert': typeof AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/create-detail': typeof AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/pdf': typeof AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/delivery/travel-voucher': typeof AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/create-detail': typeof AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/pdf': typeof AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/support/create-detail': typeof AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/create-ged-directory': typeof AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/create-lifesheet': typeof AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/create-link': typeof AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/import-ged-files': typeof AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/pdf': typeof AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/address-book/delete/$addressId': typeof AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute
  '/app/enterprises/$enterpriseId/address-book/update/$addressId': typeof AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute
  '/app/enterprises/$enterpriseId/email-history/email/$emailId': typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/task-email/$taskId/reply': typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute
  '/app/faq/ged/$faqId/delete/$itemRelativePath': typeof AppFaqGedFaqIdDeleteItemRelativePathRouteRoute
  '/app/faq/ged/$faqId/rename/$itemRelativePath': typeof AppFaqGedFaqIdRenameItemRelativePathRouteRoute
  '/app/products/$productId/informations/lifesheet-email/$lifesheetId': typeof AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute
  '/app/products/$productId/informations/task-email/$taskId': typeof AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteWithChildren
  '/app/products/$productId/informations/unlink-task/$taskId': typeof AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute
  '/app/products/$productId/manage/add-specification/$filterId': typeof AppProductsProductIdManageAddSpecificationFilterIdRouteRoute
  '/app/products/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId': typeof AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute
  '/app/products/$productId/manage/delete-specification/$specificationId': typeof AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute
  '/app/products/$productId/manage/delete-stock/$stockId': typeof AppProductsProductIdManageDeleteStockStockIdRouteRoute
  '/app/products/$productId/manage/delete-version/$versionId': typeof AppProductsProductIdManageDeleteVersionVersionIdRouteRoute
  '/app/products/$productId/manage/remove-associated-product/$associatedProductId': typeof AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute
  '/app/products/$productId/manage/stock-history/$stockId': typeof AppProductsProductIdManageStockHistoryStockIdRouteRoute
  '/app/products/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId': typeof AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute
  '/app/products/$productId/manage/update-specification/$specificationId': typeof AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute
  '/app/products/$productId/manage/update-stock/$stockId': typeof AppProductsProductIdManageUpdateStockStockIdRouteRoute
  '/app/products/$productId/manage/update-version/$versionId': typeof AppProductsProductIdManageUpdateVersionVersionIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/create': typeof AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute
  '/app/tools/formations/update/$formationId/add-detail': typeof AppToolsFormationsUpdateFormationIdAddDetailRouteRoute
  '/app/tools/formations/update/$formationId/details': typeof AppToolsFormationsUpdateFormationIdDetailsRouteRoute
  '/app/tools/scheduler/details/$rdvId/delete': typeof AppToolsSchedulerDetailsRdvIdDeleteRouteRoute
  '/app/tools/scheduler/details/$rdvId/update': typeof AppToolsSchedulerDetailsRdvIdUpdateRouteRoute
  '/app/businesses-rma/business/$businessId/bl/update': typeof AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute
  '/app/businesses-rma/business/$businessId/study/': typeof AppBusinessesRmaBusinessBusinessIdStudyIndexRoute
  '/app/businesses-rma/business/$businessId/arc/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/arc/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/arc/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bill/credits/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/bp/add-serial/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/create-detail-rma/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/create-serial-rma/$serialId': typeof AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/delete-serial/$serialId': typeof AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book/create': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId': typeof AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/delete-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/delete-link/$associatedId': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/task-email/$taskId': typeof AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/unlink-task/$taskId': typeof AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation/create-associated-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/create-detail/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/delete-subquotation/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/update-subquotation/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-faq': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-ged-directory': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-lifesheet': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-link': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-cumulated-time': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-no-billed-time': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-subtitle': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/import-ged-files': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/delivery/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/delete-ged-object/$relativePath': typeof AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/delete-link/$associatedId': typeof AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId': typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/support/unlink-task/$taskId': typeof AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute
  '/app/enterprises/$enterpriseId/email-history/email/$emailId/reply': typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute
  '/app/products/$productId/informations/task-email/$taskId/reply': typeof AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId': typeof AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId': typeof AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book/delete/$addressId': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book/update/$addressId': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId/reply': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-link/$associatedId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId/reply': typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/app/businesses-rma': typeof AppBusinessesRmaRouteRouteWithChildren
  '/app/dashboard': typeof AppDashboardRouteRouteWithChildren
  '/app/enterprises': typeof AppEnterprisesRouteRouteWithChildren
  '/app/external-links': typeof AppExternalLinksRouteRoute
  '/app/faq': typeof AppFaqRouteRouteWithChildren
  '/app/products': typeof AppProductsRouteRouteWithChildren
  '/auth/login': typeof AuthLoginRouteRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRouteLazyRoute
  '/app': typeof AppIndexRoute
  '/auth': typeof AuthIndexRoute
  '/app/businesses-rma/representative-turnover': typeof AppBusinessesRmaRepresentativeTurnoverRouteRoute
  '/app/businesses-rma/search-by-products': typeof AppBusinessesRmaSearchByProductsRouteRoute
  '/app/dashboard/create-collective-task': typeof AppDashboardCreateCollectiveTaskRouteRoute
  '/app/dashboard/create-personal-task': typeof AppDashboardCreatePersonalTaskRouteRoute
  '/app/dashboard/create-progressive-info': typeof AppDashboardCreateProgressiveInfoRouteRoute
  '/app/dashboard/delete-collective-tasks': typeof AppDashboardDeleteCollectiveTasksRouteRoute
  '/app/enterprises/create': typeof AppEnterprisesCreateRouteRoute
  '/app/enterprises/$enterpriseId': typeof AppEnterprisesEnterpriseIdRouteRouteWithChildren
  '/app/external-links/$externalLinkId': typeof AppExternalLinksExternalLinkIdRouteRoute
  '/app/faq/create': typeof AppFaqCreateRouteRoute
  '/app/products/create': typeof AppProductsCreateRouteRoute
  '/app/products/serial-numbers': typeof AppProductsSerialNumbersRouteRouteWithChildren
  '/app/tools/credit': typeof AppToolsCreditRouteRouteWithChildren
  '/app/tools/ddns': typeof AppToolsDdnsRouteRouteWithChildren
  '/app/tools/departments': typeof AppToolsDepartmentsRouteRouteWithChildren
  '/app/tools/emails': typeof AppToolsEmailsRouteRouteWithChildren
  '/app/tools/external-links': typeof AppToolsExternalLinksRouteRouteWithChildren
  '/app/tools/formations': typeof AppToolsFormationsRouteRouteWithChildren
  '/app/tools/global-turnover': typeof AppToolsGlobalTurnoverRouteRoute
  '/app/tools/mails': typeof AppToolsMailsRouteRouteWithChildren
  '/app/tools/menu': typeof AppToolsMenuRouteRouteWithChildren
  '/app/tools/news': typeof AppToolsNewsRouteRouteWithChildren
  '/app/tools/predefined-messages': typeof AppToolsPredefinedMessagesRouteRouteWithChildren
  '/app/tools/predefined-texts': typeof AppToolsPredefinedTextsRouteRouteWithChildren
  '/app/tools/product-filters': typeof AppToolsProductFiltersRouteRouteWithChildren
  '/app/tools/product-inventory': typeof AppToolsProductInventoryRouteRouteWithChildren
  '/app/tools/product-shelves': typeof AppToolsProductShelvesRouteRouteWithChildren
  '/app/tools/representatives-map': typeof AppToolsRepresentativesMapRouteRoute
  '/app/tools/representatives-turnover': typeof AppToolsRepresentativesTurnoverRouteRoute
  '/app/tools/scheduler': typeof AppToolsSchedulerRouteRouteWithChildren
  '/app/tools/vva': typeof AppToolsVvaRouteRouteWithChildren
  '/auth/reset-password/$token': typeof AuthResetPasswordTokenRouteLazyRoute
  '/app/tools': typeof AppToolsIndexRoute
  '/app/dashboard/archive-personal-task/$taskId': typeof AppDashboardArchivePersonalTaskTaskIdRouteRoute
  '/app/dashboard/delete-collective-task/$taskId': typeof AppDashboardDeleteCollectiveTaskTaskIdRouteRoute
  '/app/dashboard/delete-progressive-info/$progressiveInfoId': typeof AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute
  '/app/dashboard/link-personal-task/$taskId': typeof AppDashboardLinkPersonalTaskTaskIdRouteRoute
  '/app/dashboard/other-personal-tasks/$profileId': typeof AppDashboardOtherPersonalTasksProfileIdRouteRoute
  '/app/dashboard/personal-task-details/$taskId': typeof AppDashboardPersonalTaskDetailsTaskIdRouteRoute
  '/app/dashboard/scheduler-event-details/$eventId': typeof AppDashboardSchedulerEventDetailsEventIdRouteRoute
  '/app/dashboard/take-collective-task/$taskId': typeof AppDashboardTakeCollectiveTaskTaskIdRouteRoute
  '/app/dashboard/task-comments/$taskId': typeof AppDashboardTaskCommentsTaskIdRouteRoute
  '/app/dashboard/task-email/$taskId': typeof AppDashboardTaskEmailTaskIdRouteRouteWithChildren
  '/app/dashboard/transfer-task/$taskId': typeof AppDashboardTransferTaskTaskIdRouteRoute
  '/app/dashboard/unlink-personal-task/$taskId': typeof AppDashboardUnlinkPersonalTaskTaskIdRouteRoute
  '/app/dashboard/update-personal-task-deadline/$taskId': typeof AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute
  '/app/dashboard/update-progressive-info/$progressiveInfoId': typeof AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute
  '/app/dashboard/validate-personal-task/$taskId': typeof AppDashboardValidatePersonalTaskTaskIdRouteRoute
  '/app/enterprises/create-contact-business/$contactId': typeof AppEnterprisesCreateContactBusinessContactIdRouteRoute
  '/app/enterprises/create-contact-travel-voucher/$contactId': typeof AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute
  '/app/enterprises/create-contact/$enterpriseId': typeof AppEnterprisesCreateContactEnterpriseIdRouteRoute
  '/app/enterprises/create-enterprise-rma/$enterpriseId': typeof AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute
  '/app/enterprises/delete-contact/$contactId': typeof AppEnterprisesDeleteContactContactIdRouteRoute
  '/app/enterprises/send-email-to-contact/$contactId': typeof AppEnterprisesSendEmailToContactContactIdRouteRoute
  '/app/enterprises/update-contact-password/$contactId': typeof AppEnterprisesUpdateContactPasswordContactIdRouteRoute
  '/app/enterprises/update-contact/$contactId': typeof AppEnterprisesUpdateContactContactIdRouteRoute
  '/app/enterprises/$enterpriseId/address-book': typeof AppEnterprisesEnterpriseIdAddressBookRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/create-contact': typeof AppEnterprisesEnterpriseIdCreateContactRouteRoute
  '/app/enterprises/$enterpriseId/create-ged-directory': typeof AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute
  '/app/enterprises/$enterpriseId/create-lifesheet-comment': typeof AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute
  '/app/enterprises/$enterpriseId/delete': typeof AppEnterprisesEnterpriseIdDeleteRouteRoute
  '/app/enterprises/$enterpriseId/email-history': typeof AppEnterprisesEnterpriseIdEmailHistoryRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/import-contacts': typeof AppEnterprisesEnterpriseIdImportContactsRouteRoute
  '/app/enterprises/$enterpriseId/import-ged-files': typeof AppEnterprisesEnterpriseIdImportGedFilesRouteRoute
  '/app/enterprises/$enterpriseId/relate-business-rma': typeof AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute
  '/app/enterprises/$enterpriseId/update': typeof AppEnterprisesEnterpriseIdUpdateRouteRoute
  '/app/enterprises/$enterpriseId/update-accountability': typeof AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute
  '/app/enterprises/$enterpriseId/update-category': typeof AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute
  '/app/enterprises/$enterpriseId/update-representative': typeof AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute
  '/app/faq/archive/$faqId': typeof AppFaqArchiveFaqIdRouteRoute
  '/app/faq/delete/$faqId': typeof AppFaqDeleteFaqIdRouteRoute
  '/app/faq/ged/$faqId': typeof AppFaqGedFaqIdRouteRouteWithChildren
  '/app/faq/send-by-email/$faqId': typeof AppFaqSendByEmailFaqIdRouteRoute
  '/app/faq/update/$faqId': typeof AppFaqUpdateFaqIdRouteRoute
  '/app/products/serial-numbers/create': typeof AppProductsSerialNumbersCreateRouteRoute
  '/app/products/$productId/informations': typeof AppProductsProductIdInformationsRouteRouteWithChildren
  '/app/products/$productId/manage': typeof AppProductsProductIdManageRouteRouteWithChildren
  '/app/tools/credit/details': typeof AppToolsCreditDetailsRouteRoute
  '/app/tools/credit/show': typeof AppToolsCreditShowRouteRoute
  '/app/tools/ddns/create': typeof AppToolsDdnsCreateRouteRoute
  '/app/tools/departments/create': typeof AppToolsDepartmentsCreateRouteRoute
  '/app/tools/emails/$emailId': typeof AppToolsEmailsEmailIdRouteRouteWithChildren
  '/app/tools/external-links/create': typeof AppToolsExternalLinksCreateRouteRoute
  '/app/tools/formations/create': typeof AppToolsFormationsCreateRouteRouteWithChildren
  '/app/tools/mails/create': typeof AppToolsMailsCreateRouteRoute
  '/app/tools/menu/create-enterprise': typeof AppToolsMenuCreateEnterpriseRouteRoute
  '/app/tools/menu/create-product': typeof AppToolsMenuCreateProductRouteRoute
  '/app/tools/news/create': typeof AppToolsNewsCreateRouteRoute
  '/app/tools/predefined-messages/create': typeof AppToolsPredefinedMessagesCreateRouteRoute
  '/app/tools/predefined-texts/create': typeof AppToolsPredefinedTextsCreateRouteRoute
  '/app/tools/product-filters/create': typeof AppToolsProductFiltersCreateRouteRoute
  '/app/tools/product-inventory/validate-quantities': typeof AppToolsProductInventoryValidateQuantitiesRouteRoute
  '/app/tools/product-shelves/create': typeof AppToolsProductShelvesCreateRouteRoute
  '/app/tools/scheduler/create': typeof AppToolsSchedulerCreateRouteRoute
  '/app/tools/vva/create': typeof AppToolsVvaCreateRouteRoute
  '/app/products/$productId': typeof AppProductsProductIdIndexRoute
  '/app/businesses-rma/business/$businessId/arc': typeof AppBusinessesRmaBusinessBusinessIdArcRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bill': typeof AppBusinessesRmaBusinessBusinessIdBillRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bl': typeof AppBusinessesRmaBusinessBusinessIdBlRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bp': typeof AppBusinessesRmaBusinessBusinessIdBpRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard': typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation': typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/delivery': typeof AppBusinessesRmaRmaRmaIdDeliveryRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/reception': typeof AppBusinessesRmaRmaRmaIdReceptionRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/support': typeof AppBusinessesRmaRmaRmaIdSupportRouteRouteWithChildren
  '/app/dashboard/task-email/$taskId/reply': typeof AppDashboardTaskEmailTaskIdReplyRouteRoute
  '/app/enterprises/$enterpriseId/address-book/create': typeof AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute
  '/app/enterprises/$enterpriseId/address-book/import': typeof AppEnterprisesEnterpriseIdAddressBookImportRouteRoute
  '/app/enterprises/$enterpriseId/create-contact-business/$contactId': typeof AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute
  '/app/enterprises/$enterpriseId/delete-contact/$contactId': typeof AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute
  '/app/enterprises/$enterpriseId/delete-ged-object/$objectRelativePath': typeof AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute
  '/app/enterprises/$enterpriseId/lifesheet-email/$lifesheetId': typeof AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute
  '/app/enterprises/$enterpriseId/rename-ged-object/$objectRelativePath': typeof AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute
  '/app/enterprises/$enterpriseId/send-email-to-contact/$contactId': typeof AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute
  '/app/enterprises/$enterpriseId/task-email/$taskId': typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/unlink-task/$taskId': typeof AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute
  '/app/enterprises/$enterpriseId/unrelate-business-rma/$businessRmaId': typeof AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute
  '/app/enterprises/$enterpriseId/update-contact-password/$contactId': typeof AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute
  '/app/enterprises/$enterpriseId/update-contact/$contactId': typeof AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute
  '/app/faq/ged/$faqId/create-directory': typeof AppFaqGedFaqIdCreateDirectoryRouteRoute
  '/app/faq/ged/$faqId/import-files': typeof AppFaqGedFaqIdImportFilesRouteRoute
  '/app/products/serial-numbers/create-rma/$serialNumberId': typeof AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute
  '/app/products/serial-numbers/delete/$serialNumberId': typeof AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute
  '/app/products/serial-numbers/remove-from-business/$serialNumberId': typeof AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute
  '/app/products/serial-numbers/update/$serialNumberId': typeof AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute
  '/app/products/$productId/informations/create-ged-directory': typeof AppProductsProductIdInformationsCreateGedDirectoryRouteRoute
  '/app/products/$productId/informations/create-lifesheet-comment': typeof AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute
  '/app/products/$productId/informations/delete-ged-object': typeof AppProductsProductIdInformationsDeleteGedObjectRouteRoute
  '/app/products/$productId/informations/import-ged-files': typeof AppProductsProductIdInformationsImportGedFilesRouteRoute
  '/app/products/$productId/informations/rename-ged-object': typeof AppProductsProductIdInformationsRenameGedObjectRouteRoute
  '/app/products/$productId/manage/add-associated-product': typeof AppProductsProductIdManageAddAssociatedProductRouteRoute
  '/app/products/$productId/manage/add-nomenclature-detail': typeof AppProductsProductIdManageAddNomenclatureDetailRouteRoute
  '/app/products/$productId/manage/add-specification': typeof AppProductsProductIdManageAddSpecificationRouteRouteWithChildren
  '/app/products/$productId/manage/create-stock': typeof AppProductsProductIdManageCreateStockRouteRoute
  '/app/products/$productId/manage/create-version': typeof AppProductsProductIdManageCreateVersionRouteRoute
  '/app/tools/ddns/delete/$ddnsId': typeof AppToolsDdnsDeleteDdnsIdRouteRoute
  '/app/tools/departments/delete/$departmentId': typeof AppToolsDepartmentsDeleteDepartmentIdRouteRoute
  '/app/tools/departments/update/$departmentId': typeof AppToolsDepartmentsUpdateDepartmentIdRouteRoute
  '/app/tools/emails/$emailId/reply': typeof AppToolsEmailsEmailIdReplyRouteRoute
  '/app/tools/external-links/archive/$externalLinkId': typeof AppToolsExternalLinksArchiveExternalLinkIdRouteRoute
  '/app/tools/external-links/delete/$externalLinkId': typeof AppToolsExternalLinksDeleteExternalLinkIdRouteRoute
  '/app/tools/external-links/update/$externalLinkId': typeof AppToolsExternalLinksUpdateExternalLinkIdRouteRoute
  '/app/tools/formations/create/add-detail': typeof AppToolsFormationsCreateAddDetailRouteRoute
  '/app/tools/formations/create/details': typeof AppToolsFormationsCreateDetailsRouteRoute
  '/app/tools/formations/delete/$formationId': typeof AppToolsFormationsDeleteFormationIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId': typeof AppToolsFormationsSubscribersFormationDetailIdRouteRouteWithChildren
  '/app/tools/formations/update/$formationId': typeof AppToolsFormationsUpdateFormationIdRouteRouteWithChildren
  '/app/tools/mails/delete/$mailId': typeof AppToolsMailsDeleteMailIdRouteRoute
  '/app/tools/mails/show/$mailId': typeof AppToolsMailsShowMailIdRouteRoute
  '/app/tools/mails/update/$mailId': typeof AppToolsMailsUpdateMailIdRouteRoute
  '/app/tools/news/delete/$newsId': typeof AppToolsNewsDeleteNewsIdRouteRoute
  '/app/tools/news/update/$newsId': typeof AppToolsNewsUpdateNewsIdRouteRoute
  '/app/tools/predefined-messages/delete/$predefinedMessageId': typeof AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute
  '/app/tools/predefined-messages/update/$predefinedMessageId': typeof AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute
  '/app/tools/predefined-texts/delete/$predefinedTextId': typeof AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute
  '/app/tools/predefined-texts/update/$predefinedTextId': typeof AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute
  '/app/tools/product-filters/delete/$productFilterId': typeof AppToolsProductFiltersDeleteProductFilterIdRouteRoute
  '/app/tools/product-filters/update/$productFilterId': typeof AppToolsProductFiltersUpdateProductFilterIdRouteRoute
  '/app/tools/product-inventory/update/$stockId': typeof AppToolsProductInventoryUpdateStockIdRouteRoute
  '/app/tools/product-shelves/delete/$productShelfId': typeof AppToolsProductShelvesDeleteProductShelfIdRouteRoute
  '/app/tools/scheduler/details/$rdvId': typeof AppToolsSchedulerDetailsRdvIdRouteRouteWithChildren
  '/app/tools/vva/delete/$vvaId': typeof AppToolsVvaDeleteVvaIdRouteRoute
  '/app/businesses-rma/business/$businessId': typeof AppBusinessesRmaBusinessBusinessIdIndexRoute
  '/app/businesses-rma/rma/$rmaId': typeof AppBusinessesRmaRmaRmaIdIndexRoute
  '/app/businesses-rma/business/$businessId/arc/pdf': typeof AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/arc/update-shipping-price': typeof AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute
  '/app/businesses-rma/business/$businessId/bill/credits': typeof AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/bill/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/bl/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/bp/travel-voucher': typeof AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard/create-ged-directory': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/create-lifesheet': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/create-link': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/delete': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/email-history': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard/import-ged-files': typeof AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/send-email': typeof AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/update-billing-address': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/update-representative': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/update-responsible': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/commercial-notice': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation/pdf': typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation/update-shipping-price': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/study/automatic': typeof AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute
  '/app/businesses-rma/business/$businessId/study/expert': typeof AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/create-detail': typeof AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/pdf': typeof AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/delivery/travel-voucher': typeof AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/create-detail': typeof AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/pdf': typeof AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/support/create-detail': typeof AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/create-ged-directory': typeof AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/create-lifesheet': typeof AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/create-link': typeof AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/import-ged-files': typeof AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/pdf': typeof AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/address-book/delete/$addressId': typeof AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute
  '/app/enterprises/$enterpriseId/address-book/update/$addressId': typeof AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute
  '/app/enterprises/$enterpriseId/email-history/email/$emailId': typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteWithChildren
  '/app/enterprises/$enterpriseId/task-email/$taskId/reply': typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute
  '/app/faq/ged/$faqId/delete/$itemRelativePath': typeof AppFaqGedFaqIdDeleteItemRelativePathRouteRoute
  '/app/faq/ged/$faqId/rename/$itemRelativePath': typeof AppFaqGedFaqIdRenameItemRelativePathRouteRoute
  '/app/products/$productId/informations/lifesheet-email/$lifesheetId': typeof AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute
  '/app/products/$productId/informations/task-email/$taskId': typeof AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteWithChildren
  '/app/products/$productId/informations/unlink-task/$taskId': typeof AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute
  '/app/products/$productId/manage/add-specification/$filterId': typeof AppProductsProductIdManageAddSpecificationFilterIdRouteRoute
  '/app/products/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId': typeof AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute
  '/app/products/$productId/manage/delete-specification/$specificationId': typeof AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute
  '/app/products/$productId/manage/delete-stock/$stockId': typeof AppProductsProductIdManageDeleteStockStockIdRouteRoute
  '/app/products/$productId/manage/delete-version/$versionId': typeof AppProductsProductIdManageDeleteVersionVersionIdRouteRoute
  '/app/products/$productId/manage/remove-associated-product/$associatedProductId': typeof AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute
  '/app/products/$productId/manage/stock-history/$stockId': typeof AppProductsProductIdManageStockHistoryStockIdRouteRoute
  '/app/products/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId': typeof AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute
  '/app/products/$productId/manage/update-specification/$specificationId': typeof AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute
  '/app/products/$productId/manage/update-stock/$stockId': typeof AppProductsProductIdManageUpdateStockStockIdRouteRoute
  '/app/products/$productId/manage/update-version/$versionId': typeof AppProductsProductIdManageUpdateVersionVersionIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/create': typeof AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute
  '/app/tools/formations/update/$formationId/add-detail': typeof AppToolsFormationsUpdateFormationIdAddDetailRouteRoute
  '/app/tools/formations/update/$formationId/details': typeof AppToolsFormationsUpdateFormationIdDetailsRouteRoute
  '/app/tools/scheduler/details/$rdvId/delete': typeof AppToolsSchedulerDetailsRdvIdDeleteRouteRoute
  '/app/tools/scheduler/details/$rdvId/update': typeof AppToolsSchedulerDetailsRdvIdUpdateRouteRoute
  '/app/businesses-rma/business/$businessId/bl/update': typeof AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute
  '/app/businesses-rma/business/$businessId/study': typeof AppBusinessesRmaBusinessBusinessIdStudyIndexRoute
  '/app/businesses-rma/business/$businessId/arc/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/arc/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/arc/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bill/credits/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/bp/add-serial/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/create-detail-rma/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/create-serial-rma/$serialId': typeof AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/delete-serial/$serialId': typeof AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute
  '/app/businesses-rma/business/$businessId/bp/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book/create': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId': typeof AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/delete-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/delete-link/$associatedId': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/dashboard/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/task-email/$taskId': typeof AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/unlink-task/$taskId': typeof AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteWithChildren
  '/app/businesses-rma/business/$businessId/quotation/create-associated-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/create-detail/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/delete-subquotation/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/update-subquotation/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-faq': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-ged-directory': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-lifesheet': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-link': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-cumulated-time': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-no-billed-time': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-subtitle': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/import-ged-files': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/delivery/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute
  '/app/businesses-rma/rma/$rmaId/delivery/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute
  '/app/businesses-rma/rma/$rmaId/reception/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/delete-ged-object/$relativePath': typeof AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/delete-link/$associatedId': typeof AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId': typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteWithChildren
  '/app/businesses-rma/rma/$rmaId/support/unlink-task/$taskId': typeof AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute
  '/app/enterprises/$enterpriseId/email-history/email/$emailId/reply': typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute
  '/app/products/$productId/informations/task-email/$taskId/reply': typeof AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId': typeof AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId': typeof AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book/delete/$addressId': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/address-book/update/$addressId': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute
  '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId/reply': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute
  '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-link/$associatedId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute
  '/app/businesses-rma/business/$businessId/assistance/$assistanceId/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId/reply': typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/app': typeof AppRouteRouteWithChildren
  '/auth': typeof AuthRouteRouteWithChildren
  '/app/businesses-rma': typeof AppBusinessesRmaRouteRouteWithChildren
  '/app/dashboard': typeof AppDashboardRouteRouteWithChildren
  '/app/enterprises': typeof AppEnterprisesRouteRouteWithChildren
  '/app/external-links': typeof AppExternalLinksRouteRoute
  '/app/faq': typeof AppFaqRouteRouteWithChildren
  '/app/products': typeof AppProductsRouteRouteWithChildren
  '/app/tools': typeof AppToolsRouteRouteWithChildren
  '/auth/login': typeof AuthLoginRouteRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRouteLazyRoute
  '/app/': typeof AppIndexRoute
  '/auth/': typeof AuthIndexRoute
  '/app/businesses-rma/representative-turnover': typeof AppBusinessesRmaRepresentativeTurnoverRouteRoute
  '/app/businesses-rma/search-by-products': typeof AppBusinessesRmaSearchByProductsRouteRoute
  '/app/dashboard/create-collective-task': typeof AppDashboardCreateCollectiveTaskRouteRoute
  '/app/dashboard/create-personal-task': typeof AppDashboardCreatePersonalTaskRouteRoute
  '/app/dashboard/create-progressive-info': typeof AppDashboardCreateProgressiveInfoRouteRoute
  '/app/dashboard/delete-collective-tasks': typeof AppDashboardDeleteCollectiveTasksRouteRoute
  '/app/enterprises/create': typeof AppEnterprisesCreateRouteRoute
  '/app/enterprises_/$enterpriseId': typeof AppEnterprisesEnterpriseIdRouteRouteWithChildren
  '/app/external-links_/$externalLinkId': typeof AppExternalLinksExternalLinkIdRouteRoute
  '/app/faq/create': typeof AppFaqCreateRouteRoute
  '/app/products/create': typeof AppProductsCreateRouteRoute
  '/app/products/serial-numbers': typeof AppProductsSerialNumbersRouteRouteWithChildren
  '/app/products_/$productId': typeof AppProductsProductIdRouteRouteWithChildren
  '/app/tools/credit': typeof AppToolsCreditRouteRouteWithChildren
  '/app/tools/ddns': typeof AppToolsDdnsRouteRouteWithChildren
  '/app/tools/departments': typeof AppToolsDepartmentsRouteRouteWithChildren
  '/app/tools/emails': typeof AppToolsEmailsRouteRouteWithChildren
  '/app/tools/external-links': typeof AppToolsExternalLinksRouteRouteWithChildren
  '/app/tools/formations': typeof AppToolsFormationsRouteRouteWithChildren
  '/app/tools/global-turnover': typeof AppToolsGlobalTurnoverRouteRoute
  '/app/tools/mails': typeof AppToolsMailsRouteRouteWithChildren
  '/app/tools/menu': typeof AppToolsMenuRouteRouteWithChildren
  '/app/tools/news': typeof AppToolsNewsRouteRouteWithChildren
  '/app/tools/predefined-messages': typeof AppToolsPredefinedMessagesRouteRouteWithChildren
  '/app/tools/predefined-texts': typeof AppToolsPredefinedTextsRouteRouteWithChildren
  '/app/tools/product-filters': typeof AppToolsProductFiltersRouteRouteWithChildren
  '/app/tools/product-inventory': typeof AppToolsProductInventoryRouteRouteWithChildren
  '/app/tools/product-shelves': typeof AppToolsProductShelvesRouteRouteWithChildren
  '/app/tools/representatives-map': typeof AppToolsRepresentativesMapRouteRoute
  '/app/tools/representatives-turnover': typeof AppToolsRepresentativesTurnoverRouteRoute
  '/app/tools/scheduler': typeof AppToolsSchedulerRouteRouteWithChildren
  '/app/tools/vva': typeof AppToolsVvaRouteRouteWithChildren
  '/auth/reset-password/$token': typeof AuthResetPasswordTokenRouteLazyRoute
  '/app/tools/': typeof AppToolsIndexRoute
  '/app/businesses-rma_/business/$businessId': typeof AppBusinessesRmaBusinessBusinessIdRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId': typeof AppBusinessesRmaRmaRmaIdRouteRouteWithChildren
  '/app/dashboard/archive-personal-task/$taskId': typeof AppDashboardArchivePersonalTaskTaskIdRouteRoute
  '/app/dashboard/delete-collective-task/$taskId': typeof AppDashboardDeleteCollectiveTaskTaskIdRouteRoute
  '/app/dashboard/delete-progressive-info/$progressiveInfoId': typeof AppDashboardDeleteProgressiveInfoProgressiveInfoIdRouteRoute
  '/app/dashboard/link-personal-task/$taskId': typeof AppDashboardLinkPersonalTaskTaskIdRouteRoute
  '/app/dashboard/other-personal-tasks/$profileId': typeof AppDashboardOtherPersonalTasksProfileIdRouteRoute
  '/app/dashboard/personal-task-details/$taskId': typeof AppDashboardPersonalTaskDetailsTaskIdRouteRoute
  '/app/dashboard/scheduler-event-details/$eventId': typeof AppDashboardSchedulerEventDetailsEventIdRouteRoute
  '/app/dashboard/take-collective-task/$taskId': typeof AppDashboardTakeCollectiveTaskTaskIdRouteRoute
  '/app/dashboard/task-comments/$taskId': typeof AppDashboardTaskCommentsTaskIdRouteRoute
  '/app/dashboard/task-email/$taskId': typeof AppDashboardTaskEmailTaskIdRouteRouteWithChildren
  '/app/dashboard/transfer-task/$taskId': typeof AppDashboardTransferTaskTaskIdRouteRoute
  '/app/dashboard/unlink-personal-task/$taskId': typeof AppDashboardUnlinkPersonalTaskTaskIdRouteRoute
  '/app/dashboard/update-personal-task-deadline/$taskId': typeof AppDashboardUpdatePersonalTaskDeadlineTaskIdRouteRoute
  '/app/dashboard/update-progressive-info/$progressiveInfoId': typeof AppDashboardUpdateProgressiveInfoProgressiveInfoIdRouteRoute
  '/app/dashboard/validate-personal-task/$taskId': typeof AppDashboardValidatePersonalTaskTaskIdRouteRoute
  '/app/enterprises/create-contact-business/$contactId': typeof AppEnterprisesCreateContactBusinessContactIdRouteRoute
  '/app/enterprises/create-contact-travel-voucher/$contactId': typeof AppEnterprisesCreateContactTravelVoucherContactIdRouteRoute
  '/app/enterprises/create-contact/$enterpriseId': typeof AppEnterprisesCreateContactEnterpriseIdRouteRoute
  '/app/enterprises/create-enterprise-rma/$enterpriseId': typeof AppEnterprisesCreateEnterpriseRmaEnterpriseIdRouteRoute
  '/app/enterprises/delete-contact/$contactId': typeof AppEnterprisesDeleteContactContactIdRouteRoute
  '/app/enterprises/send-email-to-contact/$contactId': typeof AppEnterprisesSendEmailToContactContactIdRouteRoute
  '/app/enterprises/update-contact-password/$contactId': typeof AppEnterprisesUpdateContactPasswordContactIdRouteRoute
  '/app/enterprises/update-contact/$contactId': typeof AppEnterprisesUpdateContactContactIdRouteRoute
  '/app/enterprises_/$enterpriseId/address-book': typeof AppEnterprisesEnterpriseIdAddressBookRouteRouteWithChildren
  '/app/enterprises_/$enterpriseId/create-contact': typeof AppEnterprisesEnterpriseIdCreateContactRouteRoute
  '/app/enterprises_/$enterpriseId/create-ged-directory': typeof AppEnterprisesEnterpriseIdCreateGedDirectoryRouteRoute
  '/app/enterprises_/$enterpriseId/create-lifesheet-comment': typeof AppEnterprisesEnterpriseIdCreateLifesheetCommentRouteRoute
  '/app/enterprises_/$enterpriseId/delete': typeof AppEnterprisesEnterpriseIdDeleteRouteRoute
  '/app/enterprises_/$enterpriseId/email-history': typeof AppEnterprisesEnterpriseIdEmailHistoryRouteRouteWithChildren
  '/app/enterprises_/$enterpriseId/import-contacts': typeof AppEnterprisesEnterpriseIdImportContactsRouteRoute
  '/app/enterprises_/$enterpriseId/import-ged-files': typeof AppEnterprisesEnterpriseIdImportGedFilesRouteRoute
  '/app/enterprises_/$enterpriseId/relate-business-rma': typeof AppEnterprisesEnterpriseIdRelateBusinessRmaRouteRoute
  '/app/enterprises_/$enterpriseId/update': typeof AppEnterprisesEnterpriseIdUpdateRouteRoute
  '/app/enterprises_/$enterpriseId/update-accountability': typeof AppEnterprisesEnterpriseIdUpdateAccountabilityRouteRoute
  '/app/enterprises_/$enterpriseId/update-category': typeof AppEnterprisesEnterpriseIdUpdateCategoryRouteRoute
  '/app/enterprises_/$enterpriseId/update-representative': typeof AppEnterprisesEnterpriseIdUpdateRepresentativeRouteRoute
  '/app/faq/archive/$faqId': typeof AppFaqArchiveFaqIdRouteRoute
  '/app/faq/delete/$faqId': typeof AppFaqDeleteFaqIdRouteRoute
  '/app/faq/ged/$faqId': typeof AppFaqGedFaqIdRouteRouteWithChildren
  '/app/faq/send-by-email/$faqId': typeof AppFaqSendByEmailFaqIdRouteRoute
  '/app/faq/update/$faqId': typeof AppFaqUpdateFaqIdRouteRoute
  '/app/products/serial-numbers/create': typeof AppProductsSerialNumbersCreateRouteRoute
  '/app/products_/$productId/informations': typeof AppProductsProductIdInformationsRouteRouteWithChildren
  '/app/products_/$productId/manage': typeof AppProductsProductIdManageRouteRouteWithChildren
  '/app/tools/credit/details': typeof AppToolsCreditDetailsRouteRoute
  '/app/tools/credit/show': typeof AppToolsCreditShowRouteRoute
  '/app/tools/ddns/create': typeof AppToolsDdnsCreateRouteRoute
  '/app/tools/departments/create': typeof AppToolsDepartmentsCreateRouteRoute
  '/app/tools/emails/$emailId': typeof AppToolsEmailsEmailIdRouteRouteWithChildren
  '/app/tools/external-links/create': typeof AppToolsExternalLinksCreateRouteRoute
  '/app/tools/formations/create': typeof AppToolsFormationsCreateRouteRouteWithChildren
  '/app/tools/mails/create': typeof AppToolsMailsCreateRouteRoute
  '/app/tools/menu/create-enterprise': typeof AppToolsMenuCreateEnterpriseRouteRoute
  '/app/tools/menu/create-product': typeof AppToolsMenuCreateProductRouteRoute
  '/app/tools/news/create': typeof AppToolsNewsCreateRouteRoute
  '/app/tools/predefined-messages/create': typeof AppToolsPredefinedMessagesCreateRouteRoute
  '/app/tools/predefined-texts/create': typeof AppToolsPredefinedTextsCreateRouteRoute
  '/app/tools/product-filters/create': typeof AppToolsProductFiltersCreateRouteRoute
  '/app/tools/product-inventory/validate-quantities': typeof AppToolsProductInventoryValidateQuantitiesRouteRoute
  '/app/tools/product-shelves/create': typeof AppToolsProductShelvesCreateRouteRoute
  '/app/tools/scheduler/create': typeof AppToolsSchedulerCreateRouteRoute
  '/app/tools/vva/create': typeof AppToolsVvaCreateRouteRoute
  '/app/products_/$productId/': typeof AppProductsProductIdIndexRoute
  '/app/businesses-rma_/business/$businessId/arc': typeof AppBusinessesRmaBusinessBusinessIdArcRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/bill': typeof AppBusinessesRmaBusinessBusinessIdBillRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/bl': typeof AppBusinessesRmaBusinessBusinessIdBlRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/bp': typeof AppBusinessesRmaBusinessBusinessIdBpRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/dashboard': typeof AppBusinessesRmaBusinessBusinessIdDashboardRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/quotation': typeof AppBusinessesRmaBusinessBusinessIdQuotationRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId_/study': typeof AppBusinessesRmaBusinessBusinessIdStudyRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/delivery': typeof AppBusinessesRmaRmaRmaIdDeliveryRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/reception': typeof AppBusinessesRmaRmaRmaIdReceptionRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/support': typeof AppBusinessesRmaRmaRmaIdSupportRouteRouteWithChildren
  '/app/dashboard/task-email/$taskId/reply': typeof AppDashboardTaskEmailTaskIdReplyRouteRoute
  '/app/enterprises_/$enterpriseId/address-book/create': typeof AppEnterprisesEnterpriseIdAddressBookCreateRouteRoute
  '/app/enterprises_/$enterpriseId/address-book/import': typeof AppEnterprisesEnterpriseIdAddressBookImportRouteRoute
  '/app/enterprises_/$enterpriseId/create-contact-business/$contactId': typeof AppEnterprisesEnterpriseIdCreateContactBusinessContactIdRouteRoute
  '/app/enterprises_/$enterpriseId/delete-contact/$contactId': typeof AppEnterprisesEnterpriseIdDeleteContactContactIdRouteRoute
  '/app/enterprises_/$enterpriseId/delete-ged-object/$objectRelativePath': typeof AppEnterprisesEnterpriseIdDeleteGedObjectObjectRelativePathRouteRoute
  '/app/enterprises_/$enterpriseId/lifesheet-email/$lifesheetId': typeof AppEnterprisesEnterpriseIdLifesheetEmailLifesheetIdRouteRoute
  '/app/enterprises_/$enterpriseId/rename-ged-object/$objectRelativePath': typeof AppEnterprisesEnterpriseIdRenameGedObjectObjectRelativePathRouteRoute
  '/app/enterprises_/$enterpriseId/send-email-to-contact/$contactId': typeof AppEnterprisesEnterpriseIdSendEmailToContactContactIdRouteRoute
  '/app/enterprises_/$enterpriseId/task-email/$taskId': typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdRouteRouteWithChildren
  '/app/enterprises_/$enterpriseId/unlink-task/$taskId': typeof AppEnterprisesEnterpriseIdUnlinkTaskTaskIdRouteRoute
  '/app/enterprises_/$enterpriseId/unrelate-business-rma/$businessRmaId': typeof AppEnterprisesEnterpriseIdUnrelateBusinessRmaBusinessRmaIdRouteRoute
  '/app/enterprises_/$enterpriseId/update-contact-password/$contactId': typeof AppEnterprisesEnterpriseIdUpdateContactPasswordContactIdRouteRoute
  '/app/enterprises_/$enterpriseId/update-contact/$contactId': typeof AppEnterprisesEnterpriseIdUpdateContactContactIdRouteRoute
  '/app/faq/ged/$faqId/create-directory': typeof AppFaqGedFaqIdCreateDirectoryRouteRoute
  '/app/faq/ged/$faqId/import-files': typeof AppFaqGedFaqIdImportFilesRouteRoute
  '/app/products/serial-numbers/create-rma/$serialNumberId': typeof AppProductsSerialNumbersCreateRmaSerialNumberIdRouteRoute
  '/app/products/serial-numbers/delete/$serialNumberId': typeof AppProductsSerialNumbersDeleteSerialNumberIdRouteRoute
  '/app/products/serial-numbers/remove-from-business/$serialNumberId': typeof AppProductsSerialNumbersRemoveFromBusinessSerialNumberIdRouteRoute
  '/app/products/serial-numbers/update/$serialNumberId': typeof AppProductsSerialNumbersUpdateSerialNumberIdRouteRoute
  '/app/products_/$productId/informations/create-ged-directory': typeof AppProductsProductIdInformationsCreateGedDirectoryRouteRoute
  '/app/products_/$productId/informations/create-lifesheet-comment': typeof AppProductsProductIdInformationsCreateLifesheetCommentRouteRoute
  '/app/products_/$productId/informations/delete-ged-object': typeof AppProductsProductIdInformationsDeleteGedObjectRouteRoute
  '/app/products_/$productId/informations/import-ged-files': typeof AppProductsProductIdInformationsImportGedFilesRouteRoute
  '/app/products_/$productId/informations/rename-ged-object': typeof AppProductsProductIdInformationsRenameGedObjectRouteRoute
  '/app/products_/$productId/manage/add-associated-product': typeof AppProductsProductIdManageAddAssociatedProductRouteRoute
  '/app/products_/$productId/manage/add-nomenclature-detail': typeof AppProductsProductIdManageAddNomenclatureDetailRouteRoute
  '/app/products_/$productId/manage/add-specification': typeof AppProductsProductIdManageAddSpecificationRouteRouteWithChildren
  '/app/products_/$productId/manage/create-stock': typeof AppProductsProductIdManageCreateStockRouteRoute
  '/app/products_/$productId/manage/create-version': typeof AppProductsProductIdManageCreateVersionRouteRoute
  '/app/tools/ddns/delete/$ddnsId': typeof AppToolsDdnsDeleteDdnsIdRouteRoute
  '/app/tools/departments/delete/$departmentId': typeof AppToolsDepartmentsDeleteDepartmentIdRouteRoute
  '/app/tools/departments/update/$departmentId': typeof AppToolsDepartmentsUpdateDepartmentIdRouteRoute
  '/app/tools/emails/$emailId/reply': typeof AppToolsEmailsEmailIdReplyRouteRoute
  '/app/tools/external-links/archive/$externalLinkId': typeof AppToolsExternalLinksArchiveExternalLinkIdRouteRoute
  '/app/tools/external-links/delete/$externalLinkId': typeof AppToolsExternalLinksDeleteExternalLinkIdRouteRoute
  '/app/tools/external-links/update/$externalLinkId': typeof AppToolsExternalLinksUpdateExternalLinkIdRouteRoute
  '/app/tools/formations/create/add-detail': typeof AppToolsFormationsCreateAddDetailRouteRoute
  '/app/tools/formations/create/details': typeof AppToolsFormationsCreateDetailsRouteRoute
  '/app/tools/formations/delete/$formationId': typeof AppToolsFormationsDeleteFormationIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId': typeof AppToolsFormationsSubscribersFormationDetailIdRouteRouteWithChildren
  '/app/tools/formations/update/$formationId': typeof AppToolsFormationsUpdateFormationIdRouteRouteWithChildren
  '/app/tools/mails/delete/$mailId': typeof AppToolsMailsDeleteMailIdRouteRoute
  '/app/tools/mails/show/$mailId': typeof AppToolsMailsShowMailIdRouteRoute
  '/app/tools/mails/update/$mailId': typeof AppToolsMailsUpdateMailIdRouteRoute
  '/app/tools/news/delete/$newsId': typeof AppToolsNewsDeleteNewsIdRouteRoute
  '/app/tools/news/update/$newsId': typeof AppToolsNewsUpdateNewsIdRouteRoute
  '/app/tools/predefined-messages/delete/$predefinedMessageId': typeof AppToolsPredefinedMessagesDeletePredefinedMessageIdRouteRoute
  '/app/tools/predefined-messages/update/$predefinedMessageId': typeof AppToolsPredefinedMessagesUpdatePredefinedMessageIdRouteRoute
  '/app/tools/predefined-texts/delete/$predefinedTextId': typeof AppToolsPredefinedTextsDeletePredefinedTextIdRouteRoute
  '/app/tools/predefined-texts/update/$predefinedTextId': typeof AppToolsPredefinedTextsUpdatePredefinedTextIdRouteRoute
  '/app/tools/product-filters/delete/$productFilterId': typeof AppToolsProductFiltersDeleteProductFilterIdRouteRoute
  '/app/tools/product-filters/update/$productFilterId': typeof AppToolsProductFiltersUpdateProductFilterIdRouteRoute
  '/app/tools/product-inventory/update/$stockId': typeof AppToolsProductInventoryUpdateStockIdRouteRoute
  '/app/tools/product-shelves/delete/$productShelfId': typeof AppToolsProductShelvesDeleteProductShelfIdRouteRoute
  '/app/tools/scheduler/details/$rdvId': typeof AppToolsSchedulerDetailsRdvIdRouteRouteWithChildren
  '/app/tools/vva/delete/$vvaId': typeof AppToolsVvaDeleteVvaIdRouteRoute
  '/app/businesses-rma_/business/$businessId/': typeof AppBusinessesRmaBusinessBusinessIdIndexRoute
  '/app/businesses-rma_/rma/$rmaId/': typeof AppBusinessesRmaRmaRmaIdIndexRoute
  '/app/businesses-rma_/business/$businessId/arc/pdf': typeof AppBusinessesRmaBusinessBusinessIdArcPdfRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/arc/update-shipping-price': typeof AppBusinessesRmaBusinessBusinessIdArcUpdateShippingPriceRouteRoute
  '/app/businesses-rma_/business/$businessId/bill/credits': typeof AppBusinessesRmaBusinessBusinessIdBillCreditsRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/bill/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBillSendByEmailRouteRoute
  '/app/businesses-rma_/business/$businessId/bl/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBlSendByEmailRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/travel-voucher': typeof AppBusinessesRmaBusinessBusinessIdBpTravelVoucherRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/address-book': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/dashboard/create-ged-directory': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateGedDirectoryRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/create-lifesheet': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLifesheetRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/create-link': typeof AppBusinessesRmaBusinessBusinessIdDashboardCreateLinkRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/delete': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/email-history': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/dashboard/import-ged-files': typeof AppBusinessesRmaBusinessBusinessIdDashboardImportGedFilesRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/send-email': typeof AppBusinessesRmaBusinessBusinessIdDashboardSendEmailRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/update-billing-address': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateBillingAddressRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/update-representative': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateRepresentativeRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/update-responsible': typeof AppBusinessesRmaBusinessBusinessIdDashboardUpdateResponsibleRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/commercial-notice': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/quotation/pdf': typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/quotation/update-shipping-price': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateShippingPriceRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId_/study/automatic': typeof AppBusinessesRmaBusinessBusinessIdStudyAutomaticRouteRoute
  '/app/businesses-rma_/business/$businessId_/study/expert': typeof AppBusinessesRmaBusinessBusinessIdStudyExpertRouteRoute
  '/app/businesses-rma_/rma/$rmaId/delivery/create-detail': typeof AppBusinessesRmaRmaRmaIdDeliveryCreateDetailRouteRoute
  '/app/businesses-rma_/rma/$rmaId/delivery/pdf': typeof AppBusinessesRmaRmaRmaIdDeliveryPdfRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/delivery/travel-voucher': typeof AppBusinessesRmaRmaRmaIdDeliveryTravelVoucherRouteRoute
  '/app/businesses-rma_/rma/$rmaId/reception/create-detail': typeof AppBusinessesRmaRmaRmaIdReceptionCreateDetailRouteRoute
  '/app/businesses-rma_/rma/$rmaId/reception/pdf': typeof AppBusinessesRmaRmaRmaIdReceptionPdfRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/support/create-detail': typeof AppBusinessesRmaRmaRmaIdSupportCreateDetailRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/create-ged-directory': typeof AppBusinessesRmaRmaRmaIdSupportCreateGedDirectoryRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/create-lifesheet': typeof AppBusinessesRmaRmaRmaIdSupportCreateLifesheetRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/create-link': typeof AppBusinessesRmaRmaRmaIdSupportCreateLinkRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/import-ged-files': typeof AppBusinessesRmaRmaRmaIdSupportImportGedFilesRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/pdf': typeof AppBusinessesRmaRmaRmaIdSupportPdfRouteRouteWithChildren
  '/app/enterprises_/$enterpriseId/address-book/delete/$addressId': typeof AppEnterprisesEnterpriseIdAddressBookDeleteAddressIdRouteRoute
  '/app/enterprises_/$enterpriseId/address-book/update/$addressId': typeof AppEnterprisesEnterpriseIdAddressBookUpdateAddressIdRouteRoute
  '/app/enterprises_/$enterpriseId/email-history/email/$emailId': typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdRouteRouteWithChildren
  '/app/enterprises_/$enterpriseId/task-email/$taskId/reply': typeof AppEnterprisesEnterpriseIdTaskEmailTaskIdReplyRouteRoute
  '/app/faq/ged/$faqId/delete/$itemRelativePath': typeof AppFaqGedFaqIdDeleteItemRelativePathRouteRoute
  '/app/faq/ged/$faqId/rename/$itemRelativePath': typeof AppFaqGedFaqIdRenameItemRelativePathRouteRoute
  '/app/products_/$productId/informations/lifesheet-email/$lifesheetId': typeof AppProductsProductIdInformationsLifesheetEmailLifesheetIdRouteRoute
  '/app/products_/$productId/informations/task-email/$taskId': typeof AppProductsProductIdInformationsTaskEmailTaskIdRouteRouteWithChildren
  '/app/products_/$productId/informations/unlink-task/$taskId': typeof AppProductsProductIdInformationsUnlinkTaskTaskIdRouteRoute
  '/app/products_/$productId/manage/add-specification/$filterId': typeof AppProductsProductIdManageAddSpecificationFilterIdRouteRoute
  '/app/products_/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId': typeof AppProductsProductIdManageDeleteNomenclatureDetailNomenclatureDetailIdRouteRoute
  '/app/products_/$productId/manage/delete-specification/$specificationId': typeof AppProductsProductIdManageDeleteSpecificationSpecificationIdRouteRoute
  '/app/products_/$productId/manage/delete-stock/$stockId': typeof AppProductsProductIdManageDeleteStockStockIdRouteRoute
  '/app/products_/$productId/manage/delete-version/$versionId': typeof AppProductsProductIdManageDeleteVersionVersionIdRouteRoute
  '/app/products_/$productId/manage/remove-associated-product/$associatedProductId': typeof AppProductsProductIdManageRemoveAssociatedProductAssociatedProductIdRouteRoute
  '/app/products_/$productId/manage/stock-history/$stockId': typeof AppProductsProductIdManageStockHistoryStockIdRouteRoute
  '/app/products_/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId': typeof AppProductsProductIdManageUpdateNomenclatureDetailNomenclatureDetailIdRouteRoute
  '/app/products_/$productId/manage/update-specification/$specificationId': typeof AppProductsProductIdManageUpdateSpecificationSpecificationIdRouteRoute
  '/app/products_/$productId/manage/update-stock/$stockId': typeof AppProductsProductIdManageUpdateStockStockIdRouteRoute
  '/app/products_/$productId/manage/update-version/$versionId': typeof AppProductsProductIdManageUpdateVersionVersionIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/create': typeof AppToolsFormationsSubscribersFormationDetailIdCreateRouteRoute
  '/app/tools/formations/update/$formationId/add-detail': typeof AppToolsFormationsUpdateFormationIdAddDetailRouteRoute
  '/app/tools/formations/update/$formationId/details': typeof AppToolsFormationsUpdateFormationIdDetailsRouteRoute
  '/app/tools/scheduler/details/$rdvId/delete': typeof AppToolsSchedulerDetailsRdvIdDeleteRouteRoute
  '/app/tools/scheduler/details/$rdvId/update': typeof AppToolsSchedulerDetailsRdvIdUpdateRouteRoute
  '/app/businesses-rma_/business/$businessId/bl/update': typeof AppBusinessesRmaBusinessBusinessIdBlUpdateRouteLazyRoute
  '/app/businesses-rma_/business/$businessId_/study/': typeof AppBusinessesRmaBusinessBusinessIdStudyIndexRoute
  '/app/businesses-rma_/business/$businessId/arc/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdArcDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/arc/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdArcPdfSendByEmailRouteRoute
  '/app/businesses-rma_/business/$businessId/arc/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdArcUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/bill/credits/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdBillCreditsSendByEmailRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/add-serial/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpAddSerialDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/create-detail-rma/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpCreateDetailRmaDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/create-serial-rma/$serialId': typeof AppBusinessesRmaBusinessBusinessIdBpCreateSerialRmaSerialIdRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/delete-serial/$serialId': typeof AppBusinessesRmaBusinessBusinessIdBpDeleteSerialSerialIdRouteRoute
  '/app/businesses-rma_/business/$businessId/bp/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdBpUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/address-book/create': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookCreateRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId': typeof AppBusinessesRmaBusinessBusinessIdDashboardConfirmQuotationImportOtherBusinessIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/delete-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/delete-link/$associatedId': typeof AppBusinessesRmaBusinessBusinessIdDashboardDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/dashboard/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaBusinessBusinessIdDashboardLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdDashboardRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/task-email/$taskId': typeof AppBusinessesRmaBusinessBusinessIdDashboardTaskEmailTaskIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/unlink-task/$taskId': typeof AppBusinessesRmaBusinessBusinessIdDashboardUnlinkTaskTaskIdRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailRouteRouteWithChildren
  '/app/businesses-rma_/business/$businessId/quotation/create-associated-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateAssociatedDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/create-detail/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationCreateDetailSubquotationIdRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/delete-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/delete-subquotation/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationDeleteSubquotationSubquotationIdRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdQuotationPdfSendByEmailRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/update-detail/$detailId': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/update-subquotation/$subquotationId': typeof AppBusinessesRmaBusinessBusinessIdQuotationUpdateSubquotationSubquotationIdRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-faq': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateFaqRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-ged-directory': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateGedDirectoryRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-lifesheet': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLifesheetRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-link': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdCreateLinkRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-cumulated-time': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditCumulatedTimeRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-no-billed-time': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditNoBilledTimeRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-subtitle': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdEditSubtitleRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/import-ged-files': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdImportGedFilesRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/delivery/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdDeliveryDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/delivery/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdDeliveryPdfSendByEmailRouteRoute
  '/app/businesses-rma_/rma/$rmaId/delivery/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdDeliveryUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/reception/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdReceptionDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/reception/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdReceptionPdfSendByEmailRouteRoute
  '/app/businesses-rma_/rma/$rmaId/reception/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdReceptionUpdateDetailDetailIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/delete-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdSupportDeleteDetailDetailIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/delete-ged-object/$relativePath': typeof AppBusinessesRmaRmaRmaIdSupportDeleteGedObjectRelativePathRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/delete-link/$associatedId': typeof AppBusinessesRmaRmaRmaIdSupportDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaRmaRmaIdSupportLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/pdf/send-by-email': typeof AppBusinessesRmaRmaRmaIdSupportPdfSendByEmailRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaRmaRmaIdSupportRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId': typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdRouteRouteWithChildren
  '/app/businesses-rma_/rma/$rmaId/support/unlink-task/$taskId': typeof AppBusinessesRmaRmaRmaIdSupportUnlinkTaskTaskIdRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/update-detail/$detailId': typeof AppBusinessesRmaRmaRmaIdSupportUpdateDetailDetailIdRouteRoute
  '/app/enterprises_/$enterpriseId/email-history/email/$emailId/reply': typeof AppEnterprisesEnterpriseIdEmailHistoryEmailEmailIdReplyRouteRoute
  '/app/products_/$productId/informations/task-email/$taskId/reply': typeof AppProductsProductIdInformationsTaskEmailTaskIdReplyRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId': typeof AppToolsFormationsSubscribersFormationDetailIdDeleteSubscriptionIdRouteRoute
  '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId': typeof AppToolsFormationsSubscribersFormationDetailIdSendEmailSubscriptionIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/address-book/delete/$addressId': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookDeleteAddressIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/address-book/update/$addressId': typeof AppBusinessesRmaBusinessBusinessIdDashboardAddressBookUpdateAddressIdRouteRoute
  '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId/reply': typeof AppBusinessesRmaBusinessBusinessIdDashboardEmailHistoryEmailIdReplyRouteRoute
  '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages': typeof AppBusinessesRmaBusinessBusinessIdQuotationCommercialNoticeSendByEmailPredefinedMessagesRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-link/$associatedId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdDeleteLinkAssociatedIdRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/lifesheet-email/$lifesheetId': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdLifesheetEmailLifesheetIdRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf/send-by-email': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdPdfSendByEmailRouteRoute
  '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/rename-ged-object/$objectRelativePath': typeof AppBusinessesRmaBusinessBusinessIdAssistanceAssistanceIdRenameGedObjectObjectRelativePathRouteRoute
  '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId/reply': typeof AppBusinessesRmaRmaRmaIdSupportTaskEmailTaskIdReplyRouteRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/app'
    | '/auth'
    | '/app/businesses-rma'
    | '/app/dashboard'
    | '/app/enterprises'
    | '/app/external-links'
    | '/app/faq'
    | '/app/products'
    | '/app/tools'
    | '/auth/login'
    | '/auth/forgot-password'
    | '/app/'
    | '/auth/'
    | '/app/businesses-rma/representative-turnover'
    | '/app/businesses-rma/search-by-products'
    | '/app/dashboard/create-collective-task'
    | '/app/dashboard/create-personal-task'
    | '/app/dashboard/create-progressive-info'
    | '/app/dashboard/delete-collective-tasks'
    | '/app/enterprises/create'
    | '/app/enterprises/$enterpriseId'
    | '/app/external-links/$externalLinkId'
    | '/app/faq/create'
    | '/app/products/create'
    | '/app/products/serial-numbers'
    | '/app/products/$productId'
    | '/app/tools/credit'
    | '/app/tools/ddns'
    | '/app/tools/departments'
    | '/app/tools/emails'
    | '/app/tools/external-links'
    | '/app/tools/formations'
    | '/app/tools/global-turnover'
    | '/app/tools/mails'
    | '/app/tools/menu'
    | '/app/tools/news'
    | '/app/tools/predefined-messages'
    | '/app/tools/predefined-texts'
    | '/app/tools/product-filters'
    | '/app/tools/product-inventory'
    | '/app/tools/product-shelves'
    | '/app/tools/representatives-map'
    | '/app/tools/representatives-turnover'
    | '/app/tools/scheduler'
    | '/app/tools/vva'
    | '/auth/reset-password/$token'
    | '/app/tools/'
    | '/app/businesses-rma/business/$businessId'
    | '/app/businesses-rma/rma/$rmaId'
    | '/app/dashboard/archive-personal-task/$taskId'
    | '/app/dashboard/delete-collective-task/$taskId'
    | '/app/dashboard/delete-progressive-info/$progressiveInfoId'
    | '/app/dashboard/link-personal-task/$taskId'
    | '/app/dashboard/other-personal-tasks/$profileId'
    | '/app/dashboard/personal-task-details/$taskId'
    | '/app/dashboard/scheduler-event-details/$eventId'
    | '/app/dashboard/take-collective-task/$taskId'
    | '/app/dashboard/task-comments/$taskId'
    | '/app/dashboard/task-email/$taskId'
    | '/app/dashboard/transfer-task/$taskId'
    | '/app/dashboard/unlink-personal-task/$taskId'
    | '/app/dashboard/update-personal-task-deadline/$taskId'
    | '/app/dashboard/update-progressive-info/$progressiveInfoId'
    | '/app/dashboard/validate-personal-task/$taskId'
    | '/app/enterprises/create-contact-business/$contactId'
    | '/app/enterprises/create-contact-travel-voucher/$contactId'
    | '/app/enterprises/create-contact/$enterpriseId'
    | '/app/enterprises/create-enterprise-rma/$enterpriseId'
    | '/app/enterprises/delete-contact/$contactId'
    | '/app/enterprises/send-email-to-contact/$contactId'
    | '/app/enterprises/update-contact-password/$contactId'
    | '/app/enterprises/update-contact/$contactId'
    | '/app/enterprises/$enterpriseId/address-book'
    | '/app/enterprises/$enterpriseId/create-contact'
    | '/app/enterprises/$enterpriseId/create-ged-directory'
    | '/app/enterprises/$enterpriseId/create-lifesheet-comment'
    | '/app/enterprises/$enterpriseId/delete'
    | '/app/enterprises/$enterpriseId/email-history'
    | '/app/enterprises/$enterpriseId/import-contacts'
    | '/app/enterprises/$enterpriseId/import-ged-files'
    | '/app/enterprises/$enterpriseId/relate-business-rma'
    | '/app/enterprises/$enterpriseId/update'
    | '/app/enterprises/$enterpriseId/update-accountability'
    | '/app/enterprises/$enterpriseId/update-category'
    | '/app/enterprises/$enterpriseId/update-representative'
    | '/app/faq/archive/$faqId'
    | '/app/faq/delete/$faqId'
    | '/app/faq/ged/$faqId'
    | '/app/faq/send-by-email/$faqId'
    | '/app/faq/update/$faqId'
    | '/app/products/serial-numbers/create'
    | '/app/products/$productId/informations'
    | '/app/products/$productId/manage'
    | '/app/tools/credit/details'
    | '/app/tools/credit/show'
    | '/app/tools/ddns/create'
    | '/app/tools/departments/create'
    | '/app/tools/emails/$emailId'
    | '/app/tools/external-links/create'
    | '/app/tools/formations/create'
    | '/app/tools/mails/create'
    | '/app/tools/menu/create-enterprise'
    | '/app/tools/menu/create-product'
    | '/app/tools/news/create'
    | '/app/tools/predefined-messages/create'
    | '/app/tools/predefined-texts/create'
    | '/app/tools/product-filters/create'
    | '/app/tools/product-inventory/validate-quantities'
    | '/app/tools/product-shelves/create'
    | '/app/tools/scheduler/create'
    | '/app/tools/vva/create'
    | '/app/products/$productId/'
    | '/app/businesses-rma/business/$businessId/arc'
    | '/app/businesses-rma/business/$businessId/bill'
    | '/app/businesses-rma/business/$businessId/bl'
    | '/app/businesses-rma/business/$businessId/bp'
    | '/app/businesses-rma/business/$businessId/dashboard'
    | '/app/businesses-rma/business/$businessId/quotation'
    | '/app/businesses-rma/business/$businessId/study'
    | '/app/businesses-rma/rma/$rmaId/delivery'
    | '/app/businesses-rma/rma/$rmaId/reception'
    | '/app/businesses-rma/rma/$rmaId/support'
    | '/app/dashboard/task-email/$taskId/reply'
    | '/app/enterprises/$enterpriseId/address-book/create'
    | '/app/enterprises/$enterpriseId/address-book/import'
    | '/app/enterprises/$enterpriseId/create-contact-business/$contactId'
    | '/app/enterprises/$enterpriseId/delete-contact/$contactId'
    | '/app/enterprises/$enterpriseId/delete-ged-object/$objectRelativePath'
    | '/app/enterprises/$enterpriseId/lifesheet-email/$lifesheetId'
    | '/app/enterprises/$enterpriseId/rename-ged-object/$objectRelativePath'
    | '/app/enterprises/$enterpriseId/send-email-to-contact/$contactId'
    | '/app/enterprises/$enterpriseId/task-email/$taskId'
    | '/app/enterprises/$enterpriseId/unlink-task/$taskId'
    | '/app/enterprises/$enterpriseId/unrelate-business-rma/$businessRmaId'
    | '/app/enterprises/$enterpriseId/update-contact-password/$contactId'
    | '/app/enterprises/$enterpriseId/update-contact/$contactId'
    | '/app/faq/ged/$faqId/create-directory'
    | '/app/faq/ged/$faqId/import-files'
    | '/app/products/serial-numbers/create-rma/$serialNumberId'
    | '/app/products/serial-numbers/delete/$serialNumberId'
    | '/app/products/serial-numbers/remove-from-business/$serialNumberId'
    | '/app/products/serial-numbers/update/$serialNumberId'
    | '/app/products/$productId/informations/create-ged-directory'
    | '/app/products/$productId/informations/create-lifesheet-comment'
    | '/app/products/$productId/informations/delete-ged-object'
    | '/app/products/$productId/informations/import-ged-files'
    | '/app/products/$productId/informations/rename-ged-object'
    | '/app/products/$productId/manage/add-associated-product'
    | '/app/products/$productId/manage/add-nomenclature-detail'
    | '/app/products/$productId/manage/add-specification'
    | '/app/products/$productId/manage/create-stock'
    | '/app/products/$productId/manage/create-version'
    | '/app/tools/ddns/delete/$ddnsId'
    | '/app/tools/departments/delete/$departmentId'
    | '/app/tools/departments/update/$departmentId'
    | '/app/tools/emails/$emailId/reply'
    | '/app/tools/external-links/archive/$externalLinkId'
    | '/app/tools/external-links/delete/$externalLinkId'
    | '/app/tools/external-links/update/$externalLinkId'
    | '/app/tools/formations/create/add-detail'
    | '/app/tools/formations/create/details'
    | '/app/tools/formations/delete/$formationId'
    | '/app/tools/formations/subscribers/$formationDetailId'
    | '/app/tools/formations/update/$formationId'
    | '/app/tools/mails/delete/$mailId'
    | '/app/tools/mails/show/$mailId'
    | '/app/tools/mails/update/$mailId'
    | '/app/tools/news/delete/$newsId'
    | '/app/tools/news/update/$newsId'
    | '/app/tools/predefined-messages/delete/$predefinedMessageId'
    | '/app/tools/predefined-messages/update/$predefinedMessageId'
    | '/app/tools/predefined-texts/delete/$predefinedTextId'
    | '/app/tools/predefined-texts/update/$predefinedTextId'
    | '/app/tools/product-filters/delete/$productFilterId'
    | '/app/tools/product-filters/update/$productFilterId'
    | '/app/tools/product-inventory/update/$stockId'
    | '/app/tools/product-shelves/delete/$productShelfId'
    | '/app/tools/scheduler/details/$rdvId'
    | '/app/tools/vva/delete/$vvaId'
    | '/app/businesses-rma/business/$businessId/'
    | '/app/businesses-rma/rma/$rmaId/'
    | '/app/businesses-rma/business/$businessId/arc/pdf'
    | '/app/businesses-rma/business/$businessId/arc/update-shipping-price'
    | '/app/businesses-rma/business/$businessId/bill/credits'
    | '/app/businesses-rma/business/$businessId/bill/send-by-email'
    | '/app/businesses-rma/business/$businessId/bl/send-by-email'
    | '/app/businesses-rma/business/$businessId/bp/travel-voucher'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book'
    | '/app/businesses-rma/business/$businessId/dashboard/create-ged-directory'
    | '/app/businesses-rma/business/$businessId/dashboard/create-lifesheet'
    | '/app/businesses-rma/business/$businessId/dashboard/create-link'
    | '/app/businesses-rma/business/$businessId/dashboard/delete'
    | '/app/businesses-rma/business/$businessId/dashboard/email-history'
    | '/app/businesses-rma/business/$businessId/dashboard/import-ged-files'
    | '/app/businesses-rma/business/$businessId/dashboard/send-email'
    | '/app/businesses-rma/business/$businessId/dashboard/update-billing-address'
    | '/app/businesses-rma/business/$businessId/dashboard/update-representative'
    | '/app/businesses-rma/business/$businessId/dashboard/update-responsible'
    | '/app/businesses-rma/business/$businessId/quotation/commercial-notice'
    | '/app/businesses-rma/business/$businessId/quotation/pdf'
    | '/app/businesses-rma/business/$businessId/quotation/update-shipping-price'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId'
    | '/app/businesses-rma/business/$businessId/study/automatic'
    | '/app/businesses-rma/business/$businessId/study/expert'
    | '/app/businesses-rma/rma/$rmaId/delivery/create-detail'
    | '/app/businesses-rma/rma/$rmaId/delivery/pdf'
    | '/app/businesses-rma/rma/$rmaId/delivery/travel-voucher'
    | '/app/businesses-rma/rma/$rmaId/reception/create-detail'
    | '/app/businesses-rma/rma/$rmaId/reception/pdf'
    | '/app/businesses-rma/rma/$rmaId/support/create-detail'
    | '/app/businesses-rma/rma/$rmaId/support/create-ged-directory'
    | '/app/businesses-rma/rma/$rmaId/support/create-lifesheet'
    | '/app/businesses-rma/rma/$rmaId/support/create-link'
    | '/app/businesses-rma/rma/$rmaId/support/import-ged-files'
    | '/app/businesses-rma/rma/$rmaId/support/pdf'
    | '/app/enterprises/$enterpriseId/address-book/delete/$addressId'
    | '/app/enterprises/$enterpriseId/address-book/update/$addressId'
    | '/app/enterprises/$enterpriseId/email-history/email/$emailId'
    | '/app/enterprises/$enterpriseId/task-email/$taskId/reply'
    | '/app/faq/ged/$faqId/delete/$itemRelativePath'
    | '/app/faq/ged/$faqId/rename/$itemRelativePath'
    | '/app/products/$productId/informations/lifesheet-email/$lifesheetId'
    | '/app/products/$productId/informations/task-email/$taskId'
    | '/app/products/$productId/informations/unlink-task/$taskId'
    | '/app/products/$productId/manage/add-specification/$filterId'
    | '/app/products/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId'
    | '/app/products/$productId/manage/delete-specification/$specificationId'
    | '/app/products/$productId/manage/delete-stock/$stockId'
    | '/app/products/$productId/manage/delete-version/$versionId'
    | '/app/products/$productId/manage/remove-associated-product/$associatedProductId'
    | '/app/products/$productId/manage/stock-history/$stockId'
    | '/app/products/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId'
    | '/app/products/$productId/manage/update-specification/$specificationId'
    | '/app/products/$productId/manage/update-stock/$stockId'
    | '/app/products/$productId/manage/update-version/$versionId'
    | '/app/tools/formations/subscribers/$formationDetailId/create'
    | '/app/tools/formations/update/$formationId/add-detail'
    | '/app/tools/formations/update/$formationId/details'
    | '/app/tools/scheduler/details/$rdvId/delete'
    | '/app/tools/scheduler/details/$rdvId/update'
    | '/app/businesses-rma/business/$businessId/bl/update'
    | '/app/businesses-rma/business/$businessId/study/'
    | '/app/businesses-rma/business/$businessId/arc/delete-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/arc/pdf/send-by-email'
    | '/app/businesses-rma/business/$businessId/arc/update-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/bill/credits/send-by-email'
    | '/app/businesses-rma/business/$businessId/bp/add-serial/$detailId'
    | '/app/businesses-rma/business/$businessId/bp/create-detail-rma/$detailId'
    | '/app/businesses-rma/business/$businessId/bp/create-serial-rma/$serialId'
    | '/app/businesses-rma/business/$businessId/bp/delete-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/bp/delete-serial/$serialId'
    | '/app/businesses-rma/business/$businessId/bp/update-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book/create'
    | '/app/businesses-rma/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId'
    | '/app/businesses-rma/business/$businessId/dashboard/delete-ged-object/$objectRelativePath'
    | '/app/businesses-rma/business/$businessId/dashboard/delete-link/$associatedId'
    | '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId'
    | '/app/businesses-rma/business/$businessId/dashboard/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma/business/$businessId/dashboard/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma/business/$businessId/dashboard/task-email/$taskId'
    | '/app/businesses-rma/business/$businessId/dashboard/unlink-task/$taskId'
    | '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email'
    | '/app/businesses-rma/business/$businessId/quotation/create-associated-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/quotation/create-detail/$subquotationId'
    | '/app/businesses-rma/business/$businessId/quotation/delete-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/quotation/delete-subquotation/$subquotationId'
    | '/app/businesses-rma/business/$businessId/quotation/pdf/send-by-email'
    | '/app/businesses-rma/business/$businessId/quotation/update-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/quotation/update-subquotation/$subquotationId'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-faq'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-ged-directory'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-lifesheet'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-link'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-cumulated-time'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-no-billed-time'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-subtitle'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/import-ged-files'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf'
    | '/app/businesses-rma/rma/$rmaId/delivery/delete-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/delivery/pdf/send-by-email'
    | '/app/businesses-rma/rma/$rmaId/delivery/update-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/reception/delete-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/reception/pdf/send-by-email'
    | '/app/businesses-rma/rma/$rmaId/reception/update-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/support/delete-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/support/delete-ged-object/$relativePath'
    | '/app/businesses-rma/rma/$rmaId/support/delete-link/$associatedId'
    | '/app/businesses-rma/rma/$rmaId/support/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma/rma/$rmaId/support/pdf/send-by-email'
    | '/app/businesses-rma/rma/$rmaId/support/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId'
    | '/app/businesses-rma/rma/$rmaId/support/unlink-task/$taskId'
    | '/app/businesses-rma/rma/$rmaId/support/update-detail/$detailId'
    | '/app/enterprises/$enterpriseId/email-history/email/$emailId/reply'
    | '/app/products/$productId/informations/task-email/$taskId/reply'
    | '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId'
    | '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book/delete/$addressId'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book/update/$addressId'
    | '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId/reply'
    | '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-ged-object/$objectRelativePath'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-link/$associatedId'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf/send-by-email'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId/reply'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/app/businesses-rma'
    | '/app/dashboard'
    | '/app/enterprises'
    | '/app/external-links'
    | '/app/faq'
    | '/app/products'
    | '/auth/login'
    | '/auth/forgot-password'
    | '/app'
    | '/auth'
    | '/app/businesses-rma/representative-turnover'
    | '/app/businesses-rma/search-by-products'
    | '/app/dashboard/create-collective-task'
    | '/app/dashboard/create-personal-task'
    | '/app/dashboard/create-progressive-info'
    | '/app/dashboard/delete-collective-tasks'
    | '/app/enterprises/create'
    | '/app/enterprises/$enterpriseId'
    | '/app/external-links/$externalLinkId'
    | '/app/faq/create'
    | '/app/products/create'
    | '/app/products/serial-numbers'
    | '/app/tools/credit'
    | '/app/tools/ddns'
    | '/app/tools/departments'
    | '/app/tools/emails'
    | '/app/tools/external-links'
    | '/app/tools/formations'
    | '/app/tools/global-turnover'
    | '/app/tools/mails'
    | '/app/tools/menu'
    | '/app/tools/news'
    | '/app/tools/predefined-messages'
    | '/app/tools/predefined-texts'
    | '/app/tools/product-filters'
    | '/app/tools/product-inventory'
    | '/app/tools/product-shelves'
    | '/app/tools/representatives-map'
    | '/app/tools/representatives-turnover'
    | '/app/tools/scheduler'
    | '/app/tools/vva'
    | '/auth/reset-password/$token'
    | '/app/tools'
    | '/app/dashboard/archive-personal-task/$taskId'
    | '/app/dashboard/delete-collective-task/$taskId'
    | '/app/dashboard/delete-progressive-info/$progressiveInfoId'
    | '/app/dashboard/link-personal-task/$taskId'
    | '/app/dashboard/other-personal-tasks/$profileId'
    | '/app/dashboard/personal-task-details/$taskId'
    | '/app/dashboard/scheduler-event-details/$eventId'
    | '/app/dashboard/take-collective-task/$taskId'
    | '/app/dashboard/task-comments/$taskId'
    | '/app/dashboard/task-email/$taskId'
    | '/app/dashboard/transfer-task/$taskId'
    | '/app/dashboard/unlink-personal-task/$taskId'
    | '/app/dashboard/update-personal-task-deadline/$taskId'
    | '/app/dashboard/update-progressive-info/$progressiveInfoId'
    | '/app/dashboard/validate-personal-task/$taskId'
    | '/app/enterprises/create-contact-business/$contactId'
    | '/app/enterprises/create-contact-travel-voucher/$contactId'
    | '/app/enterprises/create-contact/$enterpriseId'
    | '/app/enterprises/create-enterprise-rma/$enterpriseId'
    | '/app/enterprises/delete-contact/$contactId'
    | '/app/enterprises/send-email-to-contact/$contactId'
    | '/app/enterprises/update-contact-password/$contactId'
    | '/app/enterprises/update-contact/$contactId'
    | '/app/enterprises/$enterpriseId/address-book'
    | '/app/enterprises/$enterpriseId/create-contact'
    | '/app/enterprises/$enterpriseId/create-ged-directory'
    | '/app/enterprises/$enterpriseId/create-lifesheet-comment'
    | '/app/enterprises/$enterpriseId/delete'
    | '/app/enterprises/$enterpriseId/email-history'
    | '/app/enterprises/$enterpriseId/import-contacts'
    | '/app/enterprises/$enterpriseId/import-ged-files'
    | '/app/enterprises/$enterpriseId/relate-business-rma'
    | '/app/enterprises/$enterpriseId/update'
    | '/app/enterprises/$enterpriseId/update-accountability'
    | '/app/enterprises/$enterpriseId/update-category'
    | '/app/enterprises/$enterpriseId/update-representative'
    | '/app/faq/archive/$faqId'
    | '/app/faq/delete/$faqId'
    | '/app/faq/ged/$faqId'
    | '/app/faq/send-by-email/$faqId'
    | '/app/faq/update/$faqId'
    | '/app/products/serial-numbers/create'
    | '/app/products/$productId/informations'
    | '/app/products/$productId/manage'
    | '/app/tools/credit/details'
    | '/app/tools/credit/show'
    | '/app/tools/ddns/create'
    | '/app/tools/departments/create'
    | '/app/tools/emails/$emailId'
    | '/app/tools/external-links/create'
    | '/app/tools/formations/create'
    | '/app/tools/mails/create'
    | '/app/tools/menu/create-enterprise'
    | '/app/tools/menu/create-product'
    | '/app/tools/news/create'
    | '/app/tools/predefined-messages/create'
    | '/app/tools/predefined-texts/create'
    | '/app/tools/product-filters/create'
    | '/app/tools/product-inventory/validate-quantities'
    | '/app/tools/product-shelves/create'
    | '/app/tools/scheduler/create'
    | '/app/tools/vva/create'
    | '/app/products/$productId'
    | '/app/businesses-rma/business/$businessId/arc'
    | '/app/businesses-rma/business/$businessId/bill'
    | '/app/businesses-rma/business/$businessId/bl'
    | '/app/businesses-rma/business/$businessId/bp'
    | '/app/businesses-rma/business/$businessId/dashboard'
    | '/app/businesses-rma/business/$businessId/quotation'
    | '/app/businesses-rma/rma/$rmaId/delivery'
    | '/app/businesses-rma/rma/$rmaId/reception'
    | '/app/businesses-rma/rma/$rmaId/support'
    | '/app/dashboard/task-email/$taskId/reply'
    | '/app/enterprises/$enterpriseId/address-book/create'
    | '/app/enterprises/$enterpriseId/address-book/import'
    | '/app/enterprises/$enterpriseId/create-contact-business/$contactId'
    | '/app/enterprises/$enterpriseId/delete-contact/$contactId'
    | '/app/enterprises/$enterpriseId/delete-ged-object/$objectRelativePath'
    | '/app/enterprises/$enterpriseId/lifesheet-email/$lifesheetId'
    | '/app/enterprises/$enterpriseId/rename-ged-object/$objectRelativePath'
    | '/app/enterprises/$enterpriseId/send-email-to-contact/$contactId'
    | '/app/enterprises/$enterpriseId/task-email/$taskId'
    | '/app/enterprises/$enterpriseId/unlink-task/$taskId'
    | '/app/enterprises/$enterpriseId/unrelate-business-rma/$businessRmaId'
    | '/app/enterprises/$enterpriseId/update-contact-password/$contactId'
    | '/app/enterprises/$enterpriseId/update-contact/$contactId'
    | '/app/faq/ged/$faqId/create-directory'
    | '/app/faq/ged/$faqId/import-files'
    | '/app/products/serial-numbers/create-rma/$serialNumberId'
    | '/app/products/serial-numbers/delete/$serialNumberId'
    | '/app/products/serial-numbers/remove-from-business/$serialNumberId'
    | '/app/products/serial-numbers/update/$serialNumberId'
    | '/app/products/$productId/informations/create-ged-directory'
    | '/app/products/$productId/informations/create-lifesheet-comment'
    | '/app/products/$productId/informations/delete-ged-object'
    | '/app/products/$productId/informations/import-ged-files'
    | '/app/products/$productId/informations/rename-ged-object'
    | '/app/products/$productId/manage/add-associated-product'
    | '/app/products/$productId/manage/add-nomenclature-detail'
    | '/app/products/$productId/manage/add-specification'
    | '/app/products/$productId/manage/create-stock'
    | '/app/products/$productId/manage/create-version'
    | '/app/tools/ddns/delete/$ddnsId'
    | '/app/tools/departments/delete/$departmentId'
    | '/app/tools/departments/update/$departmentId'
    | '/app/tools/emails/$emailId/reply'
    | '/app/tools/external-links/archive/$externalLinkId'
    | '/app/tools/external-links/delete/$externalLinkId'
    | '/app/tools/external-links/update/$externalLinkId'
    | '/app/tools/formations/create/add-detail'
    | '/app/tools/formations/create/details'
    | '/app/tools/formations/delete/$formationId'
    | '/app/tools/formations/subscribers/$formationDetailId'
    | '/app/tools/formations/update/$formationId'
    | '/app/tools/mails/delete/$mailId'
    | '/app/tools/mails/show/$mailId'
    | '/app/tools/mails/update/$mailId'
    | '/app/tools/news/delete/$newsId'
    | '/app/tools/news/update/$newsId'
    | '/app/tools/predefined-messages/delete/$predefinedMessageId'
    | '/app/tools/predefined-messages/update/$predefinedMessageId'
    | '/app/tools/predefined-texts/delete/$predefinedTextId'
    | '/app/tools/predefined-texts/update/$predefinedTextId'
    | '/app/tools/product-filters/delete/$productFilterId'
    | '/app/tools/product-filters/update/$productFilterId'
    | '/app/tools/product-inventory/update/$stockId'
    | '/app/tools/product-shelves/delete/$productShelfId'
    | '/app/tools/scheduler/details/$rdvId'
    | '/app/tools/vva/delete/$vvaId'
    | '/app/businesses-rma/business/$businessId'
    | '/app/businesses-rma/rma/$rmaId'
    | '/app/businesses-rma/business/$businessId/arc/pdf'
    | '/app/businesses-rma/business/$businessId/arc/update-shipping-price'
    | '/app/businesses-rma/business/$businessId/bill/credits'
    | '/app/businesses-rma/business/$businessId/bill/send-by-email'
    | '/app/businesses-rma/business/$businessId/bl/send-by-email'
    | '/app/businesses-rma/business/$businessId/bp/travel-voucher'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book'
    | '/app/businesses-rma/business/$businessId/dashboard/create-ged-directory'
    | '/app/businesses-rma/business/$businessId/dashboard/create-lifesheet'
    | '/app/businesses-rma/business/$businessId/dashboard/create-link'
    | '/app/businesses-rma/business/$businessId/dashboard/delete'
    | '/app/businesses-rma/business/$businessId/dashboard/email-history'
    | '/app/businesses-rma/business/$businessId/dashboard/import-ged-files'
    | '/app/businesses-rma/business/$businessId/dashboard/send-email'
    | '/app/businesses-rma/business/$businessId/dashboard/update-billing-address'
    | '/app/businesses-rma/business/$businessId/dashboard/update-representative'
    | '/app/businesses-rma/business/$businessId/dashboard/update-responsible'
    | '/app/businesses-rma/business/$businessId/quotation/commercial-notice'
    | '/app/businesses-rma/business/$businessId/quotation/pdf'
    | '/app/businesses-rma/business/$businessId/quotation/update-shipping-price'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId'
    | '/app/businesses-rma/business/$businessId/study/automatic'
    | '/app/businesses-rma/business/$businessId/study/expert'
    | '/app/businesses-rma/rma/$rmaId/delivery/create-detail'
    | '/app/businesses-rma/rma/$rmaId/delivery/pdf'
    | '/app/businesses-rma/rma/$rmaId/delivery/travel-voucher'
    | '/app/businesses-rma/rma/$rmaId/reception/create-detail'
    | '/app/businesses-rma/rma/$rmaId/reception/pdf'
    | '/app/businesses-rma/rma/$rmaId/support/create-detail'
    | '/app/businesses-rma/rma/$rmaId/support/create-ged-directory'
    | '/app/businesses-rma/rma/$rmaId/support/create-lifesheet'
    | '/app/businesses-rma/rma/$rmaId/support/create-link'
    | '/app/businesses-rma/rma/$rmaId/support/import-ged-files'
    | '/app/businesses-rma/rma/$rmaId/support/pdf'
    | '/app/enterprises/$enterpriseId/address-book/delete/$addressId'
    | '/app/enterprises/$enterpriseId/address-book/update/$addressId'
    | '/app/enterprises/$enterpriseId/email-history/email/$emailId'
    | '/app/enterprises/$enterpriseId/task-email/$taskId/reply'
    | '/app/faq/ged/$faqId/delete/$itemRelativePath'
    | '/app/faq/ged/$faqId/rename/$itemRelativePath'
    | '/app/products/$productId/informations/lifesheet-email/$lifesheetId'
    | '/app/products/$productId/informations/task-email/$taskId'
    | '/app/products/$productId/informations/unlink-task/$taskId'
    | '/app/products/$productId/manage/add-specification/$filterId'
    | '/app/products/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId'
    | '/app/products/$productId/manage/delete-specification/$specificationId'
    | '/app/products/$productId/manage/delete-stock/$stockId'
    | '/app/products/$productId/manage/delete-version/$versionId'
    | '/app/products/$productId/manage/remove-associated-product/$associatedProductId'
    | '/app/products/$productId/manage/stock-history/$stockId'
    | '/app/products/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId'
    | '/app/products/$productId/manage/update-specification/$specificationId'
    | '/app/products/$productId/manage/update-stock/$stockId'
    | '/app/products/$productId/manage/update-version/$versionId'
    | '/app/tools/formations/subscribers/$formationDetailId/create'
    | '/app/tools/formations/update/$formationId/add-detail'
    | '/app/tools/formations/update/$formationId/details'
    | '/app/tools/scheduler/details/$rdvId/delete'
    | '/app/tools/scheduler/details/$rdvId/update'
    | '/app/businesses-rma/business/$businessId/bl/update'
    | '/app/businesses-rma/business/$businessId/study'
    | '/app/businesses-rma/business/$businessId/arc/delete-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/arc/pdf/send-by-email'
    | '/app/businesses-rma/business/$businessId/arc/update-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/bill/credits/send-by-email'
    | '/app/businesses-rma/business/$businessId/bp/add-serial/$detailId'
    | '/app/businesses-rma/business/$businessId/bp/create-detail-rma/$detailId'
    | '/app/businesses-rma/business/$businessId/bp/create-serial-rma/$serialId'
    | '/app/businesses-rma/business/$businessId/bp/delete-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/bp/delete-serial/$serialId'
    | '/app/businesses-rma/business/$businessId/bp/update-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book/create'
    | '/app/businesses-rma/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId'
    | '/app/businesses-rma/business/$businessId/dashboard/delete-ged-object/$objectRelativePath'
    | '/app/businesses-rma/business/$businessId/dashboard/delete-link/$associatedId'
    | '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId'
    | '/app/businesses-rma/business/$businessId/dashboard/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma/business/$businessId/dashboard/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma/business/$businessId/dashboard/task-email/$taskId'
    | '/app/businesses-rma/business/$businessId/dashboard/unlink-task/$taskId'
    | '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email'
    | '/app/businesses-rma/business/$businessId/quotation/create-associated-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/quotation/create-detail/$subquotationId'
    | '/app/businesses-rma/business/$businessId/quotation/delete-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/quotation/delete-subquotation/$subquotationId'
    | '/app/businesses-rma/business/$businessId/quotation/pdf/send-by-email'
    | '/app/businesses-rma/business/$businessId/quotation/update-detail/$detailId'
    | '/app/businesses-rma/business/$businessId/quotation/update-subquotation/$subquotationId'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-faq'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-ged-directory'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-lifesheet'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/create-link'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-cumulated-time'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-no-billed-time'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/edit-subtitle'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/import-ged-files'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf'
    | '/app/businesses-rma/rma/$rmaId/delivery/delete-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/delivery/pdf/send-by-email'
    | '/app/businesses-rma/rma/$rmaId/delivery/update-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/reception/delete-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/reception/pdf/send-by-email'
    | '/app/businesses-rma/rma/$rmaId/reception/update-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/support/delete-detail/$detailId'
    | '/app/businesses-rma/rma/$rmaId/support/delete-ged-object/$relativePath'
    | '/app/businesses-rma/rma/$rmaId/support/delete-link/$associatedId'
    | '/app/businesses-rma/rma/$rmaId/support/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma/rma/$rmaId/support/pdf/send-by-email'
    | '/app/businesses-rma/rma/$rmaId/support/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId'
    | '/app/businesses-rma/rma/$rmaId/support/unlink-task/$taskId'
    | '/app/businesses-rma/rma/$rmaId/support/update-detail/$detailId'
    | '/app/enterprises/$enterpriseId/email-history/email/$emailId/reply'
    | '/app/products/$productId/informations/task-email/$taskId/reply'
    | '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId'
    | '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book/delete/$addressId'
    | '/app/businesses-rma/business/$businessId/dashboard/address-book/update/$addressId'
    | '/app/businesses-rma/business/$businessId/dashboard/email-history/$emailId/reply'
    | '/app/businesses-rma/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-ged-object/$objectRelativePath'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/delete-link/$associatedId'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/pdf/send-by-email'
    | '/app/businesses-rma/business/$businessId/assistance/$assistanceId/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma/rma/$rmaId/support/task-email/$taskId/reply'
  id:
    | '__root__'
    | '/'
    | '/app'
    | '/auth'
    | '/app/businesses-rma'
    | '/app/dashboard'
    | '/app/enterprises'
    | '/app/external-links'
    | '/app/faq'
    | '/app/products'
    | '/app/tools'
    | '/auth/login'
    | '/auth/forgot-password'
    | '/app/'
    | '/auth/'
    | '/app/businesses-rma/representative-turnover'
    | '/app/businesses-rma/search-by-products'
    | '/app/dashboard/create-collective-task'
    | '/app/dashboard/create-personal-task'
    | '/app/dashboard/create-progressive-info'
    | '/app/dashboard/delete-collective-tasks'
    | '/app/enterprises/create'
    | '/app/enterprises_/$enterpriseId'
    | '/app/external-links_/$externalLinkId'
    | '/app/faq/create'
    | '/app/products/create'
    | '/app/products/serial-numbers'
    | '/app/products_/$productId'
    | '/app/tools/credit'
    | '/app/tools/ddns'
    | '/app/tools/departments'
    | '/app/tools/emails'
    | '/app/tools/external-links'
    | '/app/tools/formations'
    | '/app/tools/global-turnover'
    | '/app/tools/mails'
    | '/app/tools/menu'
    | '/app/tools/news'
    | '/app/tools/predefined-messages'
    | '/app/tools/predefined-texts'
    | '/app/tools/product-filters'
    | '/app/tools/product-inventory'
    | '/app/tools/product-shelves'
    | '/app/tools/representatives-map'
    | '/app/tools/representatives-turnover'
    | '/app/tools/scheduler'
    | '/app/tools/vva'
    | '/auth/reset-password/$token'
    | '/app/tools/'
    | '/app/businesses-rma_/business/$businessId'
    | '/app/businesses-rma_/rma/$rmaId'
    | '/app/dashboard/archive-personal-task/$taskId'
    | '/app/dashboard/delete-collective-task/$taskId'
    | '/app/dashboard/delete-progressive-info/$progressiveInfoId'
    | '/app/dashboard/link-personal-task/$taskId'
    | '/app/dashboard/other-personal-tasks/$profileId'
    | '/app/dashboard/personal-task-details/$taskId'
    | '/app/dashboard/scheduler-event-details/$eventId'
    | '/app/dashboard/take-collective-task/$taskId'
    | '/app/dashboard/task-comments/$taskId'
    | '/app/dashboard/task-email/$taskId'
    | '/app/dashboard/transfer-task/$taskId'
    | '/app/dashboard/unlink-personal-task/$taskId'
    | '/app/dashboard/update-personal-task-deadline/$taskId'
    | '/app/dashboard/update-progressive-info/$progressiveInfoId'
    | '/app/dashboard/validate-personal-task/$taskId'
    | '/app/enterprises/create-contact-business/$contactId'
    | '/app/enterprises/create-contact-travel-voucher/$contactId'
    | '/app/enterprises/create-contact/$enterpriseId'
    | '/app/enterprises/create-enterprise-rma/$enterpriseId'
    | '/app/enterprises/delete-contact/$contactId'
    | '/app/enterprises/send-email-to-contact/$contactId'
    | '/app/enterprises/update-contact-password/$contactId'
    | '/app/enterprises/update-contact/$contactId'
    | '/app/enterprises_/$enterpriseId/address-book'
    | '/app/enterprises_/$enterpriseId/create-contact'
    | '/app/enterprises_/$enterpriseId/create-ged-directory'
    | '/app/enterprises_/$enterpriseId/create-lifesheet-comment'
    | '/app/enterprises_/$enterpriseId/delete'
    | '/app/enterprises_/$enterpriseId/email-history'
    | '/app/enterprises_/$enterpriseId/import-contacts'
    | '/app/enterprises_/$enterpriseId/import-ged-files'
    | '/app/enterprises_/$enterpriseId/relate-business-rma'
    | '/app/enterprises_/$enterpriseId/update'
    | '/app/enterprises_/$enterpriseId/update-accountability'
    | '/app/enterprises_/$enterpriseId/update-category'
    | '/app/enterprises_/$enterpriseId/update-representative'
    | '/app/faq/archive/$faqId'
    | '/app/faq/delete/$faqId'
    | '/app/faq/ged/$faqId'
    | '/app/faq/send-by-email/$faqId'
    | '/app/faq/update/$faqId'
    | '/app/products/serial-numbers/create'
    | '/app/products_/$productId/informations'
    | '/app/products_/$productId/manage'
    | '/app/tools/credit/details'
    | '/app/tools/credit/show'
    | '/app/tools/ddns/create'
    | '/app/tools/departments/create'
    | '/app/tools/emails/$emailId'
    | '/app/tools/external-links/create'
    | '/app/tools/formations/create'
    | '/app/tools/mails/create'
    | '/app/tools/menu/create-enterprise'
    | '/app/tools/menu/create-product'
    | '/app/tools/news/create'
    | '/app/tools/predefined-messages/create'
    | '/app/tools/predefined-texts/create'
    | '/app/tools/product-filters/create'
    | '/app/tools/product-inventory/validate-quantities'
    | '/app/tools/product-shelves/create'
    | '/app/tools/scheduler/create'
    | '/app/tools/vva/create'
    | '/app/products_/$productId/'
    | '/app/businesses-rma_/business/$businessId/arc'
    | '/app/businesses-rma_/business/$businessId/bill'
    | '/app/businesses-rma_/business/$businessId/bl'
    | '/app/businesses-rma_/business/$businessId/bp'
    | '/app/businesses-rma_/business/$businessId/dashboard'
    | '/app/businesses-rma_/business/$businessId/quotation'
    | '/app/businesses-rma_/business/$businessId_/study'
    | '/app/businesses-rma_/rma/$rmaId/delivery'
    | '/app/businesses-rma_/rma/$rmaId/reception'
    | '/app/businesses-rma_/rma/$rmaId/support'
    | '/app/dashboard/task-email/$taskId/reply'
    | '/app/enterprises_/$enterpriseId/address-book/create'
    | '/app/enterprises_/$enterpriseId/address-book/import'
    | '/app/enterprises_/$enterpriseId/create-contact-business/$contactId'
    | '/app/enterprises_/$enterpriseId/delete-contact/$contactId'
    | '/app/enterprises_/$enterpriseId/delete-ged-object/$objectRelativePath'
    | '/app/enterprises_/$enterpriseId/lifesheet-email/$lifesheetId'
    | '/app/enterprises_/$enterpriseId/rename-ged-object/$objectRelativePath'
    | '/app/enterprises_/$enterpriseId/send-email-to-contact/$contactId'
    | '/app/enterprises_/$enterpriseId/task-email/$taskId'
    | '/app/enterprises_/$enterpriseId/unlink-task/$taskId'
    | '/app/enterprises_/$enterpriseId/unrelate-business-rma/$businessRmaId'
    | '/app/enterprises_/$enterpriseId/update-contact-password/$contactId'
    | '/app/enterprises_/$enterpriseId/update-contact/$contactId'
    | '/app/faq/ged/$faqId/create-directory'
    | '/app/faq/ged/$faqId/import-files'
    | '/app/products/serial-numbers/create-rma/$serialNumberId'
    | '/app/products/serial-numbers/delete/$serialNumberId'
    | '/app/products/serial-numbers/remove-from-business/$serialNumberId'
    | '/app/products/serial-numbers/update/$serialNumberId'
    | '/app/products_/$productId/informations/create-ged-directory'
    | '/app/products_/$productId/informations/create-lifesheet-comment'
    | '/app/products_/$productId/informations/delete-ged-object'
    | '/app/products_/$productId/informations/import-ged-files'
    | '/app/products_/$productId/informations/rename-ged-object'
    | '/app/products_/$productId/manage/add-associated-product'
    | '/app/products_/$productId/manage/add-nomenclature-detail'
    | '/app/products_/$productId/manage/add-specification'
    | '/app/products_/$productId/manage/create-stock'
    | '/app/products_/$productId/manage/create-version'
    | '/app/tools/ddns/delete/$ddnsId'
    | '/app/tools/departments/delete/$departmentId'
    | '/app/tools/departments/update/$departmentId'
    | '/app/tools/emails/$emailId/reply'
    | '/app/tools/external-links/archive/$externalLinkId'
    | '/app/tools/external-links/delete/$externalLinkId'
    | '/app/tools/external-links/update/$externalLinkId'
    | '/app/tools/formations/create/add-detail'
    | '/app/tools/formations/create/details'
    | '/app/tools/formations/delete/$formationId'
    | '/app/tools/formations/subscribers/$formationDetailId'
    | '/app/tools/formations/update/$formationId'
    | '/app/tools/mails/delete/$mailId'
    | '/app/tools/mails/show/$mailId'
    | '/app/tools/mails/update/$mailId'
    | '/app/tools/news/delete/$newsId'
    | '/app/tools/news/update/$newsId'
    | '/app/tools/predefined-messages/delete/$predefinedMessageId'
    | '/app/tools/predefined-messages/update/$predefinedMessageId'
    | '/app/tools/predefined-texts/delete/$predefinedTextId'
    | '/app/tools/predefined-texts/update/$predefinedTextId'
    | '/app/tools/product-filters/delete/$productFilterId'
    | '/app/tools/product-filters/update/$productFilterId'
    | '/app/tools/product-inventory/update/$stockId'
    | '/app/tools/product-shelves/delete/$productShelfId'
    | '/app/tools/scheduler/details/$rdvId'
    | '/app/tools/vva/delete/$vvaId'
    | '/app/businesses-rma_/business/$businessId/'
    | '/app/businesses-rma_/rma/$rmaId/'
    | '/app/businesses-rma_/business/$businessId/arc/pdf'
    | '/app/businesses-rma_/business/$businessId/arc/update-shipping-price'
    | '/app/businesses-rma_/business/$businessId/bill/credits'
    | '/app/businesses-rma_/business/$businessId/bill/send-by-email'
    | '/app/businesses-rma_/business/$businessId/bl/send-by-email'
    | '/app/businesses-rma_/business/$businessId/bp/travel-voucher'
    | '/app/businesses-rma_/business/$businessId/dashboard/address-book'
    | '/app/businesses-rma_/business/$businessId/dashboard/create-ged-directory'
    | '/app/businesses-rma_/business/$businessId/dashboard/create-lifesheet'
    | '/app/businesses-rma_/business/$businessId/dashboard/create-link'
    | '/app/businesses-rma_/business/$businessId/dashboard/delete'
    | '/app/businesses-rma_/business/$businessId/dashboard/email-history'
    | '/app/businesses-rma_/business/$businessId/dashboard/import-ged-files'
    | '/app/businesses-rma_/business/$businessId/dashboard/send-email'
    | '/app/businesses-rma_/business/$businessId/dashboard/update-billing-address'
    | '/app/businesses-rma_/business/$businessId/dashboard/update-representative'
    | '/app/businesses-rma_/business/$businessId/dashboard/update-responsible'
    | '/app/businesses-rma_/business/$businessId/quotation/commercial-notice'
    | '/app/businesses-rma_/business/$businessId/quotation/pdf'
    | '/app/businesses-rma_/business/$businessId/quotation/update-shipping-price'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId'
    | '/app/businesses-rma_/business/$businessId_/study/automatic'
    | '/app/businesses-rma_/business/$businessId_/study/expert'
    | '/app/businesses-rma_/rma/$rmaId/delivery/create-detail'
    | '/app/businesses-rma_/rma/$rmaId/delivery/pdf'
    | '/app/businesses-rma_/rma/$rmaId/delivery/travel-voucher'
    | '/app/businesses-rma_/rma/$rmaId/reception/create-detail'
    | '/app/businesses-rma_/rma/$rmaId/reception/pdf'
    | '/app/businesses-rma_/rma/$rmaId/support/create-detail'
    | '/app/businesses-rma_/rma/$rmaId/support/create-ged-directory'
    | '/app/businesses-rma_/rma/$rmaId/support/create-lifesheet'
    | '/app/businesses-rma_/rma/$rmaId/support/create-link'
    | '/app/businesses-rma_/rma/$rmaId/support/import-ged-files'
    | '/app/businesses-rma_/rma/$rmaId/support/pdf'
    | '/app/enterprises_/$enterpriseId/address-book/delete/$addressId'
    | '/app/enterprises_/$enterpriseId/address-book/update/$addressId'
    | '/app/enterprises_/$enterpriseId/email-history/email/$emailId'
    | '/app/enterprises_/$enterpriseId/task-email/$taskId/reply'
    | '/app/faq/ged/$faqId/delete/$itemRelativePath'
    | '/app/faq/ged/$faqId/rename/$itemRelativePath'
    | '/app/products_/$productId/informations/lifesheet-email/$lifesheetId'
    | '/app/products_/$productId/informations/task-email/$taskId'
    | '/app/products_/$productId/informations/unlink-task/$taskId'
    | '/app/products_/$productId/manage/add-specification/$filterId'
    | '/app/products_/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId'
    | '/app/products_/$productId/manage/delete-specification/$specificationId'
    | '/app/products_/$productId/manage/delete-stock/$stockId'
    | '/app/products_/$productId/manage/delete-version/$versionId'
    | '/app/products_/$productId/manage/remove-associated-product/$associatedProductId'
    | '/app/products_/$productId/manage/stock-history/$stockId'
    | '/app/products_/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId'
    | '/app/products_/$productId/manage/update-specification/$specificationId'
    | '/app/products_/$productId/manage/update-stock/$stockId'
    | '/app/products_/$productId/manage/update-version/$versionId'
    | '/app/tools/formations/subscribers/$formationDetailId/create'
    | '/app/tools/formations/update/$formationId/add-detail'
    | '/app/tools/formations/update/$formationId/details'
    | '/app/tools/scheduler/details/$rdvId/delete'
    | '/app/tools/scheduler/details/$rdvId/update'
    | '/app/businesses-rma_/business/$businessId/bl/update'
    | '/app/businesses-rma_/business/$businessId_/study/'
    | '/app/businesses-rma_/business/$businessId/arc/delete-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/arc/pdf/send-by-email'
    | '/app/businesses-rma_/business/$businessId/arc/update-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/bill/credits/send-by-email'
    | '/app/businesses-rma_/business/$businessId/bp/add-serial/$detailId'
    | '/app/businesses-rma_/business/$businessId/bp/create-detail-rma/$detailId'
    | '/app/businesses-rma_/business/$businessId/bp/create-serial-rma/$serialId'
    | '/app/businesses-rma_/business/$businessId/bp/delete-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/bp/delete-serial/$serialId'
    | '/app/businesses-rma_/business/$businessId/bp/update-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/dashboard/address-book/create'
    | '/app/businesses-rma_/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId'
    | '/app/businesses-rma_/business/$businessId/dashboard/delete-ged-object/$objectRelativePath'
    | '/app/businesses-rma_/business/$businessId/dashboard/delete-link/$associatedId'
    | '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId'
    | '/app/businesses-rma_/business/$businessId/dashboard/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma_/business/$businessId/dashboard/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma_/business/$businessId/dashboard/task-email/$taskId'
    | '/app/businesses-rma_/business/$businessId/dashboard/unlink-task/$taskId'
    | '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email'
    | '/app/businesses-rma_/business/$businessId/quotation/create-associated-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/quotation/create-detail/$subquotationId'
    | '/app/businesses-rma_/business/$businessId/quotation/delete-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/quotation/delete-subquotation/$subquotationId'
    | '/app/businesses-rma_/business/$businessId/quotation/pdf/send-by-email'
    | '/app/businesses-rma_/business/$businessId/quotation/update-detail/$detailId'
    | '/app/businesses-rma_/business/$businessId/quotation/update-subquotation/$subquotationId'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-faq'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-ged-directory'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-lifesheet'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-link'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-cumulated-time'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-no-billed-time'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-subtitle'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/import-ged-files'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf'
    | '/app/businesses-rma_/rma/$rmaId/delivery/delete-detail/$detailId'
    | '/app/businesses-rma_/rma/$rmaId/delivery/pdf/send-by-email'
    | '/app/businesses-rma_/rma/$rmaId/delivery/update-detail/$detailId'
    | '/app/businesses-rma_/rma/$rmaId/reception/delete-detail/$detailId'
    | '/app/businesses-rma_/rma/$rmaId/reception/pdf/send-by-email'
    | '/app/businesses-rma_/rma/$rmaId/reception/update-detail/$detailId'
    | '/app/businesses-rma_/rma/$rmaId/support/delete-detail/$detailId'
    | '/app/businesses-rma_/rma/$rmaId/support/delete-ged-object/$relativePath'
    | '/app/businesses-rma_/rma/$rmaId/support/delete-link/$associatedId'
    | '/app/businesses-rma_/rma/$rmaId/support/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma_/rma/$rmaId/support/pdf/send-by-email'
    | '/app/businesses-rma_/rma/$rmaId/support/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId'
    | '/app/businesses-rma_/rma/$rmaId/support/unlink-task/$taskId'
    | '/app/businesses-rma_/rma/$rmaId/support/update-detail/$detailId'
    | '/app/enterprises_/$enterpriseId/email-history/email/$emailId/reply'
    | '/app/products_/$productId/informations/task-email/$taskId/reply'
    | '/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId'
    | '/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId'
    | '/app/businesses-rma_/business/$businessId/dashboard/address-book/delete/$addressId'
    | '/app/businesses-rma_/business/$businessId/dashboard/address-book/update/$addressId'
    | '/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId/reply'
    | '/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-ged-object/$objectRelativePath'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-link/$associatedId'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/lifesheet-email/$lifesheetId'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf/send-by-email'
    | '/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/rename-ged-object/$objectRelativePath'
    | '/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId/reply'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRouteRoute: typeof AppRouteRouteWithChildren
  AuthRouteRoute: typeof AuthRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRouteRoute: AppRouteRouteWithChildren,
  AuthRouteRoute: AuthRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.ts",
      "children": [
        "/",
        "/app",
        "/auth"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/app": {
      "filePath": "app/route.ts",
      "children": [
        "/app/businesses-rma",
        "/app/dashboard",
        "/app/enterprises",
        "/app/external-links",
        "/app/faq",
        "/app/products",
        "/app/tools",
        "/app/",
        "/app/enterprises_/$enterpriseId",
        "/app/external-links_/$externalLinkId",
        "/app/products_/$productId",
        "/app/businesses-rma_/business/$businessId",
        "/app/businesses-rma_/rma/$rmaId",
        "/app/businesses-rma_/business/$businessId_/study",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
      ]
    },
    "/auth": {
      "filePath": "auth/route.ts",
      "children": [
        "/auth/login",
        "/auth/forgot-password",
        "/auth/",
        "/auth/reset-password/$token"
      ]
    },
    "/app/businesses-rma": {
      "filePath": "app/businesses-rma/route.tsx",
      "parent": "/app",
      "children": [
        "/app/businesses-rma/representative-turnover",
        "/app/businesses-rma/search-by-products"
      ]
    },
    "/app/dashboard": {
      "filePath": "app/dashboard/route.ts",
      "parent": "/app",
      "children": [
        "/app/dashboard/create-collective-task",
        "/app/dashboard/create-personal-task",
        "/app/dashboard/create-progressive-info",
        "/app/dashboard/delete-collective-tasks",
        "/app/dashboard/archive-personal-task/$taskId",
        "/app/dashboard/delete-collective-task/$taskId",
        "/app/dashboard/delete-progressive-info/$progressiveInfoId",
        "/app/dashboard/link-personal-task/$taskId",
        "/app/dashboard/other-personal-tasks/$profileId",
        "/app/dashboard/personal-task-details/$taskId",
        "/app/dashboard/scheduler-event-details/$eventId",
        "/app/dashboard/take-collective-task/$taskId",
        "/app/dashboard/task-comments/$taskId",
        "/app/dashboard/task-email/$taskId",
        "/app/dashboard/transfer-task/$taskId",
        "/app/dashboard/unlink-personal-task/$taskId",
        "/app/dashboard/update-personal-task-deadline/$taskId",
        "/app/dashboard/update-progressive-info/$progressiveInfoId",
        "/app/dashboard/validate-personal-task/$taskId"
      ]
    },
    "/app/enterprises": {
      "filePath": "app/enterprises/route.ts",
      "parent": "/app",
      "children": [
        "/app/enterprises/create",
        "/app/enterprises/create-contact-business/$contactId",
        "/app/enterprises/create-contact-travel-voucher/$contactId",
        "/app/enterprises/create-contact/$enterpriseId",
        "/app/enterprises/create-enterprise-rma/$enterpriseId",
        "/app/enterprises/delete-contact/$contactId",
        "/app/enterprises/send-email-to-contact/$contactId",
        "/app/enterprises/update-contact-password/$contactId",
        "/app/enterprises/update-contact/$contactId"
      ]
    },
    "/app/external-links": {
      "filePath": "app/external-links/route.ts",
      "parent": "/app"
    },
    "/app/faq": {
      "filePath": "app/faq/route.ts",
      "parent": "/app",
      "children": [
        "/app/faq/create",
        "/app/faq/archive/$faqId",
        "/app/faq/delete/$faqId",
        "/app/faq/ged/$faqId",
        "/app/faq/send-by-email/$faqId",
        "/app/faq/update/$faqId"
      ]
    },
    "/app/products": {
      "filePath": "app/products/route.ts",
      "parent": "/app",
      "children": [
        "/app/products/create",
        "/app/products/serial-numbers"
      ]
    },
    "/app/tools": {
      "filePath": "app/tools/route.ts",
      "parent": "/app",
      "children": [
        "/app/tools/credit",
        "/app/tools/ddns",
        "/app/tools/departments",
        "/app/tools/emails",
        "/app/tools/external-links",
        "/app/tools/formations",
        "/app/tools/global-turnover",
        "/app/tools/mails",
        "/app/tools/menu",
        "/app/tools/news",
        "/app/tools/predefined-messages",
        "/app/tools/predefined-texts",
        "/app/tools/product-filters",
        "/app/tools/product-inventory",
        "/app/tools/product-shelves",
        "/app/tools/representatives-map",
        "/app/tools/representatives-turnover",
        "/app/tools/scheduler",
        "/app/tools/vva",
        "/app/tools/"
      ]
    },
    "/auth/login": {
      "filePath": "auth/login/route.ts",
      "parent": "/auth"
    },
    "/auth/forgot-password": {
      "filePath": "auth/forgot-password/route.lazy.ts",
      "parent": "/auth"
    },
    "/app/": {
      "filePath": "app/index.ts",
      "parent": "/app"
    },
    "/auth/": {
      "filePath": "auth/index.ts",
      "parent": "/auth"
    },
    "/app/businesses-rma/representative-turnover": {
      "filePath": "app/businesses-rma/representative-turnover/route.tsx",
      "parent": "/app/businesses-rma"
    },
    "/app/businesses-rma/search-by-products": {
      "filePath": "app/businesses-rma/search-by-products/route.ts",
      "parent": "/app/businesses-rma"
    },
    "/app/dashboard/create-collective-task": {
      "filePath": "app/dashboard/create-collective-task/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/create-personal-task": {
      "filePath": "app/dashboard/create-personal-task/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/create-progressive-info": {
      "filePath": "app/dashboard/create-progressive-info/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/delete-collective-tasks": {
      "filePath": "app/dashboard/delete-collective-tasks/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/enterprises/create": {
      "filePath": "app/enterprises/create/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises_/$enterpriseId": {
      "filePath": "app/enterprises_.$enterpriseId/route.ts",
      "parent": "/app",
      "children": [
        "/app/enterprises_/$enterpriseId/address-book",
        "/app/enterprises_/$enterpriseId/create-contact",
        "/app/enterprises_/$enterpriseId/create-ged-directory",
        "/app/enterprises_/$enterpriseId/create-lifesheet-comment",
        "/app/enterprises_/$enterpriseId/delete",
        "/app/enterprises_/$enterpriseId/email-history",
        "/app/enterprises_/$enterpriseId/import-contacts",
        "/app/enterprises_/$enterpriseId/import-ged-files",
        "/app/enterprises_/$enterpriseId/relate-business-rma",
        "/app/enterprises_/$enterpriseId/update",
        "/app/enterprises_/$enterpriseId/update-accountability",
        "/app/enterprises_/$enterpriseId/update-category",
        "/app/enterprises_/$enterpriseId/update-representative",
        "/app/enterprises_/$enterpriseId/create-contact-business/$contactId",
        "/app/enterprises_/$enterpriseId/delete-contact/$contactId",
        "/app/enterprises_/$enterpriseId/delete-ged-object/$objectRelativePath",
        "/app/enterprises_/$enterpriseId/lifesheet-email/$lifesheetId",
        "/app/enterprises_/$enterpriseId/rename-ged-object/$objectRelativePath",
        "/app/enterprises_/$enterpriseId/send-email-to-contact/$contactId",
        "/app/enterprises_/$enterpriseId/task-email/$taskId",
        "/app/enterprises_/$enterpriseId/unlink-task/$taskId",
        "/app/enterprises_/$enterpriseId/unrelate-business-rma/$businessRmaId",
        "/app/enterprises_/$enterpriseId/update-contact-password/$contactId",
        "/app/enterprises_/$enterpriseId/update-contact/$contactId"
      ]
    },
    "/app/external-links_/$externalLinkId": {
      "filePath": "app/external-links_.$externalLinkId/route.ts",
      "parent": "/app"
    },
    "/app/faq/create": {
      "filePath": "app/faq/create/route.ts",
      "parent": "/app/faq"
    },
    "/app/products/create": {
      "filePath": "app/products/create/route.ts",
      "parent": "/app/products"
    },
    "/app/products/serial-numbers": {
      "filePath": "app/products/serial-numbers/route.ts",
      "parent": "/app/products",
      "children": [
        "/app/products/serial-numbers/create",
        "/app/products/serial-numbers/create-rma/$serialNumberId",
        "/app/products/serial-numbers/delete/$serialNumberId",
        "/app/products/serial-numbers/remove-from-business/$serialNumberId",
        "/app/products/serial-numbers/update/$serialNumberId"
      ]
    },
    "/app/products_/$productId": {
      "filePath": "app/products_.$productId/route.ts",
      "parent": "/app",
      "children": [
        "/app/products_/$productId/informations",
        "/app/products_/$productId/manage",
        "/app/products_/$productId/"
      ]
    },
    "/app/tools/credit": {
      "filePath": "app/tools/credit/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/credit/details",
        "/app/tools/credit/show"
      ]
    },
    "/app/tools/ddns": {
      "filePath": "app/tools/ddns/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/ddns/create",
        "/app/tools/ddns/delete/$ddnsId"
      ]
    },
    "/app/tools/departments": {
      "filePath": "app/tools/departments/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/departments/create",
        "/app/tools/departments/delete/$departmentId",
        "/app/tools/departments/update/$departmentId"
      ]
    },
    "/app/tools/emails": {
      "filePath": "app/tools/emails/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/emails/$emailId"
      ]
    },
    "/app/tools/external-links": {
      "filePath": "app/tools/external-links/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/external-links/create",
        "/app/tools/external-links/archive/$externalLinkId",
        "/app/tools/external-links/delete/$externalLinkId",
        "/app/tools/external-links/update/$externalLinkId"
      ]
    },
    "/app/tools/formations": {
      "filePath": "app/tools/formations/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/formations/create",
        "/app/tools/formations/delete/$formationId",
        "/app/tools/formations/subscribers/$formationDetailId",
        "/app/tools/formations/update/$formationId"
      ]
    },
    "/app/tools/global-turnover": {
      "filePath": "app/tools/global-turnover/route.ts",
      "parent": "/app/tools"
    },
    "/app/tools/mails": {
      "filePath": "app/tools/mails/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/mails/create",
        "/app/tools/mails/delete/$mailId",
        "/app/tools/mails/show/$mailId",
        "/app/tools/mails/update/$mailId"
      ]
    },
    "/app/tools/menu": {
      "filePath": "app/tools/menu/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/menu/create-enterprise",
        "/app/tools/menu/create-product"
      ]
    },
    "/app/tools/news": {
      "filePath": "app/tools/news/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/news/create",
        "/app/tools/news/delete/$newsId",
        "/app/tools/news/update/$newsId"
      ]
    },
    "/app/tools/predefined-messages": {
      "filePath": "app/tools/predefined-messages/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/predefined-messages/create",
        "/app/tools/predefined-messages/delete/$predefinedMessageId",
        "/app/tools/predefined-messages/update/$predefinedMessageId"
      ]
    },
    "/app/tools/predefined-texts": {
      "filePath": "app/tools/predefined-texts/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/predefined-texts/create",
        "/app/tools/predefined-texts/delete/$predefinedTextId",
        "/app/tools/predefined-texts/update/$predefinedTextId"
      ]
    },
    "/app/tools/product-filters": {
      "filePath": "app/tools/product-filters/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/product-filters/create",
        "/app/tools/product-filters/delete/$productFilterId",
        "/app/tools/product-filters/update/$productFilterId"
      ]
    },
    "/app/tools/product-inventory": {
      "filePath": "app/tools/product-inventory/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/product-inventory/validate-quantities",
        "/app/tools/product-inventory/update/$stockId"
      ]
    },
    "/app/tools/product-shelves": {
      "filePath": "app/tools/product-shelves/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/product-shelves/create",
        "/app/tools/product-shelves/delete/$productShelfId"
      ]
    },
    "/app/tools/representatives-map": {
      "filePath": "app/tools/representatives-map/route.ts",
      "parent": "/app/tools"
    },
    "/app/tools/representatives-turnover": {
      "filePath": "app/tools/representatives-turnover/route.ts",
      "parent": "/app/tools"
    },
    "/app/tools/scheduler": {
      "filePath": "app/tools/scheduler/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/scheduler/create",
        "/app/tools/scheduler/details/$rdvId"
      ]
    },
    "/app/tools/vva": {
      "filePath": "app/tools/vva/route.ts",
      "parent": "/app/tools",
      "children": [
        "/app/tools/vva/create",
        "/app/tools/vva/delete/$vvaId"
      ]
    },
    "/auth/reset-password/$token": {
      "filePath": "auth/reset-password.$token/route.lazy.ts",
      "parent": "/auth"
    },
    "/app/tools/": {
      "filePath": "app/tools/index.ts",
      "parent": "/app/tools"
    },
    "/app/businesses-rma_/business/$businessId": {
      "filePath": "app/businesses-rma_/business.$businessId/route.ts",
      "parent": "/app",
      "children": [
        "/app/businesses-rma_/business/$businessId/arc",
        "/app/businesses-rma_/business/$businessId/bill",
        "/app/businesses-rma_/business/$businessId/bl",
        "/app/businesses-rma_/business/$businessId/bp",
        "/app/businesses-rma_/business/$businessId/dashboard",
        "/app/businesses-rma_/business/$businessId/quotation",
        "/app/businesses-rma_/business/$businessId/"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/route.ts",
      "parent": "/app",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/delivery",
        "/app/businesses-rma_/rma/$rmaId/reception",
        "/app/businesses-rma_/rma/$rmaId/support",
        "/app/businesses-rma_/rma/$rmaId/"
      ]
    },
    "/app/dashboard/archive-personal-task/$taskId": {
      "filePath": "app/dashboard/archive-personal-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/delete-collective-task/$taskId": {
      "filePath": "app/dashboard/delete-collective-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/delete-progressive-info/$progressiveInfoId": {
      "filePath": "app/dashboard/delete-progressive-info.$progressiveInfoId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/link-personal-task/$taskId": {
      "filePath": "app/dashboard/link-personal-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/other-personal-tasks/$profileId": {
      "filePath": "app/dashboard/other-personal-tasks.$profileId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/personal-task-details/$taskId": {
      "filePath": "app/dashboard/personal-task-details.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/scheduler-event-details/$eventId": {
      "filePath": "app/dashboard/scheduler-event-details.$eventId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/take-collective-task/$taskId": {
      "filePath": "app/dashboard/take-collective-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/task-comments/$taskId": {
      "filePath": "app/dashboard/task-comments.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/task-email/$taskId": {
      "filePath": "app/dashboard/task-email.$taskId/route.ts",
      "parent": "/app/dashboard",
      "children": [
        "/app/dashboard/task-email/$taskId/reply"
      ]
    },
    "/app/dashboard/transfer-task/$taskId": {
      "filePath": "app/dashboard/transfer-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/unlink-personal-task/$taskId": {
      "filePath": "app/dashboard/unlink-personal-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/update-personal-task-deadline/$taskId": {
      "filePath": "app/dashboard/update-personal-task-deadline.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/update-progressive-info/$progressiveInfoId": {
      "filePath": "app/dashboard/update-progressive-info.$progressiveInfoId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/dashboard/validate-personal-task/$taskId": {
      "filePath": "app/dashboard/validate-personal-task.$taskId/route.ts",
      "parent": "/app/dashboard"
    },
    "/app/enterprises/create-contact-business/$contactId": {
      "filePath": "app/enterprises/create-contact-business.$contactId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/create-contact-travel-voucher/$contactId": {
      "filePath": "app/enterprises/create-contact-travel-voucher.$contactId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/create-contact/$enterpriseId": {
      "filePath": "app/enterprises/create-contact.$enterpriseId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/create-enterprise-rma/$enterpriseId": {
      "filePath": "app/enterprises/create-enterprise-rma.$enterpriseId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/delete-contact/$contactId": {
      "filePath": "app/enterprises/delete-contact.$contactId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/send-email-to-contact/$contactId": {
      "filePath": "app/enterprises/send-email-to-contact.$contactId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/update-contact-password/$contactId": {
      "filePath": "app/enterprises/update-contact-password.$contactId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises/update-contact/$contactId": {
      "filePath": "app/enterprises/update-contact.$contactId/route.ts",
      "parent": "/app/enterprises"
    },
    "/app/enterprises_/$enterpriseId/address-book": {
      "filePath": "app/enterprises_.$enterpriseId/address-book/route.ts",
      "parent": "/app/enterprises_/$enterpriseId",
      "children": [
        "/app/enterprises_/$enterpriseId/address-book/create",
        "/app/enterprises_/$enterpriseId/address-book/import",
        "/app/enterprises_/$enterpriseId/address-book/delete/$addressId",
        "/app/enterprises_/$enterpriseId/address-book/update/$addressId"
      ]
    },
    "/app/enterprises_/$enterpriseId/create-contact": {
      "filePath": "app/enterprises_.$enterpriseId/create-contact/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/create-ged-directory": {
      "filePath": "app/enterprises_.$enterpriseId/create-ged-directory/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/create-lifesheet-comment": {
      "filePath": "app/enterprises_.$enterpriseId/create-lifesheet-comment/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/delete": {
      "filePath": "app/enterprises_.$enterpriseId/delete/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/email-history": {
      "filePath": "app/enterprises_.$enterpriseId/email-history/route.ts",
      "parent": "/app/enterprises_/$enterpriseId",
      "children": [
        "/app/enterprises_/$enterpriseId/email-history/email/$emailId"
      ]
    },
    "/app/enterprises_/$enterpriseId/import-contacts": {
      "filePath": "app/enterprises_.$enterpriseId/import-contacts/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/import-ged-files": {
      "filePath": "app/enterprises_.$enterpriseId/import-ged-files/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/relate-business-rma": {
      "filePath": "app/enterprises_.$enterpriseId/relate-business-rma/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/update": {
      "filePath": "app/enterprises_.$enterpriseId/update/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/update-accountability": {
      "filePath": "app/enterprises_.$enterpriseId/update-accountability/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/update-category": {
      "filePath": "app/enterprises_.$enterpriseId/update-category/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/update-representative": {
      "filePath": "app/enterprises_.$enterpriseId/update-representative/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/faq/archive/$faqId": {
      "filePath": "app/faq/archive.$faqId/route.ts",
      "parent": "/app/faq"
    },
    "/app/faq/delete/$faqId": {
      "filePath": "app/faq/delete.$faqId/route.ts",
      "parent": "/app/faq"
    },
    "/app/faq/ged/$faqId": {
      "filePath": "app/faq/ged.$faqId/route.ts",
      "parent": "/app/faq",
      "children": [
        "/app/faq/ged/$faqId/create-directory",
        "/app/faq/ged/$faqId/import-files",
        "/app/faq/ged/$faqId/delete/$itemRelativePath",
        "/app/faq/ged/$faqId/rename/$itemRelativePath"
      ]
    },
    "/app/faq/send-by-email/$faqId": {
      "filePath": "app/faq/send-by-email.$faqId/route.ts",
      "parent": "/app/faq"
    },
    "/app/faq/update/$faqId": {
      "filePath": "app/faq/update.$faqId/route.ts",
      "parent": "/app/faq"
    },
    "/app/products/serial-numbers/create": {
      "filePath": "app/products/serial-numbers/create/route.ts",
      "parent": "/app/products/serial-numbers"
    },
    "/app/products_/$productId/informations": {
      "filePath": "app/products_.$productId/informations/route.ts",
      "parent": "/app/products_/$productId",
      "children": [
        "/app/products_/$productId/informations/create-ged-directory",
        "/app/products_/$productId/informations/create-lifesheet-comment",
        "/app/products_/$productId/informations/delete-ged-object",
        "/app/products_/$productId/informations/import-ged-files",
        "/app/products_/$productId/informations/rename-ged-object",
        "/app/products_/$productId/informations/lifesheet-email/$lifesheetId",
        "/app/products_/$productId/informations/task-email/$taskId",
        "/app/products_/$productId/informations/unlink-task/$taskId"
      ]
    },
    "/app/products_/$productId/manage": {
      "filePath": "app/products_.$productId/manage/route.ts",
      "parent": "/app/products_/$productId",
      "children": [
        "/app/products_/$productId/manage/add-associated-product",
        "/app/products_/$productId/manage/add-nomenclature-detail",
        "/app/products_/$productId/manage/add-specification",
        "/app/products_/$productId/manage/create-stock",
        "/app/products_/$productId/manage/create-version",
        "/app/products_/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId",
        "/app/products_/$productId/manage/delete-specification/$specificationId",
        "/app/products_/$productId/manage/delete-stock/$stockId",
        "/app/products_/$productId/manage/delete-version/$versionId",
        "/app/products_/$productId/manage/remove-associated-product/$associatedProductId",
        "/app/products_/$productId/manage/stock-history/$stockId",
        "/app/products_/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId",
        "/app/products_/$productId/manage/update-specification/$specificationId",
        "/app/products_/$productId/manage/update-stock/$stockId",
        "/app/products_/$productId/manage/update-version/$versionId"
      ]
    },
    "/app/tools/credit/details": {
      "filePath": "app/tools/credit/details/route.ts",
      "parent": "/app/tools/credit"
    },
    "/app/tools/credit/show": {
      "filePath": "app/tools/credit/show/route.ts",
      "parent": "/app/tools/credit"
    },
    "/app/tools/ddns/create": {
      "filePath": "app/tools/ddns/create/route.ts",
      "parent": "/app/tools/ddns"
    },
    "/app/tools/departments/create": {
      "filePath": "app/tools/departments/create/route.ts",
      "parent": "/app/tools/departments"
    },
    "/app/tools/emails/$emailId": {
      "filePath": "app/tools/emails/$emailId/route.ts",
      "parent": "/app/tools/emails",
      "children": [
        "/app/tools/emails/$emailId/reply"
      ]
    },
    "/app/tools/external-links/create": {
      "filePath": "app/tools/external-links/create/route.ts",
      "parent": "/app/tools/external-links"
    },
    "/app/tools/formations/create": {
      "filePath": "app/tools/formations/create/route.ts",
      "parent": "/app/tools/formations",
      "children": [
        "/app/tools/formations/create/add-detail",
        "/app/tools/formations/create/details"
      ]
    },
    "/app/tools/mails/create": {
      "filePath": "app/tools/mails/create/route.ts",
      "parent": "/app/tools/mails"
    },
    "/app/tools/menu/create-enterprise": {
      "filePath": "app/tools/menu/create-enterprise/route.ts",
      "parent": "/app/tools/menu"
    },
    "/app/tools/menu/create-product": {
      "filePath": "app/tools/menu/create-product/route.ts",
      "parent": "/app/tools/menu"
    },
    "/app/tools/news/create": {
      "filePath": "app/tools/news/create/route.ts",
      "parent": "/app/tools/news"
    },
    "/app/tools/predefined-messages/create": {
      "filePath": "app/tools/predefined-messages/create/route.ts",
      "parent": "/app/tools/predefined-messages"
    },
    "/app/tools/predefined-texts/create": {
      "filePath": "app/tools/predefined-texts/create/route.ts",
      "parent": "/app/tools/predefined-texts"
    },
    "/app/tools/product-filters/create": {
      "filePath": "app/tools/product-filters/create/route.ts",
      "parent": "/app/tools/product-filters"
    },
    "/app/tools/product-inventory/validate-quantities": {
      "filePath": "app/tools/product-inventory/validate-quantities/route.ts",
      "parent": "/app/tools/product-inventory"
    },
    "/app/tools/product-shelves/create": {
      "filePath": "app/tools/product-shelves/create/route.ts",
      "parent": "/app/tools/product-shelves"
    },
    "/app/tools/scheduler/create": {
      "filePath": "app/tools/scheduler/create/route.ts",
      "parent": "/app/tools/scheduler"
    },
    "/app/tools/vva/create": {
      "filePath": "app/tools/vva/create/route.ts",
      "parent": "/app/tools/vva"
    },
    "/app/products_/$productId/": {
      "filePath": "app/products_.$productId/index.ts",
      "parent": "/app/products_/$productId"
    },
    "/app/businesses-rma_/business/$businessId/arc": {
      "filePath": "app/businesses-rma_/business.$businessId/arc/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId",
      "children": [
        "/app/businesses-rma_/business/$businessId/arc/pdf",
        "/app/businesses-rma_/business/$businessId/arc/update-shipping-price",
        "/app/businesses-rma_/business/$businessId/arc/delete-detail/$detailId",
        "/app/businesses-rma_/business/$businessId/arc/update-detail/$detailId"
      ]
    },
    "/app/businesses-rma_/business/$businessId/bill": {
      "filePath": "app/businesses-rma_/business.$businessId/bill/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId",
      "children": [
        "/app/businesses-rma_/business/$businessId/bill/credits",
        "/app/businesses-rma_/business/$businessId/bill/send-by-email"
      ]
    },
    "/app/businesses-rma_/business/$businessId/bl": {
      "filePath": "app/businesses-rma_/business.$businessId/bl/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId",
      "children": [
        "/app/businesses-rma_/business/$businessId/bl/send-by-email",
        "/app/businesses-rma_/business/$businessId/bl/update"
      ]
    },
    "/app/businesses-rma_/business/$businessId/bp": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId",
      "children": [
        "/app/businesses-rma_/business/$businessId/bp/travel-voucher",
        "/app/businesses-rma_/business/$businessId/bp/add-serial/$detailId",
        "/app/businesses-rma_/business/$businessId/bp/create-detail-rma/$detailId",
        "/app/businesses-rma_/business/$businessId/bp/create-serial-rma/$serialId",
        "/app/businesses-rma_/business/$businessId/bp/delete-detail/$detailId",
        "/app/businesses-rma_/business/$businessId/bp/delete-serial/$serialId",
        "/app/businesses-rma_/business/$businessId/bp/update-detail/$detailId"
      ]
    },
    "/app/businesses-rma_/business/$businessId/dashboard": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId",
      "children": [
        "/app/businesses-rma_/business/$businessId/dashboard/address-book",
        "/app/businesses-rma_/business/$businessId/dashboard/create-ged-directory",
        "/app/businesses-rma_/business/$businessId/dashboard/create-lifesheet",
        "/app/businesses-rma_/business/$businessId/dashboard/create-link",
        "/app/businesses-rma_/business/$businessId/dashboard/delete",
        "/app/businesses-rma_/business/$businessId/dashboard/email-history",
        "/app/businesses-rma_/business/$businessId/dashboard/import-ged-files",
        "/app/businesses-rma_/business/$businessId/dashboard/send-email",
        "/app/businesses-rma_/business/$businessId/dashboard/update-billing-address",
        "/app/businesses-rma_/business/$businessId/dashboard/update-representative",
        "/app/businesses-rma_/business/$businessId/dashboard/update-responsible",
        "/app/businesses-rma_/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId",
        "/app/businesses-rma_/business/$businessId/dashboard/delete-ged-object/$objectRelativePath",
        "/app/businesses-rma_/business/$businessId/dashboard/delete-link/$associatedId",
        "/app/businesses-rma_/business/$businessId/dashboard/lifesheet-email/$lifesheetId",
        "/app/businesses-rma_/business/$businessId/dashboard/rename-ged-object/$objectRelativePath",
        "/app/businesses-rma_/business/$businessId/dashboard/task-email/$taskId",
        "/app/businesses-rma_/business/$businessId/dashboard/unlink-task/$taskId"
      ]
    },
    "/app/businesses-rma_/business/$businessId/quotation": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId",
      "children": [
        "/app/businesses-rma_/business/$businessId/quotation/commercial-notice",
        "/app/businesses-rma_/business/$businessId/quotation/pdf",
        "/app/businesses-rma_/business/$businessId/quotation/update-shipping-price",
        "/app/businesses-rma_/business/$businessId/quotation/create-associated-detail/$detailId",
        "/app/businesses-rma_/business/$businessId/quotation/create-detail/$subquotationId",
        "/app/businesses-rma_/business/$businessId/quotation/delete-detail/$detailId",
        "/app/businesses-rma_/business/$businessId/quotation/delete-subquotation/$subquotationId",
        "/app/businesses-rma_/business/$businessId/quotation/update-detail/$detailId",
        "/app/businesses-rma_/business/$businessId/quotation/update-subquotation/$subquotationId"
      ]
    },
    "/app/businesses-rma_/business/$businessId_/study": {
      "filePath": "app/businesses-rma_/business.$businessId_/study/route.ts",
      "parent": "/app",
      "children": [
        "/app/businesses-rma_/business/$businessId_/study/automatic",
        "/app/businesses-rma_/business/$businessId_/study/expert",
        "/app/businesses-rma_/business/$businessId_/study/"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/delivery": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/delivery/create-detail",
        "/app/businesses-rma_/rma/$rmaId/delivery/pdf",
        "/app/businesses-rma_/rma/$rmaId/delivery/travel-voucher",
        "/app/businesses-rma_/rma/$rmaId/delivery/delete-detail/$detailId",
        "/app/businesses-rma_/rma/$rmaId/delivery/update-detail/$detailId"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/reception": {
      "filePath": "app/businesses-rma_/rma.$rmaId/reception/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/reception/create-detail",
        "/app/businesses-rma_/rma/$rmaId/reception/pdf",
        "/app/businesses-rma_/rma/$rmaId/reception/delete-detail/$detailId",
        "/app/businesses-rma_/rma/$rmaId/reception/update-detail/$detailId"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/support": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/support/create-detail",
        "/app/businesses-rma_/rma/$rmaId/support/create-ged-directory",
        "/app/businesses-rma_/rma/$rmaId/support/create-lifesheet",
        "/app/businesses-rma_/rma/$rmaId/support/create-link",
        "/app/businesses-rma_/rma/$rmaId/support/import-ged-files",
        "/app/businesses-rma_/rma/$rmaId/support/pdf",
        "/app/businesses-rma_/rma/$rmaId/support/delete-detail/$detailId",
        "/app/businesses-rma_/rma/$rmaId/support/delete-ged-object/$relativePath",
        "/app/businesses-rma_/rma/$rmaId/support/delete-link/$associatedId",
        "/app/businesses-rma_/rma/$rmaId/support/lifesheet-email/$lifesheetId",
        "/app/businesses-rma_/rma/$rmaId/support/rename-ged-object/$objectRelativePath",
        "/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId",
        "/app/businesses-rma_/rma/$rmaId/support/unlink-task/$taskId",
        "/app/businesses-rma_/rma/$rmaId/support/update-detail/$detailId"
      ]
    },
    "/app/dashboard/task-email/$taskId/reply": {
      "filePath": "app/dashboard/task-email.$taskId/reply/route.ts",
      "parent": "/app/dashboard/task-email/$taskId"
    },
    "/app/enterprises_/$enterpriseId/address-book/create": {
      "filePath": "app/enterprises_.$enterpriseId/address-book/create/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/address-book"
    },
    "/app/enterprises_/$enterpriseId/address-book/import": {
      "filePath": "app/enterprises_.$enterpriseId/address-book/import/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/address-book"
    },
    "/app/enterprises_/$enterpriseId/create-contact-business/$contactId": {
      "filePath": "app/enterprises_.$enterpriseId/create-contact-business.$contactId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/delete-contact/$contactId": {
      "filePath": "app/enterprises_.$enterpriseId/delete-contact.$contactId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/delete-ged-object/$objectRelativePath": {
      "filePath": "app/enterprises_.$enterpriseId/delete-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/lifesheet-email/$lifesheetId": {
      "filePath": "app/enterprises_.$enterpriseId/lifesheet-email.$lifesheetId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/rename-ged-object/$objectRelativePath": {
      "filePath": "app/enterprises_.$enterpriseId/rename-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/send-email-to-contact/$contactId": {
      "filePath": "app/enterprises_.$enterpriseId/send-email-to-contact.$contactId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/task-email/$taskId": {
      "filePath": "app/enterprises_.$enterpriseId/task-email.$taskId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId",
      "children": [
        "/app/enterprises_/$enterpriseId/task-email/$taskId/reply"
      ]
    },
    "/app/enterprises_/$enterpriseId/unlink-task/$taskId": {
      "filePath": "app/enterprises_.$enterpriseId/unlink-task.$taskId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/unrelate-business-rma/$businessRmaId": {
      "filePath": "app/enterprises_.$enterpriseId/unrelate-business-rma.$businessRmaId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/update-contact-password/$contactId": {
      "filePath": "app/enterprises_.$enterpriseId/update-contact-password.$contactId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/enterprises_/$enterpriseId/update-contact/$contactId": {
      "filePath": "app/enterprises_.$enterpriseId/update-contact.$contactId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId"
    },
    "/app/faq/ged/$faqId/create-directory": {
      "filePath": "app/faq/ged.$faqId/create-directory/route.ts",
      "parent": "/app/faq/ged/$faqId"
    },
    "/app/faq/ged/$faqId/import-files": {
      "filePath": "app/faq/ged.$faqId/import-files/route.ts",
      "parent": "/app/faq/ged/$faqId"
    },
    "/app/products/serial-numbers/create-rma/$serialNumberId": {
      "filePath": "app/products/serial-numbers/create-rma.$serialNumberId/route.ts",
      "parent": "/app/products/serial-numbers"
    },
    "/app/products/serial-numbers/delete/$serialNumberId": {
      "filePath": "app/products/serial-numbers/delete.$serialNumberId/route.ts",
      "parent": "/app/products/serial-numbers"
    },
    "/app/products/serial-numbers/remove-from-business/$serialNumberId": {
      "filePath": "app/products/serial-numbers/remove-from-business.$serialNumberId/route.ts",
      "parent": "/app/products/serial-numbers"
    },
    "/app/products/serial-numbers/update/$serialNumberId": {
      "filePath": "app/products/serial-numbers/update.$serialNumberId/route.ts",
      "parent": "/app/products/serial-numbers"
    },
    "/app/products_/$productId/informations/create-ged-directory": {
      "filePath": "app/products_.$productId/informations/create-ged-directory/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/informations/create-lifesheet-comment": {
      "filePath": "app/products_.$productId/informations/create-lifesheet-comment/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/informations/delete-ged-object": {
      "filePath": "app/products_.$productId/informations/delete-ged-object/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/informations/import-ged-files": {
      "filePath": "app/products_.$productId/informations/import-ged-files/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/informations/rename-ged-object": {
      "filePath": "app/products_.$productId/informations/rename-ged-object/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/manage/add-associated-product": {
      "filePath": "app/products_.$productId/manage/add-associated-product/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/add-nomenclature-detail": {
      "filePath": "app/products_.$productId/manage/add-nomenclature-detail/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/add-specification": {
      "filePath": "app/products_.$productId/manage/add-specification/route.ts",
      "parent": "/app/products_/$productId/manage",
      "children": [
        "/app/products_/$productId/manage/add-specification/$filterId"
      ]
    },
    "/app/products_/$productId/manage/create-stock": {
      "filePath": "app/products_.$productId/manage/create-stock/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/create-version": {
      "filePath": "app/products_.$productId/manage/create-version/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/tools/ddns/delete/$ddnsId": {
      "filePath": "app/tools/ddns/delete.$ddnsId/route.ts",
      "parent": "/app/tools/ddns"
    },
    "/app/tools/departments/delete/$departmentId": {
      "filePath": "app/tools/departments/delete.$departmentId/route.ts",
      "parent": "/app/tools/departments"
    },
    "/app/tools/departments/update/$departmentId": {
      "filePath": "app/tools/departments/update.$departmentId/route.ts",
      "parent": "/app/tools/departments"
    },
    "/app/tools/emails/$emailId/reply": {
      "filePath": "app/tools/emails/$emailId/reply/route.ts",
      "parent": "/app/tools/emails/$emailId"
    },
    "/app/tools/external-links/archive/$externalLinkId": {
      "filePath": "app/tools/external-links/archive.$externalLinkId/route.ts",
      "parent": "/app/tools/external-links"
    },
    "/app/tools/external-links/delete/$externalLinkId": {
      "filePath": "app/tools/external-links/delete.$externalLinkId/route.ts",
      "parent": "/app/tools/external-links"
    },
    "/app/tools/external-links/update/$externalLinkId": {
      "filePath": "app/tools/external-links/update.$externalLinkId/route.ts",
      "parent": "/app/tools/external-links"
    },
    "/app/tools/formations/create/add-detail": {
      "filePath": "app/tools/formations/create/add-detail/route.ts",
      "parent": "/app/tools/formations/create"
    },
    "/app/tools/formations/create/details": {
      "filePath": "app/tools/formations/create/details/route.ts",
      "parent": "/app/tools/formations/create"
    },
    "/app/tools/formations/delete/$formationId": {
      "filePath": "app/tools/formations/delete.$formationId/route.ts",
      "parent": "/app/tools/formations"
    },
    "/app/tools/formations/subscribers/$formationDetailId": {
      "filePath": "app/tools/formations/subscribers.$formationDetailId/route.ts",
      "parent": "/app/tools/formations",
      "children": [
        "/app/tools/formations/subscribers/$formationDetailId/create",
        "/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId",
        "/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId"
      ]
    },
    "/app/tools/formations/update/$formationId": {
      "filePath": "app/tools/formations/update.$formationId/route.ts",
      "parent": "/app/tools/formations",
      "children": [
        "/app/tools/formations/update/$formationId/add-detail",
        "/app/tools/formations/update/$formationId/details"
      ]
    },
    "/app/tools/mails/delete/$mailId": {
      "filePath": "app/tools/mails/delete.$mailId/route.ts",
      "parent": "/app/tools/mails"
    },
    "/app/tools/mails/show/$mailId": {
      "filePath": "app/tools/mails/show.$mailId/route.ts",
      "parent": "/app/tools/mails"
    },
    "/app/tools/mails/update/$mailId": {
      "filePath": "app/tools/mails/update.$mailId/route.ts",
      "parent": "/app/tools/mails"
    },
    "/app/tools/news/delete/$newsId": {
      "filePath": "app/tools/news/delete.$newsId/route.ts",
      "parent": "/app/tools/news"
    },
    "/app/tools/news/update/$newsId": {
      "filePath": "app/tools/news/update.$newsId/route.ts",
      "parent": "/app/tools/news"
    },
    "/app/tools/predefined-messages/delete/$predefinedMessageId": {
      "filePath": "app/tools/predefined-messages/delete.$predefinedMessageId/route.ts",
      "parent": "/app/tools/predefined-messages"
    },
    "/app/tools/predefined-messages/update/$predefinedMessageId": {
      "filePath": "app/tools/predefined-messages/update.$predefinedMessageId/route.ts",
      "parent": "/app/tools/predefined-messages"
    },
    "/app/tools/predefined-texts/delete/$predefinedTextId": {
      "filePath": "app/tools/predefined-texts/delete.$predefinedTextId/route.ts",
      "parent": "/app/tools/predefined-texts"
    },
    "/app/tools/predefined-texts/update/$predefinedTextId": {
      "filePath": "app/tools/predefined-texts/update.$predefinedTextId/route.ts",
      "parent": "/app/tools/predefined-texts"
    },
    "/app/tools/product-filters/delete/$productFilterId": {
      "filePath": "app/tools/product-filters/delete.$productFilterId/route.ts",
      "parent": "/app/tools/product-filters"
    },
    "/app/tools/product-filters/update/$productFilterId": {
      "filePath": "app/tools/product-filters/update.$productFilterId/route.ts",
      "parent": "/app/tools/product-filters"
    },
    "/app/tools/product-inventory/update/$stockId": {
      "filePath": "app/tools/product-inventory/update.$stockId/route.ts",
      "parent": "/app/tools/product-inventory"
    },
    "/app/tools/product-shelves/delete/$productShelfId": {
      "filePath": "app/tools/product-shelves/delete.$productShelfId/route.ts",
      "parent": "/app/tools/product-shelves"
    },
    "/app/tools/scheduler/details/$rdvId": {
      "filePath": "app/tools/scheduler/details.$rdvId/route.ts",
      "parent": "/app/tools/scheduler",
      "children": [
        "/app/tools/scheduler/details/$rdvId/delete",
        "/app/tools/scheduler/details/$rdvId/update"
      ]
    },
    "/app/tools/vva/delete/$vvaId": {
      "filePath": "app/tools/vva/delete.$vvaId/route.ts",
      "parent": "/app/tools/vva"
    },
    "/app/businesses-rma_/business/$businessId/": {
      "filePath": "app/businesses-rma_/business.$businessId/index.ts",
      "parent": "/app/businesses-rma_/business/$businessId"
    },
    "/app/businesses-rma_/rma/$rmaId/": {
      "filePath": "app/businesses-rma_/rma.$rmaId/index.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId"
    },
    "/app/businesses-rma_/business/$businessId/arc/pdf": {
      "filePath": "app/businesses-rma_/business.$businessId/arc/pdf/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/arc",
      "children": [
        "/app/businesses-rma_/business/$businessId/arc/pdf/send-by-email"
      ]
    },
    "/app/businesses-rma_/business/$businessId/arc/update-shipping-price": {
      "filePath": "app/businesses-rma_/business.$businessId/arc/update-shipping-price/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/arc"
    },
    "/app/businesses-rma_/business/$businessId/bill/credits": {
      "filePath": "app/businesses-rma_/business.$businessId/bill/credits/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bill",
      "children": [
        "/app/businesses-rma_/business/$businessId/bill/credits/send-by-email"
      ]
    },
    "/app/businesses-rma_/business/$businessId/bill/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId/bill/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/business/$businessId/bill"
    },
    "/app/businesses-rma_/business/$businessId/bl/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId/bl/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/business/$businessId/bl"
    },
    "/app/businesses-rma_/business/$businessId/bp/travel-voucher": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/travel-voucher/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/address-book": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/address-book/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard",
      "children": [
        "/app/businesses-rma_/business/$businessId/dashboard/address-book/create",
        "/app/businesses-rma_/business/$businessId/dashboard/address-book/delete/$addressId",
        "/app/businesses-rma_/business/$businessId/dashboard/address-book/update/$addressId"
      ]
    },
    "/app/businesses-rma_/business/$businessId/dashboard/create-ged-directory": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/create-ged-directory/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/create-lifesheet": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/create-lifesheet/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/create-link": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/create-link/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/delete": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/delete/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/email-history": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/email-history/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard",
      "children": [
        "/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId"
      ]
    },
    "/app/businesses-rma_/business/$businessId/dashboard/import-ged-files": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/import-ged-files/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/send-email": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/send-email/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/update-billing-address": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/update-billing-address/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/update-representative": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/update-representative/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/update-responsible": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/update-responsible/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/quotation/commercial-notice": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/commercial-notice/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation",
      "children": [
        "/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email"
      ]
    },
    "/app/businesses-rma_/business/$businessId/quotation/pdf": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/pdf/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation",
      "children": [
        "/app/businesses-rma_/business/$businessId/quotation/pdf/send-by-email"
      ]
    },
    "/app/businesses-rma_/business/$businessId/quotation/update-shipping-price": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/update-shipping-price/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/route.ts",
      "parent": "/app",
      "children": [
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-faq",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-ged-directory",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-lifesheet",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-link",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-cumulated-time",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-no-billed-time",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-subtitle",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/import-ged-files",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-ged-object/$objectRelativePath",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-link/$associatedId",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/lifesheet-email/$lifesheetId",
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/rename-ged-object/$objectRelativePath"
      ]
    },
    "/app/businesses-rma_/business/$businessId_/study/automatic": {
      "filePath": "app/businesses-rma_/business.$businessId_/study/automatic/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/study"
    },
    "/app/businesses-rma_/business/$businessId_/study/expert": {
      "filePath": "app/businesses-rma_/business.$businessId_/study/expert/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/study"
    },
    "/app/businesses-rma_/rma/$rmaId/delivery/create-detail": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/create-detail/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/delivery"
    },
    "/app/businesses-rma_/rma/$rmaId/delivery/pdf": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/pdf/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/delivery",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/delivery/pdf/send-by-email"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/delivery/travel-voucher": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/travel-voucher/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/delivery"
    },
    "/app/businesses-rma_/rma/$rmaId/reception/create-detail": {
      "filePath": "app/businesses-rma_/rma.$rmaId/reception/create-detail/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/reception"
    },
    "/app/businesses-rma_/rma/$rmaId/reception/pdf": {
      "filePath": "app/businesses-rma_/rma.$rmaId/reception/pdf/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/reception",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/reception/pdf/send-by-email"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/support/create-detail": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/create-detail/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/create-ged-directory": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/create-ged-directory/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/create-lifesheet": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/create-lifesheet/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/create-link": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/create-link/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/import-ged-files": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/import-ged-files/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/pdf": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/pdf/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/support/pdf/send-by-email"
      ]
    },
    "/app/enterprises_/$enterpriseId/address-book/delete/$addressId": {
      "filePath": "app/enterprises_.$enterpriseId/address-book/delete.$addressId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/address-book"
    },
    "/app/enterprises_/$enterpriseId/address-book/update/$addressId": {
      "filePath": "app/enterprises_.$enterpriseId/address-book/update.$addressId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/address-book"
    },
    "/app/enterprises_/$enterpriseId/email-history/email/$emailId": {
      "filePath": "app/enterprises_.$enterpriseId/email-history/email.$emailId/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/email-history",
      "children": [
        "/app/enterprises_/$enterpriseId/email-history/email/$emailId/reply"
      ]
    },
    "/app/enterprises_/$enterpriseId/task-email/$taskId/reply": {
      "filePath": "app/enterprises_.$enterpriseId/task-email.$taskId/reply/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/task-email/$taskId"
    },
    "/app/faq/ged/$faqId/delete/$itemRelativePath": {
      "filePath": "app/faq/ged.$faqId/delete.$itemRelativePath/route.ts",
      "parent": "/app/faq/ged/$faqId"
    },
    "/app/faq/ged/$faqId/rename/$itemRelativePath": {
      "filePath": "app/faq/ged.$faqId/rename.$itemRelativePath/route.ts",
      "parent": "/app/faq/ged/$faqId"
    },
    "/app/products_/$productId/informations/lifesheet-email/$lifesheetId": {
      "filePath": "app/products_.$productId/informations/lifesheet-email.$lifesheetId/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/informations/task-email/$taskId": {
      "filePath": "app/products_.$productId/informations/task-email.$taskId/route.ts",
      "parent": "/app/products_/$productId/informations",
      "children": [
        "/app/products_/$productId/informations/task-email/$taskId/reply"
      ]
    },
    "/app/products_/$productId/informations/unlink-task/$taskId": {
      "filePath": "app/products_.$productId/informations/unlink-task.$taskId/route.ts",
      "parent": "/app/products_/$productId/informations"
    },
    "/app/products_/$productId/manage/add-specification/$filterId": {
      "filePath": "app/products_.$productId/manage/add-specification/$filterId/route.ts",
      "parent": "/app/products_/$productId/manage/add-specification"
    },
    "/app/products_/$productId/manage/delete-nomenclature-detail/$nomenclatureDetailId": {
      "filePath": "app/products_.$productId/manage/delete-nomenclature-detail.$nomenclatureDetailId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/delete-specification/$specificationId": {
      "filePath": "app/products_.$productId/manage/delete-specification.$specificationId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/delete-stock/$stockId": {
      "filePath": "app/products_.$productId/manage/delete-stock.$stockId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/delete-version/$versionId": {
      "filePath": "app/products_.$productId/manage/delete-version.$versionId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/remove-associated-product/$associatedProductId": {
      "filePath": "app/products_.$productId/manage/remove-associated-product.$associatedProductId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/stock-history/$stockId": {
      "filePath": "app/products_.$productId/manage/stock-history.$stockId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/update-nomenclature-detail/$nomenclatureDetailId": {
      "filePath": "app/products_.$productId/manage/update-nomenclature-detail.$nomenclatureDetailId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/update-specification/$specificationId": {
      "filePath": "app/products_.$productId/manage/update-specification.$specificationId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/update-stock/$stockId": {
      "filePath": "app/products_.$productId/manage/update-stock.$stockId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/products_/$productId/manage/update-version/$versionId": {
      "filePath": "app/products_.$productId/manage/update-version.$versionId/route.ts",
      "parent": "/app/products_/$productId/manage"
    },
    "/app/tools/formations/subscribers/$formationDetailId/create": {
      "filePath": "app/tools/formations/subscribers.$formationDetailId/create/route.ts",
      "parent": "/app/tools/formations/subscribers/$formationDetailId"
    },
    "/app/tools/formations/update/$formationId/add-detail": {
      "filePath": "app/tools/formations/update.$formationId/add-detail/route.ts",
      "parent": "/app/tools/formations/update/$formationId"
    },
    "/app/tools/formations/update/$formationId/details": {
      "filePath": "app/tools/formations/update.$formationId/details/route.ts",
      "parent": "/app/tools/formations/update/$formationId"
    },
    "/app/tools/scheduler/details/$rdvId/delete": {
      "filePath": "app/tools/scheduler/details.$rdvId/delete/route.ts",
      "parent": "/app/tools/scheduler/details/$rdvId"
    },
    "/app/tools/scheduler/details/$rdvId/update": {
      "filePath": "app/tools/scheduler/details.$rdvId/update/route.ts",
      "parent": "/app/tools/scheduler/details/$rdvId"
    },
    "/app/businesses-rma_/business/$businessId/bl/update": {
      "filePath": "app/businesses-rma_/business.$businessId/bl/update/route.lazy.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bl"
    },
    "/app/businesses-rma_/business/$businessId_/study/": {
      "filePath": "app/businesses-rma_/business.$businessId_/study/index.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/study"
    },
    "/app/businesses-rma_/business/$businessId/arc/delete-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/arc/delete-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/arc"
    },
    "/app/businesses-rma_/business/$businessId/arc/pdf/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId/arc/pdf/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/business/$businessId/arc/pdf"
    },
    "/app/businesses-rma_/business/$businessId/arc/update-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/arc/update-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/arc"
    },
    "/app/businesses-rma_/business/$businessId/bill/credits/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId/bill/credits/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/business/$businessId/bill/credits"
    },
    "/app/businesses-rma_/business/$businessId/bp/add-serial/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/add-serial.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/bp/create-detail-rma/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/create-detail-rma.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/bp/create-serial-rma/$serialId": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/create-serial-rma.$serialId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/bp/delete-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/delete-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/bp/delete-serial/$serialId": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/delete-serial.$serialId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/bp/update-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/bp/update-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/bp"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/address-book/create": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/address-book/create/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard/address-book"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/confirm-quotation-import/$otherBusinessId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/confirm-quotation-import.$otherBusinessId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/delete-ged-object/$objectRelativePath": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/delete-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/delete-link/$associatedId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/delete-link.$associatedId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/email-history/$emailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard/email-history",
      "children": [
        "/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId/reply"
      ]
    },
    "/app/businesses-rma_/business/$businessId/dashboard/lifesheet-email/$lifesheetId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/lifesheet-email.$lifesheetId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/rename-ged-object/$objectRelativePath": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/rename-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/task-email/$taskId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/task-email.$taskId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/unlink-task/$taskId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/unlink-task.$taskId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard"
    },
    "/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/commercial-notice/send-by-email/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation/commercial-notice",
      "children": [
        "/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages"
      ]
    },
    "/app/businesses-rma_/business/$businessId/quotation/create-associated-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/create-associated-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId/quotation/create-detail/$subquotationId": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/create-detail.$subquotationId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId/quotation/delete-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/delete-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId/quotation/delete-subquotation/$subquotationId": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/delete-subquotation.$subquotationId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId/quotation/pdf/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/pdf/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/business/$businessId/quotation/pdf"
    },
    "/app/businesses-rma_/business/$businessId/quotation/update-detail/$detailId": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/update-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId/quotation/update-subquotation/$subquotationId": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/update-subquotation.$subquotationId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-faq": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-faq/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-ged-directory": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-ged-directory/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-lifesheet": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-lifesheet/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/create-link": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/create-link/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-cumulated-time": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-cumulated-time/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-no-billed-time": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-no-billed-time/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/edit-subtitle": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/edit-subtitle/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/import-ged-files": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/import-ged-files/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/pdf/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId",
      "children": [
        "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf/send-by-email"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/delivery/delete-detail/$detailId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/delete-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/delivery"
    },
    "/app/businesses-rma_/rma/$rmaId/delivery/pdf/send-by-email": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/pdf/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/rma/$rmaId/delivery/pdf"
    },
    "/app/businesses-rma_/rma/$rmaId/delivery/update-detail/$detailId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/delivery/update-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/delivery"
    },
    "/app/businesses-rma_/rma/$rmaId/reception/delete-detail/$detailId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/reception/delete-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/reception"
    },
    "/app/businesses-rma_/rma/$rmaId/reception/pdf/send-by-email": {
      "filePath": "app/businesses-rma_/rma.$rmaId/reception/pdf/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/rma/$rmaId/reception/pdf"
    },
    "/app/businesses-rma_/rma/$rmaId/reception/update-detail/$detailId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/reception/update-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/reception"
    },
    "/app/businesses-rma_/rma/$rmaId/support/delete-detail/$detailId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/delete-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/delete-ged-object/$relativePath": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/delete-ged-object.$relativePath/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/delete-link/$associatedId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/delete-link.$associatedId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/lifesheet-email/$lifesheetId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/lifesheet-email.$lifesheetId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/pdf/send-by-email": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/pdf/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/rma/$rmaId/support/pdf"
    },
    "/app/businesses-rma_/rma/$rmaId/support/rename-ged-object/$objectRelativePath": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/rename-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/task-email.$taskId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support",
      "children": [
        "/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId/reply"
      ]
    },
    "/app/businesses-rma_/rma/$rmaId/support/unlink-task/$taskId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/unlink-task.$taskId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/businesses-rma_/rma/$rmaId/support/update-detail/$detailId": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/update-detail.$detailId/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support"
    },
    "/app/enterprises_/$enterpriseId/email-history/email/$emailId/reply": {
      "filePath": "app/enterprises_.$enterpriseId/email-history/email.$emailId/reply/route.ts",
      "parent": "/app/enterprises_/$enterpriseId/email-history/email/$emailId"
    },
    "/app/products_/$productId/informations/task-email/$taskId/reply": {
      "filePath": "app/products_.$productId/informations/task-email.$taskId/reply/route.ts",
      "parent": "/app/products_/$productId/informations/task-email/$taskId"
    },
    "/app/tools/formations/subscribers/$formationDetailId/delete/$subscriptionId": {
      "filePath": "app/tools/formations/subscribers.$formationDetailId/delete.$subscriptionId/route.ts",
      "parent": "/app/tools/formations/subscribers/$formationDetailId"
    },
    "/app/tools/formations/subscribers/$formationDetailId/send-email/$subscriptionId": {
      "filePath": "app/tools/formations/subscribers.$formationDetailId/send-email.$subscriptionId/route.ts",
      "parent": "/app/tools/formations/subscribers/$formationDetailId"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/address-book/delete/$addressId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/address-book/delete.$addressId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard/address-book"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/address-book/update/$addressId": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/address-book/update.$addressId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard/address-book"
    },
    "/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId/reply": {
      "filePath": "app/businesses-rma_/business.$businessId/dashboard/email-history/$emailId/reply/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/dashboard/email-history/$emailId"
    },
    "/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email/predefined-messages": {
      "filePath": "app/businesses-rma_/business.$businessId/quotation/commercial-notice/send-by-email/predefined-messages/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId/quotation/commercial-notice/send-by-email"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-ged-object/$objectRelativePath": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/delete-link/$associatedId": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/delete-link.$associatedId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/lifesheet-email/$lifesheetId": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/lifesheet-email.$lifesheetId/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf/send-by-email": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/pdf/send-by-email/route.tsx",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/pdf"
    },
    "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId/rename-ged-object/$objectRelativePath": {
      "filePath": "app/businesses-rma_/business.$businessId_/assistance.$assistanceId/rename-ged-object.$objectRelativePath/route.ts",
      "parent": "/app/businesses-rma_/business/$businessId_/assistance/$assistanceId"
    },
    "/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId/reply": {
      "filePath": "app/businesses-rma_/rma.$rmaId/support/task-email.$taskId/reply/route.ts",
      "parent": "/app/businesses-rma_/rma/$rmaId/support/task-email/$taskId"
    }
  }
}
ROUTE_MANIFEST_END */
